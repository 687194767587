import React, {FC} from 'react'
import { PageTitle } from '../../../../_metronic/layout/core'
import AdminPage from './AdminPage'

const AdminPageWrapper: FC = () => {

  return (
    <>
      <PageTitle breadcrumbs={[]}>{"ADMIN"}</PageTitle>
      <AdminPage />
    </>
  )
}

export default AdminPageWrapper
