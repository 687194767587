/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { connect } from 'react-redux'
import { AppDispatch, RootState } from '../../../../setup/redux/Store'
import { KTSVG } from '../../../../_metronic/helpers'
import { PageTitle } from '../../../../_metronic/layout/core'
import { AreaJobTemplate, AreaWorkflowStageTemplate, AreaWorkflowStepTemplate, Workflow } from '../models/AreaModel'
import { createTemplateStage, createTemplateStep, fetchJobTemplates, fetchTemplateStages, updateTemplateStage, updateTemplateStep } from '../redux/AreaRedux'
import { getModal, getModalData, _hideModal, _showModal } from '../../modals/redux/ModalRedux'
import { ModalModel } from '../../modals/models/ModalModel'
import { RouteComponentProps } from 'react-router-dom'
import WorkflowStage from './WorkflowStage'
import { WorkflowCreateItemModal } from './WorkflowCreateItemModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-duotone-svg-icons'
import { WorkflowEditItemModal } from './WorkflowEditItemModal'

interface WorkflowState {
  workflowId: string
}

interface WorkflowProps {

}

interface ConnectedProps1 {
  workflows: Workflow[]
  modals: ModalModel[]
  stages: AreaWorkflowStageTemplate[]
  steps: AreaWorkflowStepTemplate[]
  createStageLoading: boolean
  jobTemplates: AreaJobTemplate[]
  createTemplateStepLoading: boolean
}

interface DispatchProps {
  hideModal: typeof _hideModal
  showModal: typeof _showModal
  fetchTemplateStages: typeof fetchTemplateStages
  createTemplateStage: typeof createTemplateStage
  fetchJobTemplates: typeof fetchJobTemplates
  createTemplateStep: typeof createTemplateStep,
  updateTemplateStage: typeof updateTemplateStage,
  updateTemplateStep: typeof updateTemplateStep
}

interface RouterParams {
  workflow: string;
}

type Props = ConnectedProps1 & DispatchProps & WorkflowProps & WrappedComponentProps & RouteComponentProps<RouterParams>

class WorkflowEditPage extends React.Component<Props, WorkflowState> {
  workflowId: string

  constructor(props: Props) {
    super(props);
    this.workflowId = this.props.match.params.workflow;
  }

  componentDidMount() {
    this.props.fetchTemplateStages(this.workflowId);
    this.props.fetchJobTemplates();
  }

  componentDidUpdate() {
    const paramWorkflowId = this.props.match.params.workflow;

    if (this.workflowId !== paramWorkflowId) {
      this.workflowId = paramWorkflowId;
      this.props.fetchTemplateStages(this.workflowId);
      this.props.fetchJobTemplates();
    }
  }

  stageSteps = (stage: AreaWorkflowStageTemplate, steps: any[]) => {
    return steps.filter((step: AreaWorkflowStepTemplate) => {
      return stage.stage_steps.indexOf(step.id) !== -1
    })
  }

  render(): React.ReactNode {
    const intl = this.props.intl;
    const modals = this.props.modals;
    return (
      <>
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({ "id": "AREA.MANAGEMENT.WORKFLOW" })}</PageTitle>

        <div className='card mb-10'>
          <div className='card-body d-flex align-items-center py-8'>
            {/* begin::Icon */}
            <div className='d-flex h-80px w-80px flex-shrink-0 flex-center position-relative'>
              <KTSVG
                path='/media/icons/duotune/abstract/abs051.svg'
                className='svg-icon-primary position-absolute opacity-15'
                svgClassName='h-80px w-80px'
              />
              <KTSVG
                path='/media/icons/duotune/coding/cod009.svg'
                className='svg-icon-3x svg-icon-primary position-absolute'
              />
            </div>
            {/* end::Icon */}

            {/* begin::Description */}
            <div className='ms-6'>
              <p className='list-unstyled text-gray-600 fw-bold fs-6 p-0 m-0'>
                {intl.formatMessage({ id: "AREA.MANAGEMENT.WORKFLOW.DESCRIPTION" })}
              </p>
            </div>
            {/* end::Description */}
          </div>
        </div>
        <div className='card card-custom'>
          <div className='card-toolbar mt-2 mr-5'>
            {/* begin::Menu */}
            <div>
              <WorkflowCreateItemModal
                show={getModal('create-item-template', modals)}
                handleClose={() => this.props.hideModal('create-item-template')}
                stages={this.props.stages}
                workflow={this.workflowId}
                loading={this.props.createStageLoading || this.props.createTemplateStepLoading}
                onSubmit={this.props.createTemplateStage}
                onSubmit2={this.props.createTemplateStep}
                jobTemplates={this.props.jobTemplates}
                workflows={this.props.workflows}
              />
            </div>

            <div>
              {getModalData('edit-item-template', modals) && (
                <WorkflowEditItemModal
                  show={getModal('edit-item-template', modals)}
                  data={getModalData('edit-item-template', modals)}
                  handleClose={() => this.props.hideModal('edit-item-template')}
                  stages={this.props.stages}
                  workflow={this.workflowId}
                  loading={this.props.createStageLoading || this.props.createTemplateStepLoading}
                  onSubmit={this.props.updateTemplateStage}
                  onSubmit2={this.props.updateTemplateStep}
                  jobTemplates={this.props.jobTemplates}
                  workflows={this.props.workflows}
                />
              )}
            </div>

            <div>
              <button
                className='btn btn-sm btn-primary float-right'
                style={{ float: "right", marginRight: "0.5em" }}
                onClick={() => this.props.showModal('create-item-template', null)}
              >
                <FontAwesomeIcon icon={faPlus} className='fa-fw' />{intl.formatMessage({ id: "GENERAL.CREATE" })}
              </button>
            </div>
            {/* end::Menu */}
          </div>
          {/* begin::Form */}
          <form className='form'>
            {/* begin::Body */}
            <div className='card-body'>
              <div className='tab-content pt-3 row'>
                {this.props.stages && this.props.stages.map((stage: AreaWorkflowStageTemplate, index: number) => (
                  <div key={index} className='col-md-4 mr-2'>
                    <WorkflowStage workflowSteps={this.stageSteps(stage, this.props.steps)} stage={stage} history={this.props.history} />
                  </div>
                ))}

              </div>
            </div>
            {/* end::Body */}


          </form>
          {/* end::Form */}
        </div>
      </>
    )
  }

}

const mapStateToProps = (state: RootState) => {
  return {
    workflows: state.areas.workflows,
    modals: state.modals.modals,
    stages: state.areas.areaWorkflowStageTemplates,
    steps: state.areas.areaWorkflowStepTemplates,
    createStageLoading: state.areas.createTemplateStageLoading,
    jobTemplates: state.areas.jobTemplates,
    createTemplateStepLoading: state.areas.createTemplateStepLoading
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    fetchTemplateStages: (workflow: string) => dispatch(fetchTemplateStages(workflow)),
    showModal: (name: string) => dispatch(_showModal(name)),
    hideModal: (name: string) => dispatch(_hideModal(name)),
    createTemplateStage: (workflow: string, name: string) => dispatch(createTemplateStage(workflow, name)),
    fetchJobTemplates: () => dispatch(fetchJobTemplates()),
    createTemplateStep: (stage: string, name: string, _type: number, source: string, workflow: string) => dispatch(createTemplateStep(stage, name, _type, source, workflow)),
    updateTemplateStage: (workflow: string, stage: string, name: string) => dispatch(updateTemplateStage(workflow, stage, name)),
    updateTemplateStep: (workflow: string, stage: string, step: string, name: string, source: string, type: number) => dispatch(updateTemplateStep(workflow, stage, step, name, source, type)),
  };
};

export default connect<ConnectedProps1, DispatchProps>(mapStateToProps, mapDispatchToProps)(injectIntl(WorkflowEditPage));
