/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import {API_URL, KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../setup'
import { AreaModel } from './models/AreaModel'
import HasPerm from '../auth/redux/HasPerm'
import "react-loading-skeleton/dist/skeleton.css";

import Skeleton from 'react-loading-skeleton'
import { useIntl } from 'react-intl'
import { getWorkflowStatus } from '../../pages/dashboard/DashboardWrapper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMap, faMapLocation, faRotateRight } from '@fortawesome/pro-duotone-svg-icons'
import axios from 'axios'
import { fetchAreas } from './redux/AreaRedux'

export const AREA_STATUS_BADGE:any = {
  OPEN: "info",
  IN_PROGRESS: "warning",
  DONE: "success",
  DECLINED: "danger"
}

type Props = {
  areaId: string
}

const AreaHeader: React.FC<Props> = ({areaId}) => {
  const location = useLocation()
  const intl = useIntl();


  const areas:any = useSelector<RootState>(({areas}) => areas.areaDetails)
  const area:AreaModel = areas.filter(
    (area:AreaModel) => {
      return area.id.toString() === areaId;
    }
  )[0]

  const [reloadLoading, setReloadLoading] = useState(false);
  const [areaIdLoad, setAreaIdLoad] = useState(areaId);
  const dispatch = useDispatch();

  const reloadStatus = () =>{
    setReloadLoading(true);
    axios.post(API_URL+`/areas/${areaId}/status-reload`).then(res=>{
      setReloadLoading(false);
      dispatch(fetchAreas(0, 0, false, '', '', {}, [areaId]));
    }).catch(()=>{
      setReloadLoading(false);
    })
  }

  useEffect(() => {
    if((areaId !== areaIdLoad) && area){
      setAreaIdLoad(areaId)
    }
  }, [areaId, areaIdLoad, area])


  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='me-7 mb-4'>
            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
              {area ? (
                <>
              <img src={toAbsoluteUrl(area.image, true)} style={{"objectFit": "cover"}} alt='tpp' />
              </>
              ):(<Skeleton />)}
            </div>
          </div>

          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-3'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center'>
                  {area ? (
                    <p className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1 mb-0'>
                      {area.name}
                    </p>
                  ):(
                    <Skeleton />
                  )}
                  
                  {area && area.published && (
                  <a href='#'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen026.svg'
                      className='svg-icon-1 svg-icon-primary'
                    />
                  </a>
                  )}
                </div>

                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <div className='d-flex flex-column w-45'>
                    {area ? (
                      <div>
                        <Link
                          to={'status'}>
                          <span className={`badge badge-light-${AREA_STATUS_BADGE[area.status]} fs-8 fw-bolder me-2`}>{getWorkflowStatus(area.workflow_status)}</span>
                        </Link>
                        <HasPerm permission='areas.area.change'>
                          <button onClick={reloadStatus} className='btn btn-link'><FontAwesomeIcon className={reloadLoading?'fa-spin':''} icon={faRotateRight}/></button>
                        </HasPerm>
                      </div>
                    ):(
                      <Skeleton />
                    )}
                  </div>
                  </div>
                  <div className='d-flex flex-wrap fw-bold fs-6 mb-1 pe-2'>
                  {area ? (
                    <>
                    <p
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen018.svg'
                        className='svg-icon-4 me-1'
                      />
                      {area.location_name}
                    </p>
                    {area.location_link && (
                    <a
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                      href={area.location_link}
                      target={'_blank'}
                      rel={"noreferrer"}
                    >
                      <FontAwesomeIcon icon={faMap} className="svg-icon-4 me-1"/>
                      BayernAtlas
                    </a>
                    )}
                    {area.route_link && (
                    <a
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                      href={area.route_link}
                      target={'_blank'}
                      rel={"noreferrer"}
                    >
                      <FontAwesomeIcon icon={faMapLocation} className="svg-icon-4 me-1"/>
                      Google Maps
                    </a>
                    )}
                      </>
                      ):(<Skeleton />)}
                </div>
              </div>
            </div>

            <div className='d-flex flex-wrap flex-stack'>
              <div className='d-flex flex-column flex-grow-1 pe-8'>
                <div className='d-flex flex-wrap'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3 me-2'>
                    <div className='d-flex align-items-center'>
                      {area ? (
                        <>
                        <div className='fs-5 fw-bolder me-2'>{area.size.replace(".",",")}</div>
                        <div className='fw-bold fs-8 text-gray-400'>Hektar</div>
                        </>

                      ):(<Skeleton />)}  
                    </div>

                  </div>

                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                    {area ? (
                      <>
                        <div className='fs-5 fw-bolder me-2'>{area.trees_planted}/{area.trees_to_plant}</div>
                        <div className='fw-bold fs-8 text-gray-400'>Bäume</div>
                        </>
                      ):(<Skeleton />)}  
                      
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            {area ? (
            <>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  ((location.pathname === `/area/${area.id}` && 'active')|| (location.pathname === `/area/${area.id}/overview` && 'active'))
                }
                to={`/area/${area.id}/overview`}
              >
                {intl.formatMessage({id: "AREA.MENU.OVERVIEW"})}
              </Link>
            </li>
            <HasPerm permission='areas.areastatus.view'>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === `/area/${area.id}/status` && 'active')
                  }
                  to={`/area/${area.id}/status`}
                >
                Status 
                </Link>
              </li>
            </HasPerm>
            <HasPerm permission='areas.areaworkflowtodo.view'>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === `/area/${area.id}/todos` && 'active')
                  }
                  to={`/area/${area.id}/todos`}
                >
                {intl.formatMessage({id: "AREA.MENU.TODOS"})} 
                </Link>
              </li>
            </HasPerm>
            <HasPerm permission='areas.areaplantingarea.view'>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === `/area/${area.id}/plots` && 'active')
                  }
                  to={`/area/${area.id}/plots`}
                >
                {intl.formatMessage({id: "AREA.MENU.PLOTS"})} 
                </Link>
              </li>
            </HasPerm>
            <HasPerm permission='areas.areadocument.view'>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname.includes(`/area/${area.id}/documents`) && 'active')
                  }
                  to={`/area/${area.id}/documents`}
                >
                  {intl.formatMessage({id: "AREA.MENU.DOCUMENTS"})}
                </Link>
              </li>
            </HasPerm>
            <HasPerm permission='areas.areajob.view'>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname.includes(`/area/${area.id}/jobs`) && 'active')
                  }
                  to={`/area/${area.id}/jobs`}
                >
                   {intl.formatMessage({id: "AREA.MENU.JOBS"})}
                </Link>
              </li>
            </HasPerm>
            <HasPerm permission='areas.area.change'>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname.includes(`/area/${area.id}/settings`) && 'active')
                  }
                  to={`/area/${area.id}/settings`}
                >
                   {intl.formatMessage({id: "AREA.MENU.SETTINGS"})}
                </Link>
              </li>
            </HasPerm>
            </>
            ):(
              Array.from(Array(4).keys()).map((key: any, index: number) => (
                <li key={index} className='nav-item'>
                  <Skeleton />
                </li>
              ))
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}

export {AreaHeader}
