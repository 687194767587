/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { RootState } from '../../../setup/redux/Store'
import { AppDispatch } from '../../../setup/redux/Store'
import { addPartner, fetchPartnerServices } from '../areas/redux/AreaRedux'
import { PartnerRegistrationCard } from './partner-registration/PartnerRegistrationCard'

interface ConnectedProps {
  partnerServices: any[]
}

interface DispatchProps {
  fetchPartnerServices: typeof fetchPartnerServices,
  addPartner: typeof addPartner,
}

type Props = ConnectedProps & DispatchProps & WrappedComponentProps & RouteComponentProps

class PartnerRegistration extends Component<Props> {
  componentDidMount() {
    this.props.fetchPartnerServices();
  }

  onSubmit(values: any) {
    const services = (values.services === '' ? [] : values.services).map((services: any) => {
      return services.value;
    });

    this.props.addPartner(values.type, values.name, values.homepage, services);
  }

  render() {
    return (
      <>
        <div className='row g-6 g-xl-9 mt-6'>
          <PartnerRegistrationCard partnerServices={this.props.partnerServices} onSubmit={(values: any) => this.onSubmit(values)}></PartnerRegistrationCard>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    partnerServices: state.areas.partnerServices
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    fetchPartnerServices: () => dispatch(fetchPartnerServices()),
    addPartner: (type: string, name: string, homepage: string, services: string[]) => dispatch(addPartner(type, name, homepage, services))
  };
};

export default withRouter(connect<ConnectedProps, DispatchProps>(mapStateToProps, mapDispatchToProps)(injectIntl(PartnerRegistration)));
