import clsx from 'clsx'
import {FC} from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { ProfileModel } from '../../../../app/modules/auth/models/ProfileModel'
import { RootState } from '../../../../setup/redux/Store'
import { KTSVG } from '../../../helpers'
import { HeaderNotificationsMenu, HeaderUserMenu } from '../../../partials'
import { Link } from 'react-router-dom'
import HasPerm from '../../../../app/modules/auth/redux/HasPerm'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'
type Props = {
  setShowSearch: any
 }
const Topbar: FC<Props> = ({setShowSearch}) => {
  const user: ProfileModel = useSelector<RootState>(({auth}) => auth.profile, shallowEqual) as ProfileModel
  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>

      <div className='topbar d-flex align-items-stretch flex-shrink-0'>
        <HasPerm permission='areas.area.add'>
        <Link to={'/areas/report'}>
            <div className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px mt-2 me-2'>
            <KTSVG path='/media/icons/duotune/general/gen035.svg' className='svg-icon-1' />
            </div>
          </Link>
        </HasPerm>
      <div
          className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
          id='kt_header_user_menu_toggle'
        >
         
          <div className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px mt-2 me-2' onClick={setShowSearch.setShowSearch}>
            <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-1' />
          </div>
          <div
            className={clsx('cursor-pointer symbol me-2', toolbarUserAvatarHeightClass)}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            {(user.notifications && user.notifications > 0) ? (
              <span className='badge badge-warning position-absolute fs-8 w-30 h-20px' style={{top: "-0.3em", right: "-0.3em"}}>{user.notifications}</span>
            ):''}
            <div className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px mt-2 me-2'>
              <KTSVG path='/media/icons/duotune/general/gen007.svg' className='svg-icon-1' />
          </div>
          </div>
          <HeaderNotificationsMenu />
        </div>
        <div
          className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
          id='kt_header_user_menu_toggle'
        >
          <div
            className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <div className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px mt-2 me-2'>
              <KTSVG path='/media/icons/duotune/communication/com006.svg' className='svg-icon-1' />
          </div>
          </div>
          <HeaderUserMenu />
        </div>
      </div>
    </div>
  )
}

export { Topbar }
