import { useEffect, useState} from 'react'
import { useIntl } from 'react-intl'
import { API_URL, KTSVG } from '../../../../../_metronic/helpers'
import { getWorkflowStatus } from '../../../../pages/dashboard/DashboardWrapper'
import { AreasFilter, AreasFilterWorkflow, TableFilters } from '../../models/AreaModel'
import axios from 'axios'
import Select from 'react-select'

type Props = {
    filters: AreasFilter
    filtersLoading: boolean
    fetchAreas: any
    map_view: boolean
    setShow: any
    showStatus: any
    filterStatus: string
    filterWorkflow: string
    tableFilters: TableFilters
  }

const Filter: React.FC<Props> = ({
    filters, filtersLoading, fetchAreas, map_view, setShow, showStatus, filterStatus, filterWorkflow, tableFilters }) => {
  
  const [status, setStatus] = useState<string | undefined>()
  const [_tableFilters, setTableFilters] = useState<TableFilters|undefined>(tableFilters)
  const [managers, setManagers] = useState<any[]>([])

  const fetchAreaManagers = () =>{
    axios.get(API_URL+"/areas/managers").then(res=>{
      setManagers(res.data)
    })
  }
  
  useEffect(()=>{
    
    fetchAreaManagers()

  }, [])

  const intl = useIntl();
  const handleShowStatusChange = (status:string)=>{
    let _status = {...showStatus};
    _status[status] = !_status[status];
    setShow(_status);
  }

  const getManagerOptions = () => {
    const selectOptions:{ label: string; value: string }[] = []
    managers.map(manager=>selectOptions.push({label:manager.name, value:manager.id}))
    return selectOptions
    }

  return (
    <>
      <button
        disabled={filtersLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        data-kt-menu-permanent='true'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Filter
      </button>
      <div className='menu menu-sub menu-sub-dropdown w-650px w-md-700px' data-kt-menu='true'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter</div>
        </div>
        <div className='separator border-gray-200'></div>
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          <div className='row'>
            <div className='col-4'>
              <div className='mb-4'>
                <label className='form-label fs-6 fw-bold'>Status:</label>
                <select
                  className='form-select form-select-solid fw-bolder'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  data-kt-user-table-filter='role'
                  data-hide-search='true'
                  onChange={(e) => setStatus(e.target.value)}
                  value={status}
                >
                  <option value=""></option>
                  {filters.status.map((status: string, index: number) => (<option key={index} selected={filterStatus !== '' &&  status.includes(filterStatus)} value={status.includes(':')?status.split(":")[2]:status}>{getWorkflowStatus(status)}</option>))}
                </select>
              </div>
              <div className='mb-4'>
                <label className='form-label fs-6 fw-bold'>Workflow:</label>
                <select
                  className='form-select form-select-solid fw-bolder'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  data-kt-user-table-filter='role'
                  data-hide-search='true'
                  // onChange={(e) => setWorkflow(e.target.value)}
                  onChange={(e) => setTableFilters({..._tableFilters, workflow:e.target.value})}
                  defaultValue={tableFilters?.workflow}
                >
                  <option value=""></option>
                  {filters.workflow && filters.workflow.map((workflow: AreasFilterWorkflow, index: number) => (<option key={index} value={workflow.id}>{workflow.name}</option>))}
                </select>
              </div>
              <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>{intl.formatMessage({id: "AREA.MANAGEMENT.TABLE.FORSTFARMER"})}:</label>
                <Select
                  // defaultValue={[]}
                  isMulti
                  name="colors"
                  options={getManagerOptions()}
                  className="basic-multi-select"
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  data-kt-user-table-filter='role'
                  data-hide-search='true'
                  onChange={(e) => {
                    const options:string[] = []
                    e.map(manager=>options.push(manager.value))
                    setTableFilters({..._tableFilters, managers:options})
                  }}
                />
              </div>
            </div>

            <div className='col-md-4'>
              <div className='mb-4'>
                <label className='form-label fs-6 fw-bold'>{intl.formatMessage({id: 'AREA.MANAGEMENT.TABLE.TREES'})}:</label>
                <select
                  className='form-select form-select-solid fw-bolder'
                  onChange={(e) => setTableFilters({..._tableFilters, trees:e.target.value})}
                  defaultValue={tableFilters?.trees}
                >
                  <option value=""></option>
                    <option value={'to_plant'}>{intl.formatMessage({id: 'AREA.MANAGEMENT.SETTINGS.TREES_TO_PLANT'})}</option>
                    <option value={'planted'}>{intl.formatMessage({id: 'AREA.MANAGEMENT.SETTINGS.TREES_PLANTED'})}</option>
                    <option value={'zero'}>{intl.formatMessage({id: 'GENERAL.NO_RESULTS'})}</option>

                </select>
              </div>
              <div className='mb-4'>
                <label className='form-label fs-6 fw-bold'>{intl.formatMessage({id: 'AREA.MENU.JOBS'})}:</label>
                <select
                  className='form-select form-select-solid fw-bolder'
                  onChange={(e) => setTableFilters({..._tableFilters, jobs:e.target.value})}
                  defaultValue={tableFilters?.jobs}
                >
                  <option value=""></option>
                    <option value={'open'}>{intl.formatMessage({id: 'AREA.MANAGEMENT.JOB.STATUS.OPENED'})}</option>
                    <option value={'in_progress'}>{intl.formatMessage({id: 'AREA.MANAGEMENT.JOB.STATUS.IN_PROGRESS'})}</option>
                    <option value={'done'}>{intl.formatMessage({id: 'AREA.MANAGEMENT.JOB.STATUS.DONE'})}</option>

                </select>
              </div>
              <div className='mb-4'>
                <label className='form-label fs-6 fw-bold'>{intl.formatMessage({id: 'AREAS.TODOS.TITLE'})}:</label>
                <select
                  className='form-select form-select-solid fw-bolder'
                  onChange={(e) => setTableFilters({..._tableFilters, todos:e.target.value})}
                  value={_tableFilters?.todos}
                >
                  <option value=""></option>
                    <option value={'open'}>{intl.formatMessage({id: 'AREA.MANAGEMENT.JOB.STATUS.OPENED'})}</option>
                    <option value={'in_progress'}>{intl.formatMessage({id: 'AREA.MANAGEMENT.JOB.STATUS.IN_PROGRESS'})}</option>
                    <option value={'done'}>{intl.formatMessage({id: 'AREA.MANAGEMENT.JOB.STATUS.DONE'})}</option>

                </select>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='mb-4'>
                <label className='form-label fs-6 fw-bold'>{intl.formatMessage({id: 'AREA.MENU.PLOTS'})}:</label>
                <select
                  className='form-select form-select-solid fw-bolder'
                  onChange={(e) => setTableFilters({..._tableFilters, plots:e.target.value})}
                  defaultValue={tableFilters?.plots}
                >
                  <option value=""></option>
                    <option value={'open'}>{intl.formatMessage({id: 'AREA.MANAGEMENT.JOB.STATUS.OPENED'})}</option>
                    <option value={'in_progress'}>{intl.formatMessage({id: 'AREA.MANAGEMENT.JOB.STATUS.IN_PROGRESS'})}</option>
                    <option value={'done'}>{intl.formatMessage({id: 'AREA.MANAGEMENT.JOB.STATUS.DONE'})}</option>

                </select>
              </div>
              {map_view && (
                <>
                <div className='d-flex mb-2'>
                <label className='col-form-label fw-bold fs-6'>{intl.formatMessage({id: `AREA.MANAGEMENT.SETTINGS.STATUS.OPEN`})}</label>
                  <div className='form-check form-check-solid form-switch fv-row  me-2'>
                    <input
                      className='form-check-input w-45px h-30px mt-2'
                      type='checkbox'
                      onChange={()=>handleShowStatusChange('open')}
                      checked={showStatus.open}
                    />
                  </div>
                  <label className='col-form-label fw-bold fs-6'>{intl.formatMessage({id: `AREA.MANAGEMENT.SETTINGS.STATUS.IN_PROGRESS`})}</label>
                  <div className='form-check form-check-solid form-switch fv-row'>
                    <input
                      className='form-check-input w-45px h-30px mt-2'
                      type='checkbox'
                      onChange={()=>handleShowStatusChange('inProgress')}
                      checked={showStatus.inProgress}
                    />
                  </div>
                </div>
                <div className='d-flex mb-10'>
                <label className='col-form-label fw-bold fs-6'>{intl.formatMessage({id: `AREA.MANAGEMENT.SETTINGS.STATUS.DONE`})}</label>
                  <div className='form-check form-check-solid form-switch fv-row  me-2'>
                    <input
                      className='form-check-input w-45px h-30px mt-2'
                      type='checkbox'
                      onChange={()=>handleShowStatusChange('done')}
                      checked={showStatus.done}
                    />
                  </div>
                  <label className='col-form-label fw-bold fs-6'>{intl.formatMessage({id: `AREA.MANAGEMENT.SETTINGS.STATUS.DECLINED`})}</label>
                  <div className='form-check form-check-solid form-switch fv-row'>
                    <input
                      className='form-check-input w-45px h-30px mt-2'
                      type='checkbox'
                      onChange={()=>handleShowStatusChange('declined')}
                      checked={showStatus.declined}
                    />
                  </div>
                </div>
                </>
              )}
              </div>
          </div>
          

          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={filtersLoading}
              onClick={()=>fetchAreas('', undefined)}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={filtersLoading}
              onClick={()=>fetchAreas(status, _tableFilters)}
              type='button'
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </>
    )
}

export {Filter}