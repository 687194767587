/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import Skeleton from 'react-loading-skeleton'

type Props = {

}

const AreaDocumentCardLoading: FC<Props> = () => {
  return (
    <div className='card h-100'>
      <div className='d-flex justify-content-between'>        
        </div>
      <div className='card-body d-flex jussstify-content-center text-center flex-column p-8'>
        
          <div className='symbol symbol-75px mb-6'>
            <Skeleton width={"75px"} height={"75px"} />
          </div>
          <Skeleton />
      </div>
    </div>
  )
}

export {AreaDocumentCardLoading}
