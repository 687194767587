import { Store } from "redux"
import { loginSuccess, logoutSuccess } from "../../app/modules/auth"
import { API_URL } from "../../_metronic/helpers"

export default function setupAxios(axios: any, store: Store) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: any) => {
      const {
        auth: {auth},
      } = store.getState()

      if (auth.accessToken) {
        config.headers.Authorization = `JWT ${auth.accessToken}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
  axios.interceptors.response.use(
    (response: any) => {
      return response;
    },
    async (error:any) => {
      if (error.response.status === 401) {
        try {   
        let apiResponse = await axios.post(
          API_URL+"/api-token-refresh/",
          {token: store.getState().auth.auth.accessToken}
        );
        store.dispatch({ type: loginSuccess.type, payload: { accessToken: apiResponse.data.token } })
        error.config.headers.Authorization = `JWT ${apiResponse.data.token}`;
        return axios(error.config);
        } catch(e:any){
          if(JSON.stringify(e.response.data).includes('Signature has expired')){
            store.dispatch({type: logoutSuccess.type})
          }
        }
      } else {
        return Promise.reject(error);
      }
    }
  );
}
