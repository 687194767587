/* eslint-disable jsx-a11y/anchor-is-valid */

import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useFormik } from 'formik'
import store from '../../../../setup/redux/Store'
import { useIntl } from 'react-intl'
import axios from 'axios'
import { fetchProfileFail, fetchProfileLoading, fetchProfileSuccess, loginFail, loginLoading, loginSuccess } from '../redux/AuthRedux'
import { GoogleLogin } from 'react-google-login';
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { ReleaseNote } from '../../areas/models/AreaModel'
import { showModal } from '../../modals/redux/ModalRedux'
import { Dispatch } from 'redux'
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

const API_URL = process.env.REACT_APP_TPP_API;

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

// const facebookLoginResponse = (response: any) => {
//   console.log(response);
// }

const checkNewReleaseNote = (dispatch:Dispatch) => {
  axios.get(API_URL+"/release-notes").then(res=>{
    let latest_note:ReleaseNote = res.data.results[0]
    if(latest_note){
      if(localStorage.getItem('release-note')===latest_note.id){
        return
      }
      dispatch({ type: showModal.type, payload: {modal: 'new-release-note', data: {} } })
    }
  })
}

export default function Login() {
  const dispatch = useDispatch()
  const loading = store.getState().auth.authLoading
  const googleLoginResponse = (response: any) => {
    axios.post(API_URL+"/rest-auth/google/", {access_token: response.accessToken}).then(authRes=>{
      axios.get(API_URL + '/profiles', {
        headers: {
          Authorization: 'JWT ' + authRes.data.token
        }
      })
            .then(res => {
              dispatch({ type: fetchProfileSuccess.type, payload: res.data })
              dispatch({ type: loginSuccess.type, payload: { access: authRes.data.token } })
              checkNewReleaseNote(dispatch);
            })
            .catch(() => {
              dispatch({ type: fetchProfileFail.type });
            })

    })
  }
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      dispatch({ type: loginLoading.type });

      const resToken = await new Promise<any>((resolve, reject) => {
        axios.post(API_URL + '/api-token-auth/', {
          username: values.email,
          password: values.password
        })
          .then(res => resolve(res))
          .catch(e => {
            if (e.response && e.response.data && e.response.data.detail) {
              setStatus(e.response.data.detail);
            }

            dispatch({ type: loginFail.type });
            reject();
          });
      });

      if (resToken && resToken.data.token) {
        dispatch({ type: fetchProfileLoading.type });

        const resProfiles = await new Promise<any>((resolve, reject) => {
          axios.get(API_URL + '/profiles', {
            headers: {
              Authorization: 'JWT ' + resToken.data.token
            }
          })
            .then(res => resolve(res))
            .catch(() => {
              dispatch({ type: fetchProfileFail.type });
              reject();
            });
        });

        dispatch({ type: fetchProfileSuccess.type, payload: resProfiles.data });
        dispatch({ type: loginSuccess.type, payload: { access: resToken.data.token } });
        checkNewReleaseNote(dispatch);
      } else {
        dispatch({ type: loginFail.type });
      }

      setSubmitting(false);
    },
  })

  const intl = useIntl()

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>{intl.formatMessage({ id: 'AUTH.LOGIN.TITLE' })}</h1>
        {/* <div className='text-gray-400 fw-bold fs-4'>
          Neu bei TPP?{' '}
          <Link to='/auth/registration' className='link-primary fw-bolder'>
            Jetzt mitmachen
          </Link>
        </div> */}
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        ""
      )}

      <div className="fv-row">
        <GoogleLogin
          clientId="517857437085-6hnjreb3rfs0mhtbttefeojtataf1csa.apps.googleusercontent.com"
          render={renderProps => (
            <button
              type='button'
              className='btn btn-lg btn-info w-100 mb-5'
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
            >
              <div className='d-flex justify-content-start align-items-md-center'>
              <div className='symbol symbol-35px me-5 bg-light-danger p-1 rounded'>
                <img src={toAbsoluteUrl("/media/logos/GoogleLogo.png")} className='' alt='' style={{objectFit: "contain"}}/>
            </div>
            <span className='indicator-label fs-4'>{intl.formatMessage({ id: 'AUTH.LOGIN.GOOGLE_BUTTON' })}</span>
            </div>
            </button>
          )}
          buttonText="Login"
          onSuccess={googleLoginResponse}
          onFailure={googleLoginResponse}
          cookiePolicy={'single_host_origin'}
        />
      </div>
{/* 
      <div className="fv-row mb-5">
        <FacebookLogin
          appId="1088597931155576"
          autoLoad
          callback={facebookLoginResponse}
          render={(renderProps: any) => (
            <button
              type='submit'
              className='btn btn-lg btn-info w-100 mb-5'
              onClick={renderProps.onClick}
              disabled={renderProps.isDisabled}
            >
              <span className='indicator-label'>{intl.formatMessage({ id: 'AUTH.LOGIN.FACEBOOK_BUTTON' })}</span>
            </button>
          )}
        />
      </div> */}

      <div className="fv-row mb-5">
        <div className='text-info text-center'>
          - oder -
        </div>
      </div>

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>{intl.formatMessage({ id: 'AUTH.INPUT.PASSWORD' })}</label>
            {/* end::Label */}
            {/* begin::Link */}
            {/* <Link
              to='/auth/forgot-password'
              className='link-primary fs-6 fw-bolder'
              style={{ marginLeft: '5px' }}
            >
              {intl.formatMessage({ id: 'AUTH.FORGOT.TITLE' })}
            </Link> */}
            {/* end::Link */}
          </div>
        </div>
        <input
          type='password'
          placeholder={intl.formatMessage({ id: 'AUTH.INPUT.PASSWORD' })}
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid || loading}
        >
          {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'AUTH.LOGIN.BUTTON' })}</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              {intl.formatMessage({ id: 'AUTH.GENERAL.LOADING' })}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>

      {/* end::Action */}
    </form>
  )
}
