/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

type Props = {
  className: string
  color: string
  icon: IconProp
  iconColor: string
  title: string
  description: string
}

const Stats: React.FC<Props> = ({
  className,
  color,
  icon,
  iconColor,
  title,
  description,
}) => {
  return (
    <a href='#' className={`card bg-${color} hoverable ${className}`}>
      {/* begin::Body */}
      <div className='card-body'>
        <FontAwesomeIcon size='3x' icon={icon} className={`svg-icon-${iconColor} svg-icon-3x ms-n1`} />

        <div className={`text-inverse-${color} fw-bolder fs-2 mb-2 mt-5`}>{title}</div>

        <div className={`fw-bold text-inverse-${color} fs-7`}>{description}</div>
      </div>
      {/* end::Body */}
    </a>
  )
}

export {Stats}
