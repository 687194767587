/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { connect } from 'react-redux'
import { AppDispatch, RootState } from '../../../../../setup/redux/Store'
import { KTSVG } from '../../../../../_metronic/helpers'
import HasPerm from '../../../auth/redux/HasPerm'

import { AreaDocumentCard } from '../../AreaDocumentCard'
import { AreaDocumentCardLoading } from '../../AreaDocumentCardLoading'
import { AreaDocument } from '../../models/AreaModel'
import { deleteDocument, fetchAreaDocuments, renameDocument, uploadAreaDocument } from '../../redux/AreaRedux'

interface WorkflowProps {
  area: string
}

interface WorkflowState {
  fileUploadState: string,
  file: string|null
}

interface ConnectedProps {
  documents: AreaDocument[]
  documentActionLoading: boolean
  loading: boolean
}

interface DispatchProps {
  fetchAreaDocuments: typeof fetchAreaDocuments;
  uploadAreaDocument: typeof uploadAreaDocument;
  renameDocument: typeof renameDocument;
  deleteDocument: typeof deleteDocument;
}

type Props = ConnectedProps & DispatchProps & WorkflowProps


class AreaDocuments extends React.Component<Props, WorkflowState>  {
  private inputReference: React.RefObject<HTMLInputElement>;
  constructor(props: any) {
    super(props)
    this.state = {
      fileUploadState: "",
      file: null
    }
    this.inputReference = React.createRef();

  }

  componentDidMount() {
    this.props.fetchAreaDocuments(this.props.area)
  }

  fileUploadAction = () => this.inputReference.current && this.inputReference.current.click();
  fileUploadInputChange = (e:any) => {
    var size = e.target.files[0].size / 1024 / 1024; // in MiB
    if(size > 5) {
      alert("Datei zu groß")
      return ""
    }

    this.setState({ fileUploadState: e.target.value });
    this.setState({ file: e.target.files[0] });

    this.props.uploadAreaDocument(this.props.area, e.target.files[0])

  }

  groupDocuments(documents:AreaDocument[]) {
    let items: AreaDocument[][] = [];
    let counter = 0;
    let index = 0;
    documents.forEach(doc=>{
      if(counter > 2) {
        index = index + 1;
        counter = 0 ;
      
      } else {
        counter = counter + 1;
      }
      items[index] = [...items[index] || [], doc] || [doc];
    })
    return items;

  }

  render() {
    return (
      <>
        <div className='d-flex flex-wrap flex-stack mb-6'>
          <h3 className='fw-bolder my-2'>
            Dokumente
            {/* <span className='fs-6 text-gray-400 fw-bold ms-1'>100+ resources</span> */}
          </h3>

          <div className='d-flex my-2'>
            <div className='d-flex align-items-center position-relative me-4'>
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-3 position-absolute ms-3'
              />
              <input
                type='text'
                id='kt_filter_search'
                className='form-control form-control-white form-control-sm w-150px ps-9'
                placeholder='Suche'
              />
            </div>

            <HasPerm permission='areas.areadocument.add'>
              <button type='button' className='btn btn-primary btn-sm' onClick={this.fileUploadAction}>
                Hochladen
              </button>
            </HasPerm>

            <input
              type="file"
              style={{ display: "none" }}
              name="fileUpload"
              hidden
              accept="*/*"
              ref={this.inputReference}
              onChange={this.fileUploadInputChange}
            />
          </div>
        </div>
        {this.props.loading && (
        <div className='row g-6 g-xl-9 mb-6 mb-xl-9'>
          <div className='col-md-3'>
            <AreaDocumentCardLoading />
          </div>
          <div className='col-md-3'>
            <AreaDocumentCardLoading />
          </div>
          <div className='col-md-3'>
            <AreaDocumentCardLoading />
          </div>
          <div className='col-md-3'>
            <AreaDocumentCardLoading />
          </div>
        </div>
        )}
        {!this.props.loading && this.groupDocuments(this.props.documents).map((document_group: AreaDocument[], index: number) => (
          <div key={index} className='row g-6 g-xl-9 mb-6 mb-xl-9'>
            {document_group.map((document: AreaDocument, secondex: number) => (
              <div key={secondex} className='col-12 col-sm-12 col-xl'>
                <AreaDocumentCard
                  key={document.id} 
                  icon={`/media/svg/files/${document.filetype}.svg`} 
                  title={document.name} 
                  description={"vor "+timeSince(document.created_at)} 
                  link={document.file} 
                  document={document}
                  handleRename={this.props.renameDocument}
                  handleDelete={this.props.deleteDocument}
                  loading={this.props.documentActionLoading}
                  />
              </div>
            ))}
          </div>
        ))}
      </>    
    )
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    documents: state.areas.documents || [],
    documentActionLoading: state.areas.documentActionLoading,
    loading: state.areas.documentsLoading
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
      fetchAreaDocuments: (area:string) => dispatch(fetchAreaDocuments(area)),
      uploadAreaDocument: (area:string, file:File) => dispatch(uploadAreaDocument(area, file)),
      renameDocument: (area: string, document: string, name: string) => dispatch(renameDocument(area, document, name)),
      deleteDocument: (area: string, document: string) => dispatch(deleteDocument(area, document))
  };
};


export default connect<ConnectedProps, DispatchProps>(mapStateToProps, mapDispatchToProps)(AreaDocuments);


export function timeSince(date:string) {

  var seconds = Math.floor((+new Date() - +new Date(date)) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " Jahren";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " Monaten";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " Tage";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " Stunden";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " Minuten";
  }
  return Math.floor(seconds) + " Sekunden";
}