import {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import { UsersListWrapper } from '../modules/apps/chat/user-management/users-list/UsersList'
import AdminPageWrapper from '../modules/areas/admin/AdminPageWrapper'
import AdminSwitchProfilePage from '../modules/areas/admin/AdminSwitchProfilePage'
import WorkflowEditPage from '../modules/areas/layout-builder/WorkflowEditPage'
import WorkflowPageWrapper from '../modules/areas/layout-builder/WorkflowPageWrapper'
import ReportAreaPage from '../modules/areas/report/ReportAreaPage'
import PartnerPage from '../modules/partners/PartnerPage'
import {DashboardWrapper, MapWrapper} from '../pages/dashboard/DashboardWrapper'
// import { JoinDashboardWrapper } from '../pages/dashboard/JoinDashboard'
import {MenuTestPage} from '../pages/MenuTestPage'


// import {shallowEqual, useSelector} from 'react-redux'
// import {RootState} from '../../setup'

export function PrivateRoutes() {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const AreaPage = lazy(() => import('../modules/areas/AreaPage'))
  const ReleaseNotesPage = lazy(() => import('../modules/release-notes/ReleaseNotesPage'))

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/areas/report' component={ReportAreaPage} />
        <Route path='/area/:area' component={AreaPage} />
        {/* <Route path='/join' component={JoinDashboardWrapper} /> */}
        {/* <ProtectedRoute> */}
          <Route path='/admin/switchProfile/:profile' component={AdminSwitchProfilePage} />
          <Route path='/admin' component={AdminPageWrapper} />
          <Route path='/profiles/:profileId' component={AccountPage} />
          <Route path='/profiles' component={UsersListWrapper} />
          <Route path='/profile' component={AccountPage} />
          <Route path='/areas' component={DashboardWrapper} />
          <Route path='/map' component={MapWrapper} />
          <Route path='/release-notes' component={ReleaseNotesPage} />
        {/* </ProtectedRoute> */}
        <Route path='/workflow/:workflow' component={WorkflowEditPage} />
        <Route path='/workflow' component={WorkflowPageWrapper} />

        {/* <ProtectedRoute> */}
          <Route path='/partners' component={PartnerPage} />
        {/* </ProtectedRoute> */}
        {/* <ProtectedRoute> */}
        {/* </ProtectedRoute> */}
        {/* <ProtectedRoute> */}
        {/* </ProtectedRoute> */}
        {/* <ProtectedRoute> */}
        {/* </ProtectedRoute> */}
        {/* <Route path='/dashboard' component={MainDashboardWrapper} /> */}
        <Route path='/builder' component={BuilderPageWrapper} />
        <Route path='/crafted/pages/profile' component={ProfilePage} />
        <Route path='/crafted/pages/wizards' component={WizardsPage} />
        <Route path='/crafted/widgets' component={WidgetsPage} />
        <Route path='/crafted/account' component={AccountPage} />
        <Route path='/apps/chat' component={ChatPage} />
        <Route path='/menu-test' component={MenuTestPage} />
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect from='/dashboard' to='/partners/dashboard' />
        <Redirect to='/error/404' />
      </Switch>
    </Suspense>
  )
}

// type ProtectedRouteProps = {
//   children: JSX.Element
// }

// const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
//   const isAuthorized = useSelector<RootState>(({auth}) => auth.auth.accessToken, shallowEqual)

//   if (!isAuthorized) {
//     return <Redirect to="/auth/login" />;
//   }

//   return children;
// };