/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { faSave, faTimes, } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Field, Form, Formik } from 'formik'
import React from 'react'
import { Modal } from 'react-bootstrap-v5'
import { useIntl } from 'react-intl'
import { AreaJobTemplate, AreaWorkflowStageTemplate, Workflow } from '../models/AreaModel'
import { updateTemplateStage, updateTemplateStep } from '../redux/AreaRedux'
import * as Yup from 'yup';

type Props = {
  show: boolean
  data: {
    type: 'stage' | 'step',
    template: any
  };
  handleClose: () => void
  loading: boolean
  stages: AreaWorkflowStageTemplate[]
  onSubmit: typeof updateTemplateStage
  workflow: string
  jobTemplates: AreaJobTemplate[]
  workflows: Workflow[]
  onSubmit2: typeof updateTemplateStep
}

const WorkflowEditItemModal: React.FC<Props> = ({ show, data, handleClose, loading, stages, workflow, onSubmit, jobTemplates, workflows, onSubmit2 }) => {
  const intl = useIntl();

  const isValidSource = (source: string) => {
    if (data?.template.step_data._type < 3) {
      const re = new RegExp('[a-zA-Z]+[.]{1}[a-zA-Z]+');
      return re.test(source);
    } else {
      return Boolean(source);
    }
  }

  Yup.addMethod(Yup.string, "creditCardType", function (errorMessage) {
    return this.test(`test-card-type`, errorMessage, function (value) {
      return true
    });
  });

  const initialValuesStageForm = {
    name: data.template?.stage_name,
  }

  const validationSchemaStageForm = Yup.object({
    name: Yup.string().required()
  });

  const onSubmitStageForm = (values: any) => {
    onSubmit(workflow, data?.template.id, values.name);
  };

  const initialValuesStepForm = {
    name: data.template?.step_name,
    source: data.template.step_data?.data,
    type: String(data.template.step_data?._type)
  }

  const validationSchemaStepForm = Yup.object().shape({
    name: Yup.string().required(),
    source: Yup.string().required().test('is-valid-source', '', (value) => isValidSource(value as string)),
    type: Yup.string().required()
  });

  const onSubmitStepForm = (values: any) => {
    onSubmit2(workflow, stages.filter(stage => stage.stage_steps.indexOf(data?.template.id) > -1)[0].id, data?.template.id, values.name, values.source, values.type);
  };

  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-1000px h-auto'
      show={show}
      onHide={handleClose}
    >
      <div className='container-xxl px-10 py-10'>
        <div className='modal-header py-2 d-flex justify-content-end border-0'>
          {/* begin::Close */}
          <div className='btn btn-icon btn-sm btn-light-primary' onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          {/* end::Close */}
        </div>

        <div className='modal-body'>
          {/*begin::Stepper */}
          <div
            className='d-flex flex-column flex-xl-row flex-row-fluid'
          >
            {/*begin::Aside */}
            <div className='d-flex justify-content-center justify-content-xl-start flex-row-auto '>

            </div>
            {/*begin::Aside */}

            {/*begin::Content */}
            <div className='d-flex flex-row-fluid justify-content-center'>
              {/*begin::Form */}
              {data?.type === 'stage' && (
                <Formik
                  initialValues={initialValuesStageForm}
                  validationSchema={validationSchemaStageForm}
                  onSubmit={(values) => onSubmitStageForm(values)}
                >
                  {({ isSubmitting, isValid, dirty }) =>
                    <Form className='pb-5 w-100 w-md-400px w-xl-500px'>
                      <div className='pb-5 current' data-kt-stepper-element='content'>
                        <div className='w-100'>
                          <div className='pb-5 pb-lg-10'>
                            <h3 className='fw-bolder text-dark display-6'>{intl.formatMessage({ id: 'AREA.MANAGEMENT.WORKFLOW.EDIT_ITEM_TEMPLATE' })}</h3>
                          </div>

                          <div className='fv-row mb-12'>
                            <label className='fs-6 fw-bolder text-dark form-label'>{intl.formatMessage({ id: 'AREA.MANAGEMENT.WORKFLOW.CREATE_ITEM_TEMPLATE.STAGE_NAME' })}</label>

                            <Field
                              name='name'
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                            ></Field>
                          </div>
                        </div>
                      </div>

                      <div className='d-flex justify-content-between pt-10'>
                        <div className='mr-2'></div>

                        <div>
                          <button
                            type='submit'
                            className='btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3'
                            disabled={!dirty || !isValid}
                          >
                            {isSubmitting && (
                              <span className='indicator-progress' style={{ display: 'block' }}>
                                {intl.formatMessage({ id: 'GENERAL.LOADING' })}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                            )}
                            {!isSubmitting && (
                              <>
                                {intl.formatMessage({ id: 'GENERAL.SAVE' })}{' '}
                                <FontAwesomeIcon
                                  icon={faSave}
                                  className='svg-icon-3 ms-1'
                                />
                              </>
                            )}
                          </button>
                        </div>
                      </div>
                    </Form>
                  }
                </Formik>
              )}

              {data?.type === 'step' && (
                <Formik
                  initialValues={initialValuesStepForm}
                  validationSchema={validationSchemaStepForm}
                  onSubmit={(values) => onSubmitStepForm(values)}
                >
                  {({ isSubmitting, isValid, dirty, values }) =>
                    <Form className='pb-5 w-100 w-md-400px w-xl-500px'>
                      <div className='pb-5 current' data-kt-stepper-element='content'>
                        <div className='w-100'>
                          <div className='pb-5 pb-lg-10'>
                            <h3 className='fw-bolder text-dark display-6'>{intl.formatMessage({ id: 'AREA.MANAGEMENT.WORKFLOW.EDIT_ITEM_TEMPLATE' })}</h3>
                          </div>

                          <div className='row mb-5'>
                            <div className='col-md-12'>
                              <div className='fv-row'>
                                <label className='fs-6 fw-bolder text-dark form-label'>{intl.formatMessage({ id: 'AREA.MANAGEMENT.WORKFLOW.CREATE_ITEM_TEMPLATE.STEP_NAME' })}</label>

                                <Field
                                  name='name'
                                  type='text'
                                  className='form-control form-control-lg form-control-solid'
                                ></Field>
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-md-6'>
                              <div className='fv-row mb-12'>
                                <label className='fs-6 fw-bolder text-dark form-label'>{intl.formatMessage({ id: 'AREA.MANAGEMENT.WORKFLOW.CREATE_ITEM_TEMPLATE.STEP_TYPE' })}</label>

                                <Field
                                  name='type'
                                  as='select'
                                  placeholder={values.type === '1' ? "area.size" : "pachtvertrag.pdf"}
                                  className='form-select form-select-solid'
                                >
                                  <option value='1'>{intl.formatMessage({ id: 'AREA.MANAGEMENT.WORKFLOW.CREATE_ITEM_TEMPLATE.STEP_DATA' })}</option>
                                  <option value='2'>{intl.formatMessage({ id: 'AREA.MANAGEMENT.WORKFLOW.CREATE_ITEM_TEMPLATE.STEP_DOCUMENT' })}</option>
                                  <option value='3'>{intl.formatMessage({ id: 'AREA.MANAGEMENT.WORKFLOW.CREATE_ITEM_TEMPLATE.STEP_JOB' })}</option>
                                  <option value='4'>{intl.formatMessage({ id: 'AREA.MANAGEMENT.WORKFLOW.CREATE_ITEM_TEMPLATE.STEP_PLOT' })}</option>
                                  <option value='5'>{intl.formatMessage({ id: 'AREA.MANAGEMENT.WORKFLOW.CREATE_ITEM_TEMPLATE.STEP_TODO' })}</option>
                                </Field>
                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className='fv-row'>
                                {values.type === '1' || values.type === '2' ? (
                                  <>
                                    <label className='fs-6 fw-bolder text-dark form-label'>{intl.formatMessage({ id: 'AREA.MANAGEMENT.WORKFLOW.CREATE_ITEM_TEMPLATE.STEP_SOURCE' })}</label>

                                    <Field
                                      name='source'
                                      type='text'
                                      placeholder={values.type === '1' ? "area.size" : "pachtvertrag.pdf"}
                                      className='form-control form-control-lg form-control-solid'
                                    ></Field>
                                  </>
                                ) : (
                                  values.type === '3' ? (
                                    <>
                                      <label className='fs-6 fw-bolder text-dark form-label'>{intl.formatMessage({ id: 'AREA.MANAGEMENT.WORKFLOW.CREATE_ITEM_TEMPLATE.STEP_SOURCE' })}</label>

                                      <Field
                                        name='source'
                                        as='select'
                                        className='form-select form-select-solid'
                                      >
                                        {jobTemplates.map((job: AreaJobTemplate) => (
                                          <option key={job.id} value={job.id}>{job.name}</option>
                                        ))}
                                      </Field>
                                    </>
                                  ) : (
                                    values.type === '5' ? (
                                      <>
                                        <label className='fs-6 fw-bolder text-dark form-label'>{intl.formatMessage({ id: 'AREA.MANAGEMENT.WORKFLOW.CREATE_ITEM_TEMPLATE.STEP_TODO' })}</label>

                                        <Field
                                          name='source'
                                          type='text'
                                          className='form-control form-control-lg form-control-solid'
                                        ></Field>
                                      </>
                                    ) : (
                                      <>
                                        <label className='fs-6 fw-bolder text-dark form-label'>{intl.formatMessage({ id: 'AREA.MANAGEMENT.WORKFLOW.CREATE_ITEM_TEMPLATE.PLOT_DEFAULTS' })}</label>

                                        <Field
                                          name='source'
                                          as='select'
                                          className='form-select form-select-solid'
                                        >
                                          {workflows
                                            .filter(_workflow => _workflow.id !== workflow)
                                            .map((workflow: Workflow) => (
                                              <option key={workflow.id} value={workflow.id}>{workflow.name}</option>
                                            ))}
                                        </Field>
                                      </>
                                    )
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='d-flex justify-content-between pt-10'>
                        <div className='mr-2'></div>

                        <div>
                          <button
                            type='submit'
                            className='btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3'
                            disabled={!isValid || !dirty}
                          >
                            {isSubmitting && (
                              <span className='indicator-progress' style={{ display: 'block' }}>
                                {intl.formatMessage({ id: 'GENERAL.LOADING' })}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                            )}
                            {!isSubmitting && (
                              <>
                                {intl.formatMessage({ id: 'GENERAL.SAVE' })}{' '}
                                <FontAwesomeIcon
                                  icon={faSave}
                                  className='svg-icon-3 ms-1'
                                />
                              </>
                            )}
                          </button>
                        </div>
                      </div>
                    </Form>
                  }
                </Formik>
              )}
              {/*end::Form */}
            </div>
            {/*end::Content */}
          </div>
          {/* end::Stepper */}
        </div>
        <div>

        </div>
      </div>
    </Modal >
  )
}

export { WorkflowEditItemModal }
