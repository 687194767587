/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { faPerson, faTimes, faTrees, faUserHelmetSafety } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Component } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { AppDispatch, RootState } from '../../../../setup/redux/Store'
import { API_URL, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { getWorkflowStatus } from '../../../pages/dashboard/DashboardWrapper'
import { AREA_STATUS_BADGE } from '../AreaHeader'
import { AreaModel, AreaOwnerDetails } from '../models/AreaModel'
import { timeSince } from './documents/AreaDocuments'
import { SkeletonRow } from './SkeletonRow'



interface CreateComment {
  show: boolean
  hideModal: ()=>void
}

interface CreateCommentModalState {
  areas: AreaModel[]
  loading: boolean

}

interface ConnectedProps {

}

interface DispatchProps {

}

type Props = ConnectedProps & DispatchProps & CreateComment & WrappedComponentProps


class AreaSearchModal extends Component<Props, CreateCommentModalState> {
  public stepInput: React.RefObject<HTMLInputElement>;
  constructor(props:Props){
    super(props);
    this.stepInput = React.createRef();
    this.state = {
      areas: [],
      loading: false
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<CreateCommentModalState>, snapshot?: any): void {
    if(this.props.show !== prevProps.show){
      this.stepInput.current?.focus();
      this.stepInput.current?.select()
    }
  }

  timer:any = 0;

  processs = (search: string) => {
    const rc = this;
    this.setState({loading:true})
    if(this.timer) clearTimeout(this.timer);
    this.timer = setTimeout(function () {

      axios.request({
          baseURL: process.env.REACT_APP_TPP_API,
          method: "GET",
          url: `/areas?search=${search}`
        }).then(res=>{
          rc.setState({areas:res.data.results, loading:false})
          
        })
      }, 1500)
  }



  render() {
    
    return (
      <Modal
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog-centered mw-1000px h-auto'
        show={this.props.show}
        onHide={this.props.hideModal}
      >

        <div className='container-xxl px-10 py-10 modal-header'>

          <div className=' py-2 d-flex'>
            <h3 className='fw-bolder text-dark display-6'>{this.props.intl.formatMessage({id: 'AREA.MANAGEMENT.SEARCH'})}</h3>
          </div>
          <div className='py-2 d-flex justify-content-end border-0'>
            
            {/* begin::Close */}
            <div className='btn btn-icon btn-sm btn-light-primary' onClick={this.props.hideModal}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </div>
            {/* end::Close */}
        </div>

  
          <div className='modal-body'>

            {/*begin::Stepper */}
            <div
              className='d-flex flex-column flex-xl-row flex-row-fluid'
            >
              {/*begin::Content */}
              <div className='d-flex flex-row-fluid justify-content-center'>
                <input
                  type='text'
                  className='form-control form-control-sm form-control-solid'
                  placeholder={this.props.intl.formatMessage({id: 'AREA.MANAGEMENT.SEARCHPLACEHOLDER'})}
                  ref={this.stepInput}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>)=>this.processs(event.target.value)}
                  name='query'
                />
              </div>
            </div>

              <div className='scroll-y mh-200px mh-lg-350px'>

                <h3
                  className='fs-5 text-muted m-0 pt-5 pb-5'
                >
                  {this.props.intl.formatMessage({id: 'AREA.MANAGEMENT.AREAS'})}
                </h3>

                {(!this.state.areas.length && !this.state.loading)&&(
                  <span className='fs-6 fw-bold'>{this.props.intl.formatMessage({id: 'SEARCH.NO_AREAS'})}</span>
                )}
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  <thead>
                    <th className='min-w-50px'></th>
                    <th className='min-w-80px'></th>
                    <th className='min-w-80px'></th>
                    <th className='min-w-80px'></th>
                    <th className='min-w-80px'></th>
                    <th className='min-w-50px'></th>
                  </thead>
                  <tbody>       
                  {this.state.loading && (
                    <SkeletonRow rows={5} columns={6} height={50}/>
                  )}
                  {!this.state.loading && this.state.areas.map((area: AreaModel, index: number) => (
                    <tr key={index}>
                      <td>
                        <div className='symbol symbol-40px me-4'>
                          <span className='symbol symbol-50px w-50px bg-light'>
                          {area.image ? (
                            <img src={toAbsoluteUrl(area.image, true)} alt='' style={{objectFit: "cover"}}/>
                          ):<FontAwesomeIcon icon={faTrees}/>}
                          </span>
                        </div>
                      </td>
                      <td>
                        <Link
                          onClick={this.props.hideModal}
                          key={index}
                          to={`/area/${area.id}`}
                          className='d-flex text-dark text-hover-primary align-items-center mb-5'>
                          <div className='d-flex flex-column justify-content-start fw-bold'>
                            <span className='fs-6 fw-bold'>{area.name}</span>
                          </div>
                        </Link>
                      </td>
                      <td>
                        <div className='d-flex flex-column w-100 me-2'>
                          <span className={`badge badge-light-${AREA_STATUS_BADGE[area.status]} fs-8 fw-bolder my-2`}>{getWorkflowStatus(area.workflow_status) || this.props.intl.formatMessage({id: 'AREA.MANAGEMENT.TABLE.NO_WORKFLOW'})}</span>
                        </div>
                      </td>
                      {/* <td>{area.locations.map((location:AreaLocationSet)=><p className='me-2'>{location.location_mark}-{location.location_number}</p>)}</td> */}
                      <td>
                        {area.forest_farmers.length ? (
                          <>
                            <FontAwesomeIcon icon={faUserHelmetSafety} />
                            {area.forest_farmers.map(farmer=>(
                              <p>{farmer.fullname}</p>
                            ))}
                          </>
                        ):""}
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faPerson} />
                        <AreaOwnerInfo area={area.id} />
                      </td>
                      
                      <td>
                      <span className='text-muted fw-bold text-muted d-block fs-7'>
                      { timeSince(area.created_at)}
                      </span>
                  </td>
                    </tr>
                  ))}
                 </tbody>
                </table>


              </div>
              {/*end::Content */}
            {/* end::Stepper */}
          </div>
          <div>
  
          </div>
      </Modal>
    )
  }
}


const mapStateToProps = (state: RootState) => {
  return {
    selectedPartner: state.areas.selectedPartner
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {

  };
};

export default connect<ConnectedProps, DispatchProps>(mapStateToProps, mapDispatchToProps)(injectIntl(AreaSearchModal));

type AreaOwnerInfoProps = {
  area: string
}

const AreaOwnerInfo: React.FC<AreaOwnerInfoProps> = ({area}) => {
  const [owner, setOwner] = useState<AreaOwnerDetails>()
  useEffect(() => {
    axios.get(API_URL+`/area/${area}/owner`).then(res=>setOwner(res.data))
  }, [setOwner])
  return (<p>{owner?.name||""}</p>)
}
