/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { faSave, faTimes, } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { useIntl } from 'react-intl'
import { AreaJobTemplate, AreaWorkflowStageTemplate, Workflow } from '../models/AreaModel'
import { createTemplateStage, createTemplateStep } from '../redux/AreaRedux'

type Props = {
  show: boolean
  handleClose: () => void
  loading: boolean
  stages: AreaWorkflowStageTemplate[]
  onSubmit: typeof createTemplateStage
  workflow: string
  jobTemplates: AreaJobTemplate[]
  workflows: Workflow[]
  onSubmit2: typeof createTemplateStep
}

const WorkflowCreateItemModal: React.FC<Props> = ({ show, handleClose, loading, stages, workflow, onSubmit, jobTemplates, workflows, onSubmit2 }) => {

  const [stageName, setStageName] = useState("");
  const [_type, _setType] = useState('stage');
  const [stepName, setStepName] = useState("");
  const [selectedStage, setSelectStage] = useState(stages[0] && stages[0].id);
  const [selectedStepType, _setSelectedStepType] = useState(1);
  const [selectedStepTypeSource, _setSelectedStepTypeSource] = useState("");

  const intl = useIntl()


  useEffect(() => {
    if (show === false) {
      setStageName("");
      _setType("stage");
      setStepName("");
      _setSelectedStepType(1);
      _setSelectedStepTypeSource("");
    }
    if (selectedStepType === 3 && !selectedStepTypeSource) {
      _setSelectedStepTypeSource(jobTemplates[0].id)
    }
  });

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    setStageName(e.currentTarget.value)
  }

  const isValidSource = (source: string) => {
    if (selectedStepType < 3) {
      const re = new RegExp('[a-zA-Z]+[.]{1}[a-zA-Z]+');
      return re.test(source)
    } else {
      return Boolean(selectedStepTypeSource)
    }
  }

  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-1000px h-auto'
      show={show}
      onHide={handleClose}
    >
      <div className='container-xxl px-10 py-10'>
        <div className='modal-header py-2 d-flex justify-content-end border-0'>
          {/* begin::Close */}
          <div className='btn btn-icon btn-sm btn-light-primary' onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          {/* end::Close */}
        </div>

        <div className='modal-body'>
          {/*begin::Stepper */}
          <div
            className='d-flex flex-column flex-xl-row flex-row-fluid'
          >
            {/*begin::Aside */}
            <div className='d-flex justify-content-center justify-content-xl-start flex-row-auto '>

            </div>
            {/*begin::Aside */}

            {/*begin::Content */}
            <div className='d-flex flex-row-fluid justify-content-center'>
              {/*begin::Form */}
              <form
                className='pb-5 w-100 w-md-400px w-xl-500px'
              >
                {/*begin::Step 1 */}
                <div className='pb-5 current' data-kt-stepper-element='content'>
                  <div className='w-100'>
                    {/*begin::Heading */}
                    <div className='pb-5 pb-lg-10'>
                      <h3 className='fw-bolder text-dark display-6'>{intl.formatMessage({ id: 'AREA.MANAGEMENT.WORKFLOW.CREATE_ITEM_TEMPLATE' })}</h3>
                    </div>
                    {/*begin::Heading */}

                    {/*begin::Form Group */}
                    <div className='row mb-5'>
                      <div className='col-md-6'>
                        <div className='fv-row'>
                          <label className='fs-6 fw-bolder text-dark form-label'>{intl.formatMessage({ id: 'AREA.MANAGEMENT.WORKFLOW.CREATE_ITEM_TEMPLATE.TYPE' })}</label>
                          <div className=''>
                            <select
                              className='form-select form-select-solid'
                              onChange={(e: any) => _setType(e.target.value)}
                            >
                              <option value='stage'>{intl.formatMessage({ id: 'AREA.MANAGEMENT.WORKFLOW.STAGE' })}</option>
                              <option value='step'>{intl.formatMessage({ id: 'AREA.MANAGEMENT.WORKFLOW.STEP' })}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      {_type === "step" && (
                        <div className='col-md-6'>
                          <div className='fv-row'>
                            <label className='fs-6 fw-bolder text-dark form-label'>{intl.formatMessage({ id: 'AREA.MANAGEMENT.WORKFLOW.STAGE' })}</label>
                            <div className=''>
                              <select
                                className='form-select form-select-solid'
                                onChange={(e: any) => setSelectStage(e.target.value)}
                              >
                                {stages.map((stage: AreaWorkflowStageTemplate) => (
                                  <option key={stage.id} value={stage.id}>{stage.stage_name || "Unknown"}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    {_type === "stage" && (
                      <div className='fv-row mb-12'>
                        <label className='fs-6 fw-bolder text-dark form-label'>{intl.formatMessage({ id: 'AREA.MANAGEMENT.WORKFLOW.CREATE_ITEM_TEMPLATE.STAGE_NAME' })}</label>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          placeholder=''
                          value={stageName}
                          onChange={handleChange}
                        />
                      </div>

                    )}
                    {_type === "step" && (
                      <>
                        <div className='row mb-5'>
                          <div className='col-md-12'>
                            <div className='fv-row'>
                              <label className='fs-6 fw-bolder text-dark form-label'>{intl.formatMessage({ id: 'AREA.MANAGEMENT.WORKFLOW.CREATE_ITEM_TEMPLATE.STEP_NAME' })}</label>
                              <input
                                type='text'
                                className='form-control form-control-lg form-control-solid'
                                placeholder=''
                                value={stepName}
                                onChange={(e: any) => setStepName(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-md-6'>
                            <div className='fv-row mb-12'>
                              <label className='fs-6 fw-bolder text-dark form-label'>{intl.formatMessage({ id: 'AREA.MANAGEMENT.WORKFLOW.CREATE_ITEM_TEMPLATE.STEP_TYPE' })}</label>
                              <select
                                className='form-select form-select-solid'
                                onChange={(e: any) => _setSelectedStepType(parseInt(e.target.value))}
                              >
                                <option value='1'>{intl.formatMessage({ id: 'AREA.MANAGEMENT.WORKFLOW.CREATE_ITEM_TEMPLATE.STEP_DATA' })}</option>
                                <option value='2'>{intl.formatMessage({ id: 'AREA.MANAGEMENT.WORKFLOW.CREATE_ITEM_TEMPLATE.STEP_DOCUMENT' })}</option>
                                <option value='3'>{intl.formatMessage({ id: 'AREA.MANAGEMENT.WORKFLOW.CREATE_ITEM_TEMPLATE.STEP_JOB' })}</option>
                                <option value='4'>{intl.formatMessage({ id: 'AREA.MANAGEMENT.WORKFLOW.CREATE_ITEM_TEMPLATE.STEP_PLOT' })}</option>
                                <option value='5'>{intl.formatMessage({ id: 'AREA.MANAGEMENT.WORKFLOW.CREATE_ITEM_TEMPLATE.STEP_TODO' })}</option>
                              </select>
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <div className='fv-row'>
                              {selectedStepType === 1 || selectedStepType === 2 ? (
                                <>
                                  <label className='fs-6 fw-bolder text-dark form-label'>{intl.formatMessage({ id: 'AREA.MANAGEMENT.WORKFLOW.CREATE_ITEM_TEMPLATE.STEP_SOURCE' })}</label>

                                  <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder={selectedStepType === 1 ? "area.size" : "pachtvertrag.pdf"}
                                    value={selectedStepTypeSource}
                                    onChange={(e: any) => _setSelectedStepTypeSource(e.target.value)}
                                  />
                                </>
                              ) : (
                                selectedStepType === 3 ? (
                                  <>
                                    <label className='fs-6 fw-bolder text-dark form-label'>{intl.formatMessage({ id: 'AREA.MANAGEMENT.WORKFLOW.CREATE_ITEM_TEMPLATE.STEP_SOURCE' })}</label>

                                    <select
                                      className='form-select form-select-solid'
                                      onChange={(e: any) => _setSelectedStepTypeSource(e.target.value)}
                                    >
                                     <option value=""></option>
                                      {jobTemplates.map((job: AreaJobTemplate) => (
                                        <option key={job.id} value={job.id}>{job.name}</option>
                                      ))}
                                    </select>
                                  </>
                                ) : (
                                  selectedStepType === 5 ? (
                                    <>
                                      <label className='fs-6 fw-bolder text-dark form-label'>{intl.formatMessage({ id: 'AREA.MANAGEMENT.WORKFLOW.CREATE_ITEM_TEMPLATE.STEP_SOURCE' })}</label>

                                      <input
                                        type='text'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='title'
                                        value={(selectedStepTypeSource as any)?.title}
                                        onChange={(e: any) => _setSelectedStepTypeSource({ ...(selectedStepTypeSource as any), title: e.target.value })}
                                      />
                                      <input
                                        type='text'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='description'
                                        value={(selectedStepTypeSource as any)?.description}
                                        onChange={(e: any) => _setSelectedStepTypeSource({ ...(selectedStepTypeSource as any), description: e.target.value })}
                                      />
                                      <input
                                        type='text'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='ref'
                                        value={(selectedStepTypeSource as any)?.ref}
                                        onChange={(e: any) => _setSelectedStepTypeSource({ ...(selectedStepTypeSource as any), ref: e.target.value })}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <label className='fs-6 fw-bolder text-dark form-label'>{intl.formatMessage({ id: 'AREA.MANAGEMENT.WORKFLOW.CREATE_ITEM_TEMPLATE.PLOT_DEFAULTS' })}</label>
                                      <select
                                        className='form-select form-select-solid'
                                        onChange={(e: any) => 
                                          _setSelectedStepTypeSource(e.target.value)
                                        }
                                      >
                                        <option value=""></option>

                                        {workflows
                                          .filter(_workflow => _workflow.id !== workflow)
                                          .map((workflow: Workflow) => (
                                            <option key={workflow.id} value={workflow.id}>{workflow.name}</option>
                                          ))}
                                          
                                      </select>
                                    </>
                                  )
                                )
                              )}

                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {/*end::Form Group */}

                  </div>
                </div>
                {/*end::Step 1 */}

                {/*begin::Actions */}
                <div className='d-flex justify-content-between pt-10'>
                  <div className='mr-2'>

                  </div>
                  <div>
                    <button
                      type='button'
                      className='btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3'
                      disabled={_type === 'stage' ? !stageName : (!stepName || (!isValidSource(selectedStepTypeSource)))}
                      onClick={_type === "stage" ? (() => onSubmit(workflow, stageName)) : (() => onSubmit2(selectedStage, stepName, selectedStepType, selectedStepTypeSource, workflow))}

                    >
                      {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          {intl.formatMessage({ id: 'GENERAL.LOADING' })}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                      {!loading && (
                        <>
                          {intl.formatMessage({ id: 'GENERAL.SAVE' })}{' '}
                          <FontAwesomeIcon
                            icon={faSave}
                            className='svg-icon-3 ms-1'
                          />
                        </>
                      )}</button>

                  </div>
                </div>
                {/*end::Actions */}
              </form>
              {/*end::Form */}
            </div>
            {/*end::Content */}
          </div>
          {/* end::Stepper */}
        </div>
        <div>

        </div>
      </div>
    </Modal>
  )
}

export { WorkflowCreateItemModal }
