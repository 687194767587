/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { faSave, faTimes } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, {useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import { useIntl } from 'react-intl'
import { Workflow } from '../models/AreaModel'
import { createAreaWorkflow } from '../redux/AreaRedux'

type Props = {
  show: boolean
  handleClose: () => void
  loading: boolean
  createWorkflow: typeof createAreaWorkflow
  workflows: Workflow[]
}

const WorkflowCreateModal: React.FC<Props> = ({show, handleClose, loading, createWorkflow, workflows}) => {

  const [ name, setName ] = useState("");

  const intl = useIntl()

  const handleChange = (e:React.FormEvent<HTMLInputElement>) => {
    setName(e.currentTarget.value)
  }

  const newWorkflowCreatedOrExists = () => {
    
    let x = workflows.filter(workflow=>(workflow.name===name))[0];
    if(x) {
      return true;
    } else {
      return false;
    }
  }

  
  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-1000px h-auto'
      show={show}
      onHide={handleClose}
    >
      <div className='container-xxl px-10 py-10'>
        <div className='modal-header py-2 d-flex justify-content-end border-0'>
          {/* begin::Close */}
          <div className='btn btn-icon btn-sm btn-light-primary' onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          {/* end::Close */}
        </div>

        <div className='modal-body'>
          {/*begin::Stepper */}
          <div
            className='d-flex flex-column flex-xl-row flex-row-fluid'
          >
            {/*begin::Aside */}
            <div className='d-flex justify-content-center justify-content-xl-start flex-row-auto '>
              
            </div>
            {/*begin::Aside */}

            {/*begin::Content */}
            <div className='d-flex flex-row-fluid justify-content-center'>
              {/*begin::Form */}
              <form
                className='pb-5 w-100 w-md-400px w-xl-500px'
              >
                {/*begin::Step 1 */}
                <div className='pb-5 current' data-kt-stepper-element='content'>
                  <div className='w-100'>
                    {/*begin::Heading */}
                    <div className='pb-5 pb-lg-10'>
                      <h3 className='fw-bolder text-dark display-6'>{intl.formatMessage({id: 'AREA.MANAGEMENT.WORKFLOW.CREATE_TEMPLATE'})}</h3>
                    </div>
                    {/*begin::Heading */}

                    {/*begin::Form Group */}
                    <div className='fv-row mb-12'>
                      <label className='fs-6 fw-bolder text-dark form-label'>{intl.formatMessage({id: 'AREA.MANAGEMENT.WORKFLOW.CREATE_TEMPLATE.NAME'})}</label>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder=''
                        value={name}
                        onChange={handleChange}
                      />
                      {newWorkflowCreatedOrExists() === true && <p>{intl.formatMessage({id: 'AREA.MANAGEMENT.WORKFLOW.CREATE_TEMPLATE.WARNING'})}</p>}
                    </div>
                    {/*end::Form Group */}

                  </div>
                </div>
                {/*end::Step 1 */}

                {/*begin::Actions */}
                <div className='d-flex justify-content-between pt-10'>
                  <div className='mr-2'>
                
                  </div>
                  <div>
                    <button
                      type='button'
                      className='btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3'
                      onClick={()=>createWorkflow(name)}
                      disabled={!name}

                    >
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        {intl.formatMessage({id: 'GENERAL.LOADING'})}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                      {!loading && (
                      <>
                      {intl.formatMessage({id: 'GENERAL.SAVE'})}{' '}
                      <FontAwesomeIcon
                        icon={faSave}
                        className='svg-icon-3 ms-1'
                        
                      />
                      </>
                      )}</button>
                  
                  </div>
                </div>
                {/*end::Actions */}
              </form>
              {/*end::Form */}
            </div>
            {/*end::Content */}
          </div>
          {/* end::Stepper */}
        </div>
        <div>

        </div>
      </div>
    </Modal>
  )
}

export {WorkflowCreateModal}
