import { faSquareQuestion } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import {useLocation} from 'react-router-dom'
import { ProfileModel } from '../../../app/modules/auth/models/ProfileModel'
import { RootState } from '../../../setup'
import { version } from '../../../../package.json';

export const Jira: FC = () => {
  const {pathname} = useLocation()

  var jiraUrl = "https://treeplantingprojects.atlassian.net/servicedesk/customer/portal/3/group/8/create/31?description=Fehlermeldung:%0A%0A%0A%0A%0A------Nicht%20entfernen------%0AURL:%20{{URL}}%0AVersion:%20{{VERSION}}%0AUser:%20{{USERID}}%0ARolle:%20{{GROUP}}%0APartner:%20{{PARTNER}}%0ABrowser:%20{{BROWSER}}"
  const user: ProfileModel = useSelector<RootState>(({auth}) => auth.profile, shallowEqual) as ProfileModel
  const profile: any = useSelector<RootState>(({auth}) => auth.profile)
  const selectedPartner: any = useSelector<RootState>(({areas}) => areas.selectedPartner)


  const handleJira = () => {  
    jiraUrl = jiraUrl.replace('{{USERID}}', user.id.toString())
    jiraUrl = jiraUrl.replace('{{VERSION}}', version)
    jiraUrl = jiraUrl.replace('{{URL}}', pathname)
    jiraUrl = jiraUrl.replace('{{BROWSER}}', navigator.userAgent)
    jiraUrl = jiraUrl.replace('{{GROUP}}', profile.role)
    jiraUrl = jiraUrl.replace('{{PARTNER}}', selectedPartner)

    window.open(jiraUrl, '_blank')?.focus();
  }
  
  return (
    <div className='jira' onClick={handleJira}>
      <FontAwesomeIcon icon={faSquareQuestion} size={'3x'} className='svg-icon-jira' />
    </div>
  )
}
