/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useIntl } from 'react-intl'
import { AreaTodo } from '../../models/AreaModel'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { Link } from 'react-router-dom'

type Props = {
  className: string
  todos: AreaTodo[]
}

const AreasTodos: React.FC<Props> = ({className, todos}) => {
  const intl = useIntl();

  const TodoStatus = [
    intl.formatMessage({id: "AREA.TODO.STATUS.OPEN"}),
    intl.formatMessage({id: "AREA.TODO.STATUS.IN_PROGRESS"})
  ]


  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>{intl.formatMessage({id: "AREAS.TODOS.TITLE"})}</span>

          <span className='text-muted fw-bold fs-7'>{todos.length} {intl.formatMessage({id: "AREAS.TODOS.TITLE"})}</span>
        </h3>


      </div>
      <div className='card-body pt-3'>
        {todos.map(todo=>(
          <div className='d-flex align-items-sm-center mb-7'>
            <div className='symbol symbol-60px symbol-2by3 me-4'>
            <div
              className='symbol-label'
              style={{backgroundImage: `url(${toAbsoluteUrl(todo.ref_details.image, true)})`}}
            ></div>
          </div>
            <div className='d-flex flex-row-fluid flex-wrap align-items-center'>
              <div className='flex-grow-1 me-2'>
                <Link to={`area/${todo.ref_details.area}`} className='text-gray-800 fw-bolder text-hover-primary fs-6'>
                {todo.title}
                </Link>
                <span className='text-muted fw-bold d-block pt-1'>{todo.ref_details.name}</span>
              </div>
              <span className='text-muted fw-bold d-block pt-1 me-10'>{getDaysToEnd(todo.deadline)} {intl.formatMessage({id: "AREAS.TODOS.DAYS_REMAINING"})}</span>
              <span className={`badge badge-light-${todo.status === 1 ? 'info':'warning'} fs-8 fw-bolder my-2`}>{TodoStatus[todo.status-1]}</span>
            </div>
          </div>
        ))}
        {!todos.length && (
          <span className='text-muted fw-bold d-block pt-1'>{intl.formatMessage({id: "AREAS.TODOS.NO_TODOS"})}</span>
        )}
        
      </div>
    </div>
  )
}

export {AreasTodos}

export const getDaysToEnd = (date:string) => {
  const due_date = new Date(date).valueOf();
  const today = new Date().valueOf();
  const diffTime = due_date - today;
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
}