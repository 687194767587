/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { AreaManager, AreaModel, AreasFilter, Partner, TableFilters } from '../../../../app/modules/areas/models/AreaModel'
import { AREA_MAP_PROPS, deleteArea, fetchAreas } from '../../../../app/modules/areas/redux/AreaRedux'
import {API_URL, KTSVG, toAbsoluteUrl} from '../../../helpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faCube, faTrash } from '@fortawesome/pro-duotone-svg-icons'
import { ModalModel } from '../../../../app/modules/modals/models/ModalModel'
import { getModal, _hideModal, _showModal } from '../../../../app/modules/modals/redux/ModalRedux'
import { ConfirmDropdown } from '../../content/dropdown/ConfirmDropdown'
import HasPerm from '../../../../app/modules/auth/redux/HasPerm'
import { Filter } from '../../../../app/modules/areas/components/list/Filter'
import { timeSince } from '../../../../app/modules/areas/components/documents/AreaDocuments'
import GoogleMapReact from 'google-map-react';

import { MapInnerCard } from '../../../../app/modules/areas/components/list/MapInnerCard'
import { getWorkflowStatus } from '../../../../app/pages/dashboard/DashboardWrapper'
import axios from 'axios'
import { AREA_STATUS_BADGE } from '../../../../app/modules/areas/AreaHeader'
import { SkeletonRow } from '../../../../app/modules/areas/components/SkeletonRow'

export const AREA_LIMIT_ALL=2000
export const AREA_LIMIT_MIN=25

const colors:string[]= [
  '#800000',
  '#9A6324',
  '#469990',
  '#000075',
  '#e6194B',
  '#f58231',
  '#bfef45',
  '#3cb44b',
  '#42d4f4',
  '#f032e6',
];
let i = 0;
while (i < 50) {
  colors.push("#"+Math.floor(Math.random()*16777215).toString(16));
  i++;
}


type Props = {
  className: string
  areas: AreaModel[]
  fetchAreas: typeof fetchAreas
  loading: boolean
  deleteArea: typeof deleteArea
  deleteAreaLoading: boolean
  modals: ModalModel[]
  showModal: typeof _showModal
  hideModal: typeof _hideModal
  count: number
  page: number
  limit: number
  filter: boolean
  filters: AreasFilter
  filtersLoading: boolean
  filterStatus: string
  map_view: boolean
  mapProps: any
  areaDetails: AreaModel[]
  partner: string
  partners: Partner[]
  filterHistory: string[]
  tableFilters: TableFilters
}

const  AreaTable: React.FC<Props> = ({
  className, areas, fetchAreas, loading, deleteArea, deleteAreaLoading,
  modals, showModal, hideModal, count, page, limit, filter, filters, filtersLoading,
  filterStatus, map_view, mapProps, areaDetails, partner, partners, filterHistory, tableFilters }) => {

  const intl = useIntl();
  const [checked, setChecked] = useState(filter);
  const [_search, setSearch] = useState('');
  const [mapDetail, setMapDetail] = useState('');
  const[_mapProps, set_mapProps] = useState(mapProps);
  const [projects, setProjects] = useState<any[]>([]);
  const [managers, setManagers] = useState<any[]>([]);
  const [showProjects, setShowProjects] = useState(false);
  const [showManagers, setShowManagers] = useState(false);
  const [maps, setMaps] = useState<any>();
  const [map, setMap] = useState<any>();
  const [projectCircles, setprojectCircles] = useState<any[]>([]);
  const [managerCircles, setManagerCircles] = useState<any[]>([]);
  const [showStatus, setShowStatus] = useState<any>({open:true,inProgress:true,done:false,declined:false});


  const setMapDetails:any = useCallback((id:string, lat:number, lng:number) => {
    setMapDetail(id);
    const mapPropsChange = {..._mapProps};
    mapPropsChange.center = {lat,lng};
    fetchAreas(page, AREA_LIMIT_ALL, filter, _search, filterStatus, mapPropsChange, [id]);
    set_mapProps(mapPropsChange);
    }, [_mapProps, _search, fetchAreas, filter, filterStatus, page]
    )

  useEffect(()=>{
    // if(mapProps === AREA_MAP_PROPS && mapDetail) {
    //   console.log("CALLL", mapProps, AREA_MAP_PROPS)
    //   const m_props = {...mapProps, map_view}
    //   if(mapProps !== m_props){
    //     console.log("CALLL ACTION")

    //     set_mapProps(m_props);

    //   }
    // }
    if(mapProps === AREA_MAP_PROPS && !mapDetail && _mapProps.zoom !== 10) {
      set_mapProps({..._mapProps, zoom: 10});
    }
    if(areas.length === 1 && !mapDetail) {

      setMapDetails(areas[0].id, areas[0].lat, areas[0].lng)
    }
    if(areas.length > 1 && !mapDetail && _mapProps.zoom !== 10) {

      // set_mapProps({..._mapProps, zoom: 10})
    }
    if(filter !== checked){
      setChecked(filter)
    }

    if(_mapProps.map_view !== map_view){
      set_mapProps(AREA_MAP_PROPS);
    }
  }, [mapProps, mapDetail, map_view, areas, _mapProps, set_mapProps, setMapDetails, filter, setChecked, checked, projects, setProjects, managers, setManagers])

  const handleManagerShow = (manager:number, target:string="")=>{
    if (target==="show") {
      managerCircles[manager].setMap(map)
      let circles = [...managerCircles]
      circles[manager].setMap(map)
      setManagerCircles(circles)
    }
    if (target==="hide") {
      managerCircles[manager].setMap(null)
      let circles = [...managerCircles]
      circles[manager].setMap(null)
      setManagerCircles(circles)
    }
    if(managerCircles[manager].map){
      managerCircles[manager].setMap(null)
      let circles = [...managerCircles]
      circles[manager].setMap(null)
      setManagerCircles(circles)
    }
    else{
      managerCircles[manager].setMap(map)
      let circles = [...managerCircles]
      circles[manager].setMap(map)
      setManagerCircles(circles)

    }
  }
  const handleShowProjects = ()=>{
    if(!showProjects){
      setShowProjects(true);
      axios.get(API_URL+"/projects?limit=150").then(res=>{
        setProjects(res.data["results"])
        let circles: any[] = []
        res.data["results"].map((project: { lat: any; lng: any })=>{
          const circle = new maps.Circle({
            strokeColor: "#4EBC97",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#4EBC97",
            fillOpacity: 0.00,
            map,
            center: {lat: project.lat, lng: project.lng},
            radius: 25*1000,
          })
          circles.push(circle)
          return circle
      })
      setprojectCircles(circles);
    })
    } else {
      setShowProjects(false);
      projectCircles.map(circle=>(
        circle.setMap(null)
      ))
      setprojectCircles([])
    }
  }

  const handleShowManagers = ()=>{
    if(!showManagers){
      setShowManagers(true);
      axios.get(API_URL+"/areas/managers").then(res=>{
        setManagers(res.data)
        let circles: any[] = []
        res.data.forEach((manager: any,key: any) => {
          const circle = new maps.Circle({
            strokeColor: colors[key],
            strokeOpacity: 0.8,
            strokeWeight: 3,
            // fillColor: MAP_ITEM_COLORS[key],
            fillOpacity: 0.00,
            map,
            center: {lat: manager.lat, lng: manager.lng},
            radius: 25*1000,
          })
          circles.push(circle)
          return circle
      })
      setManagerCircles(circles);
    })
    } else {
      setShowManagers(false);
      managerCircles.map(circle=>(
        circle.setMap(null)
      ))
      setManagerCircles([])
    }
  }

  const getAreaManagerColor = (area:string)=>{
    let color = ""
    managers.map((manager:any,key:number)=>{
      if(manager.areas.includes(area)){
        color = colors[key]
      }
      return color
    })
    return color
  }
  const filterAreasChange = (e:any) => {
    setMapDetail('')
    fetchAreas(0, limit, !filter, _search, filterStatus, _mapProps, undefined, undefined, undefined, tableFilters)
    setChecked(!checked)
  }

  let timer:any = 0;

  const searchAreaChange = (search:string) => {
    if(timer) clearTimeout(timer);
    timer = setTimeout(()=>{
      setSearch(search)
      setMapDetail('')
      set_mapProps({...mapProps, map_view, zoom: 10});
      fetchAreas(0, limit, filter, search, filterStatus, _mapProps, undefined, undefined, undefined, tableFilters)
    }, 500)
  }

  const areaMapChange = (e: any) => {
    const mapPropsChange = {..._mapProps};
    mapPropsChange.zoom = e.zoom;
    set_mapProps(mapPropsChange);

    // if(timer) clearTimeout(timer);
    // timer = setTimeout(()=>{
    //   const mapPropsChange = {..._mapProps};
    //   // const {lat, lng} = e.center;
    //   // mapPropsChange.center = {lat,lng};
    //   mapPropsChange.zoom = e.zoom;
    //   // mapPropsChange.map_view = true;
    //   // if(
    //   //   lat === _mapProps.center.lat
    //   //   && lng === _mapProps.center.lng
    //   //   && _mapProps.zoom === e.zoom
    //   // ) return
    //   set_mapProps(mapPropsChange);
    //   // if(lat === _mapProps.center.lat
    //   //   && lng === _mapProps.center.lng
    //   //   && _mapProps.zoom !== e.zoom) setMapDetail('')
    //   // fetchAreas(page, AREA_LIMIT_ALL, filter, _search, filterStatus, mapPropsChange);
    // }, 500)
  }

  

  const MapMarker:any = ({id, lat, lng}:any) => (
    <div    
    >
      {/* <FontAwesomeIcon icon={faCheck} size="2x" /> */}
        <img 
          onClick={()=>setMapDetails(id, lat, lng)}
          src={toAbsoluteUrl('/media/logos/tpp_logo.png')}
          style={getAreaManagerColor(id)?{border: `3px solid ${getAreaManagerColor(id)}`, borderRadius: "25px"}:{}}
          width="25"
          height="25"
          alt="" />
         
    </div>
    );

  const ProjectMarker:any = ({key, lat, lng, image, name, zoom}:any) => (
    <div className='d-flex'>
        <FontAwesomeIcon icon={faCube} size="2x"/>
        {zoom >= 9 && (
          <p className='fs-6 font-weight-bold me-2' >{name}</p>
        )}
    </div>
  )

  // const ManagerMarker:any = ({key, lat, lng, image, name, zoom}:any) => (
  //   <div className='d-flex'>
  //       <img 
  //         src={toAbsoluteUrl(image, true)}
  //         alt=""
  //         width={25} height={25}/>
  //        {zoom >= 9 && (
  //           <p className='fs-6 font-weight-bold me-2' >{name}</p>
  //         )}
  //   </div>
  // )
  const selectedArea = areas.filter(area=>String(area.id) === String(mapDetail))[0];
  const _areaDetails = (id:string) => (areaDetails.filter(area=>String(area.id) === String(id))[0])
  const getAreasWithStatus = () => {
    let _areas = areas
    if(map_view){
      if(!showStatus.open){
        _areas = _areas.filter(area=>area.status!=="OPEN")
      }
      if(!showStatus.inProgress){
        _areas = _areas.filter(area=>area.status!=="IN_PROGRESS")
      }
      if(!showStatus.done){
        _areas = _areas.filter(area=>area.status!=="DONE")
      }
      if(!showStatus.declined){
        _areas = _areas.filter(area=>area.status!=="DECLINED")
      }
    }
    return _areas
  }
  return (
    
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>

          <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>{intl.formatMessage({id: 'AREA.MANAGEMENT.TITLE'})}</span>
          <span className='text-muted mt-1 fw-bold fs-7'>
            {loading ? (
              <span className='indicator-progress' style={{display: 'block'}}>
                <FormattedMessage id='GENERAL.LOADING' />
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            ):
            (
              <>{map_view?getAreasWithStatus().length:count} {intl.formatMessage({id: 'AREA.MANAGEMENT.AREAS'})}</>
            )}
          </span>
          {!loading && (filterHistory && filterHistory.slice(-1)[0] === filterStatus ? filterHistory[filterHistory.length-2]:filterHistory.slice(-1)[0]) && (
            <>
              <span className='text-muted mt-1 fw-bold fs-7'>
                Letzter Filter:
              <span className='text-muted mt-1 fw-bold fs-7 cursor-pointer' 
                onClick={()=>fetchAreas(0, limit, filter, _search, filterHistory.slice(-1)[0] === filterStatus ? filterHistory[filterHistory.length-2]:filterHistory.slice(-1)[0], _mapProps)}>
                {getWorkflowStatus(filterHistory.slice(-1)[0] === filterStatus ? filterHistory[filterHistory.length-2]:filterHistory.slice(-1)[0])}
              </span>
              </span>
            </>
          )}
          
        </h3>
        {map_view && (
        <HasPerm permission='projects.project.view'>
          <div className='row'>
            <div className='col-md-6'>
              <label className='col-form-label fw-bold fs-6'>{intl.formatMessage({id: `AREA.MANAGEMENT.TABLE.MAPS.SHOW_PROJECTS`})}</label>
                <div className='form-check form-check-solid form-switch fv-row'>
                  <input
                    className='form-check-input w-45px h-30px'
                    type='checkbox'
                    onChange={handleShowProjects}
                    checked={showProjects}
                  />
                </div>
            </div>
            <div className='col-md-6'>
              <label className='col-form-label fw-bold fs-6'>{intl.formatMessage({id: `AREA.MANAGEMENT.TABLE.MAPS.SHOW_MANAGERS`})}</label>
                <div className='form-check form-check-solid form-switch fv-row'>
                  <input
                    className='form-check-input w-45px h-30px'
                    type='checkbox'
                    onChange={handleShowManagers}
                    checked={showManagers}
                  />
                </div>
            </div>
          </div>
        </HasPerm>
        )}
          <div className='row'>
            <div className='col-4'>
              <label className='col-form-label fw-bold fs-6'>{intl.formatMessage({id: 'AREA.MANAGEMENT.SEARCH'})}</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder={intl.formatMessage({id: 'AREA.MANAGEMENT.SEARCHPLACEHOLDER'})}
                  onChange={(e:any)=>searchAreaChange(e.target.value)}
                />
              </div>
              <div className='col-3'>
                <label className='col-form-label fw-bold fs-6'>Filter</label>
                <Filter 
                  filters={filters} 
                  filtersLoading={filtersLoading}
                  filterWorkflow={''}
                  fetchAreas={(_status:string, tableFilters:TableFilters)=>{
                    setMapDetail('')
                    fetchAreas(0, limit, filter, _search, _status, _mapProps, [], undefined, undefined, tableFilters)
                    }}
                  map_view={map_view}
                  setShow={setShowStatus}
                  showStatus={showStatus}
                  filterStatus={filterStatus}
                  tableFilters={tableFilters}
                  />
              </div>
              <div className='col-3'>
                <label className='col-form-label fw-bold fs-6'>{intl.formatMessage({id: 'AREA.MANAGEMENT.LIMIT'})}</label>
                <select
                  className='form-select form-select-solid'
                  disabled={map_view}
                  defaultValue={map_view ? '2000' : limit}
                  onChange={(e:any)=>{
                    setMapDetail('')
                    fetchAreas(0, parseInt(e.target.value), filter, _search, filterStatus, _mapProps, undefined, undefined, undefined, tableFilters)
                  }}
                >
                  <option value='25'>25</option>
                  <option value='50'>50</option>
                  <option value='150'>150</option>
                  <option value='2000'>All</option>
                </select>
              </div>
              <HasPerm permission='areas.forestfarmer.view'>
                <div className='col-2'>
                  <label className='col-form-label fw-bold fs-6'>{intl.formatMessage({id: `AREA.MANAGEMENT.TABLE.FILTER_ON`})}</label>
                  <div className='form-check form-check-solid form-switch fv-row'>
                    <input
                      className='form-check-input w-45px h-30px'
                      type='checkbox'
                      onChange={filterAreasChange}
                      checked={checked}
                    />
                    <label className='form-check-label'></label>
                  </div>
                </div>
              </HasPerm>
         
        </div>
        
        
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {!map_view ? (
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th>
                <th className='min-w-80px'>{intl.formatMessage({id: 'AREA.MANAGEMENT.TABLE.NAME'})}</th>
                <th className='min-w-140px'>{intl.formatMessage({id: 'AREA.MANAGEMENT.TABLE.LOCATION'})}</th>
                <th className='min-w-120px'>{intl.formatMessage({id: 'AREA.MANAGEMENT.TABLE.TREES'})}</th>
                <th className='min-w-120px'>{intl.formatMessage({id: 'AREA.MANAGEMENT.TABLE.SIZE'})}</th>
                <th className='min-w-120px'>{intl.formatMessage({id: 'AREA.MANAGEMENT.TABLE.STATUS'})}</th>
                <th className='min-w-80px'>
                  {intl.formatMessage({id: 'AREA.MANAGEMENT.TABLE.FORSTFARMER'})}
                  <div onClick={filterAreasChange} style={{cursor: "pointer"}}>
                    ({intl.formatMessage({id: `AREA.MANAGEMENT.TABLE.FILTER${checked ? '_ON':''}`})})
                  </div>
                  </th>
                <th className='min-w-80px'>{intl.formatMessage({id: 'AREA.MANAGEMENT.TABLE.REPORTED'})}</th>
                <th className='min-w-20px text-end'>{intl.formatMessage({id: 'AREA.MANAGEMENT.TABLE.ACTIONS'})}</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {loading && <SkeletonRow rows={5} columns={7} />}
              {!loading && areas.map((area: AreaModel, index: number) => (
                <tr key={index}>
                  <td>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-45px me-5'>
                        {area.image && (
                        <img src={toAbsoluteUrl(area.image, true)} alt='' style={{objectFit: "cover"}}/>
                        )}
                      </div>
                      <div className='d-flex justify-content-start flex-column'>
                        <Link to={"/area/"+area.id} className='text-dark fw-bolder text-hover-primary fs-6'>
                          {area.name}
                        </Link>
                      </div>
                    </div>
                  </td>
                  <td>
                    <p className='text-dark fw-bolder d-block fs-6'>
                      {area.location_name}
                    </p>
                    <span className='text-muted fw-bold text-muted d-block fs-7'>
                      {area.location_zip}
                    </span>
                  </td>
                  <td>
                    <span className='text-muted fw-bold text-muted d-block fs-7'>
                      {area.trees_planted}/{area.trees_to_plant} 
                    </span>
                  </td>
                  <td>
                    <span className='text-muted fw-bold text-muted d-block fs-7'>
                      {area.size} Hektar
                    </span>
                  </td>
                  <td className='text-end'>
                    <div className='d-flex flex-column w-100 me-2'>
                      <span className={`badge badge-light-${AREA_STATUS_BADGE[area.status]} fs-8 fw-bolder my-2`}>{getWorkflowStatus(area.workflow_status) || intl.formatMessage({id: 'AREA.MANAGEMENT.TABLE.NO_WORKFLOW'})}</span>
                      
                    </div>
                  </td>
                  <td>
                    <div className='d-flex'>
                    {area.forest_farmers && area.forest_farmers.map((farmer: AreaManager, index: number) => (
                      <Link to={'/profiles/'+farmer.id}>
                      <div key={index} className='symbol symbol-35px me-5 d-flex flex-column'>
                       <img src={toAbsoluteUrl(farmer.image, true)} alt='' style={{objectFit: "cover"}}/>
                    </div>
                    </Link>
                    ))}
                    </div>
                  </td>
                  <td>
                    <span className='text-muted fw-bold text-muted d-block fs-7'>
                      {timeSince(area.created_at)}
                    </span>
                  </td>
                  <td>
                    <div className='d-flex justify-content-end flex-shrink-0'>
                        <Link
                          to={'/area/'+area.id}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                        </Link>
                      <HasPerm permission='areas.area.delete'>
                        <button
                          // disabled={}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                          onClick={()=>showModal('delete-area-'+area.id)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      </HasPerm>
                      <ConfirmDropdown
                        text={intl.formatMessage({id: "AREA.MANAGEMENT.AREA.DELETE"})}
                        loading={deleteAreaLoading}
                        show={getModal('delete-area-'+area.id, modals)}
                        onConfirm={()=>deleteArea(area.id)}
                        onBack={()=>hideModal('delete-area-'+area.id)}
                        style={{position: "absolute"}}
                        />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
          <div className='d-flex justify-content-end'>
            <p className='text-dark mb-1 fs-6 me-3 mt-4'>{page+1}-{((page)+limit) > count ? count : (page)+limit} von {count}</p>

            {page !== 0 && (
                <div className='mr-2 '>
                  <button
                    onClick={(e:any)=>fetchAreas((page-limit) < 0 ? 0 :page-limit, limit, filter, filterStatus, _search, _mapProps, [], undefined, undefined, tableFilters)}
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                  >
                    <FontAwesomeIcon icon={faArrowLeft} />
                    {intl.formatMessage({id: 'AREA.MANAGEMENT.LIST.BACK'})}
                  </button>
                </div>
                )}
                {(page+limit) < count && (
                <div>
                  <button 
                    type='button'
                    onClick={(e:any)=>fetchAreas(page+limit, limit, filter, filterStatus, _search, _mapProps, [], undefined, undefined, tableFilters)}
                    className='btn btn-lg btn-primary me-3'>
                    <span className='indicator-label'>
                      {intl.formatMessage({id: 'AREA.MANAGEMENT.LIST.NEXT'})}
                    <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                    
                  </button>
                </div>
                )}
              </div>
              <div className='d-flex justify-content-end'>
            </div>
        </div>
        ):(
          <>
          <div style={{ height: '55vh', width: '100%' }}>
          {_mapProps.center && (
          <GoogleMapReact
            bootstrapURLKeys={{ key: "AIzaSyBH9__W-u7DMJ7b4xI1jiNSDrCEyaap4i4" }}
            center={_mapProps.center}
            zoom={_mapProps.zoom}
            onChange={(e:any)=>areaMapChange(e)}
            onGoogleApiLoaded={({ map, maps }) => {
              setMaps(maps);
              setMap(map);
            }
          }
            >
            {getAreasWithStatus().map((area: AreaModel, index: number) => (
              <MapMarker
                key={index}
                id={area.id}
                lat={area.lat}
                lng={area.lng}
                 />
                 
            ))}
            {showProjects && projects.map(project=>(
              <ProjectMarker
                key={project.id}
                lat={project.lat}
                lng={project.lng}
                image={project.image}
                name={project.name}
                zoom={_mapProps.zoom}
              />
            ))}
            {/* {showManagers && managers.map(manager=>(
              <ManagerMarker
                key={manager.id}
                lat={manager.lat}
                lng={manager.lng}
                image={manager.image}
                name={manager.name}
                zoom={_mapProps.zoom}
              />
            ))} */}
          </GoogleMapReact>
          )}
          <div className='row g-5 g-xl-8' style={{marginTop: "-17em", marginLeft: "-1.5em"}}>
            <div className='col-xl-4'>
              {mapDetail && selectedArea && (
                <MapInnerCard
                  className='card-xl-stretch mb-xl-8'
                  color='success'
                  area={selectedArea}
                  areaDetails={_areaDetails(selectedArea.id)}
                  onClose={()=>setMapDetail('')}
                />
              )}
            </div>
            </div>
            
          </div>
          <div className='row g-5 g-xl-8 mt-2'>
          {showManagers && (
            <div className='col-xl-12 d-flex'>
              <p 
                className={managerCircles.filter(c=>c.map).length===managerCircles.length? `fs-6 fw-bold`:'cursor-pointer '}
                onClick={()=>{
                  managerCircles.map((manager,key)=>handleManagerShow(key, "hide"))
                }}
              >
                Alle {intl.formatMessage({id: `AREA.MANAGEMENT.TABLE.MAPS.SHOW_MANAGERS`})}
              </p>
              /
              <p
                className={!managerCircles.filter(c=>c.map).length? `fs-6 fw-bold`:'cursor-pointer '}
                onClick={()=>{
                  managerCircles.map((manager,key)=>handleManagerShow(key, "show"))
                }}
              >
                Keine {intl.formatMessage({id: `AREA.MANAGEMENT.TABLE.MAPS.SHOW_MANAGERS`})}
              </p>
            </div>
            )}
            </div>
            <div className='row row-cols-6'>
              {showManagers && managers.map((manager:any, key:number)=>{
                return(
                <div className='col card mr-2'>
                  <div className='card-body p-2'>
                    <div className='d-flex'>
                      <img
                        src={toAbsoluteUrl(manager.image, true)}
                        alt=""
                        className='me-2'
                        width={43} height={43}/>
                      <div className='d-flex align-items-start flex-column fs-8 font-weight-bold me-2 text-white' onClick={()=>handleManagerShow(key)} style={{backgroundColor: colors[key], borderRadius: "2px", padding: "0.5em", cursor: "pointer", width: "10em", opacity: (managerCircles[key] && managerCircles[key].map)?1:0.5}}>
                        <div>
                          {manager.name.split(' ')[0]} {manager.name.split(' ')[1][0]}.
                        </div>
                        <div>
                          {manager.areas.length} {intl.formatMessage({id: 'AREA.MANAGEMENT.AREAS'})}
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>
              )})}
            </div>
          </>
          
        )}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {AreaTable}
