import React from 'react'
import { useIntl } from 'react-intl'
import { Route, Switch } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import PartnerDashboard from './PartnerDashboard'
import PartnerManage from './PartnerManage'
import PartnerOverview from './PartnerOverview'
import PartnerRegistration from './PartnerRegistration'
// import Workflow from './components/Workflow'

// import { fetchAreaForestRanger, fetchAreaOwnerDetails, fetchAreas } from './redux/AreaRedux'
// import AreaDocuments from './components/documents/AreaDocuments'
// import Workflow from './components/todos/Workflow'
// import Plots from './components/plots/Plots'
// import Plot from './components/plots/Plot'
// import HasPerm from '../auth/redux/HasPerm'
// import { HasPermission } from '../auth/Utils'
// import { useIntl } from 'react-intl'
// import Todos from './components/todos/Todos'
// import Jobs from './components/jobs/Jobs'
// import JobDetails from './components/jobs/JobDetails'
// import Overview from './components/details/Overview'
// import { Settings } from './components/details/Settings'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Partner',
    path: '/partners',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]


const PartnerPage: React.FC = () => {

  const intl = useIntl();

  return (
    <>
      <Switch>
        <Route path='/partners/registration'>
          <PageTitle>{intl.formatMessage({ id: "PARTNER.REGISTRATION" })}</PageTitle>
          <PartnerRegistration />
        </Route>

        <Route path='/partners/dashboard'>
          <PageTitle breadcrumbs={profileBreadCrumbs}>{intl.formatMessage({ id: "MENU.DASHBOARD" })}</PageTitle>
          <PartnerDashboard />
        </Route>

        <Route path='/partners/settings'>
          <PageTitle breadcrumbs={profileBreadCrumbs}>{intl.formatMessage({ id: "AREA.MENU.MANAGE" })}</PageTitle>
          <PartnerManage />
        </Route>

        <Route path='/partners'>
          <PageTitle breadcrumbs={profileBreadCrumbs}>{intl.formatMessage({ id: "AREA.MENU.OVERVIEW" })}</PageTitle>
          <PartnerOverview />
        </Route>
      </Switch>
    </>
  )
}

export default PartnerPage
