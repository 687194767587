/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import { useIntl } from 'react-intl'
// import {useQueryClient} from 'react-query'
import { Link } from 'react-router-dom'
import { MenuComponent } from '../../../../../../../../_metronic/assets/ts/components'
import { KTSVG } from '../../../../../../../../_metronic/helpers'
// import { QUERIES } from '../../../../../../../../_metronic/helpers/crud-helper/consts'
import { ID } from '../../../../../../../../_metronic/helpers/crud-helper/models'
import HasPerm from '../../../../../../auth/redux/HasPerm'
// import {useQueryResponse} from '../../core/QueryResponseProvider'
// import {deleteUser} from '../../core/_requests'

type Props = {
  id: ID
}

const UserActionsCell: FC<Props> = ({id}) => {
  // const {query} = useQueryResponse()
  // const queryClient = useQueryClient()
  const intl = useIntl()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])


  // const deleteItem = useMutation(() => deleteUser(id), {
  //   // 💡 response of the mutation is passed to onSuccess
  //   onSuccess: () => {
  //     // ✅ update detail view directly
  //     queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
  //   },
  // })

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        {intl.formatMessage({id: "MENU.ACTIONS"})}
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <Link className='menu-link px-3' to={'/profiles/'+id}>
            {intl.formatMessage({id: "GENERAL.EDIT"})}
          </Link>
        </div>
        {/* end::Menu item */}
        
        <HasPerm permission='profiles.profile.switch'>
          {/* begin::Menu item */}
          <div className='menu-item px-3'>
            <Link className='menu-link px-3' to={'/admin/switchProfile/'+id}>
              {intl.formatMessage({id: "AUTH.LOGIN.BUTTON"})}
            </Link>
          </div>
          {/* end::Menu item */}
        </HasPerm>
      </div>
      {/* end::Menu */}
    </>
  )
}

export {UserActionsCell}
