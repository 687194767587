/* eslint-disable jsx-a11y/anchor-is-valid */
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

type Props = {
  rows: number
  columns: number
  height?: number
}

const SkeletonRow= (props:Props) => {
	return (
    <>
      {Array.from(Array(props.rows).keys()).map((key: any, index: number) => (
        <tr key={index}>
          {Array.from(Array(props.columns).keys()).map((key: any, secondex: number) => (
            <td key={secondex}>
              <Skeleton height={props?.height}/>
            </td>
          ))}
        </tr>
      ))}
    </>
  )
}

export { SkeletonRow }
