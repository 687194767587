/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { API_URL, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { ProfileModel } from '../../../auth/models/ProfileModel'
import { PartnerMember } from '../../models/AreaModel'

type Props = {
  className: string
  addPartnerMember: (member:string)=>void
  removePartnerMember: (member:string)=>void
  members: PartnerMember[]
}

const AddPartnerMember: React.FC<Props> = ({className, addPartnerMember, members, removePartnerMember}) => {
  const intl = useIntl();
  const [profiles, setProfiles] = useState<ProfileModel[]>()
  // const [loading, setLoading] = useState(false)
  
  let timer:any = 0;

  const searchChange = (search:string) => {
    // setLoading(true)
    if(timer) clearTimeout(timer);
    timer = setTimeout(()=>{
      axios.get(API_URL+'/i/profiles?search='+search).then(res=>{
        setProfiles(res.data.results)
        // setLoading(false)
      })
    }, 500)
  }

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>{intl.formatMessage({id: "AREA.MANAGEMENT.PARTNERS"})}</span>
        </h3>
      </div>
      <div className='card-body pt-3'>
        <div className='mb-5'>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
            placeholder={intl.formatMessage({id: 'AREA.MANAGEMENT.SEARCH'})}
            onChange={e=>searchChange(e.target.value)}
          />
        </div>
        {profiles?.map(profile=>(
        <div className='d-flex align-items-sm-center mb-7'>
          <div className='symbol symbol-60px symbol-2by3 me-4'>
          <div
            className='symbol-label'
            style={{backgroundImage: `url(${toAbsoluteUrl(profile?.image||'', true)})`}}
          ></div>
        </div>
          <div className='d-flex flex-row-fluid flex-wrap align-items-center'>
            <div className='flex-grow-1 me-2'>
              <Link className='text-dark text-hover-primary fs-6' to={'/profiles/'+profile.id}>
                {profile.first_name} {profile.last_name}
              </Link>
              <span className='text-muted fw-bold d-block pt-1'>{profile.email}</span>
            </div>
            {/* <span className='text-muted fw-bold d-block pt-1 me-10'>{getDaysToEnd(job.due_date)} {intl.formatMessage({id: "AREAS.TODOS.DAYS_REMAINING"})}</span> */}
          </div>
          {members.findIndex(member=>member.profile.profile.toString()===profile.id.toString()) > -1?(
            <button className='btn btn-danger' onClick={()=>removePartnerMember(members.filter(member=>member.profile.profile.toString()===profile.id.toString())[0].id)}>{intl.formatMessage({id: 'AREA.MANAGEMENT.REMOVE'})}</button>
          ):(
            <button className='btn btn-primary' onClick={()=>addPartnerMember(profile.id.toString())}>{intl.formatMessage({id: 'AREA.MANAGEMENT.ADD'})}</button>
          )}
        </div>
        ))}
      </div>
    </div>
  )
}

export {AddPartnerMember}