import { Component } from 'react'
import { connect } from 'react-redux'
import { AppDispatch } from '../../../../setup/redux/Store'

import {  AreaWorkflowStepDataTemplate, AreaWorkflowStepTemplate } from '../models/AreaModel'
import { createTemplateStage, createTemplateStep, deleteTemplateStage, deleteTemplateStep, fetchJobTemplates, fetchTemplateStages, fetchTemplateStepData, updateStepOrder } from '../redux/AreaRedux'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faPen, faTrash } from '@fortawesome/pro-duotone-svg-icons'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { _hideModal, _showModal } from '../../modals/redux/ModalRedux'
import { ConfirmDropdown } from '../../../../_metronic/partials/content/dropdown/ConfirmDropdown'

const grid = 8;

const getItemStyle = (isDragging:any, draggableStyle:any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  border: "1px dashed",
  borderRadius: "0.3em",
  borderColor: "#a1a5b7",
  backgroundColor: "white",
  width: "15em",

  // change background colour if dragging
  //background: isDragging ? "lightgreen" : "grey",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = (isDraggingOver: any) => ({
  //background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: grid,
  width: 280
});


// a little function to help us with reordering the result
const reorder = (list: AreaWorkflowStepTemplate[], startIndex: number, endIndex: number) => {

  let result = Array.from(list);
  result[startIndex] = {...result[startIndex], step_order: endIndex+1}
  if(startIndex < endIndex) {    
    result.forEach((step, i)=>{
      if(step.step_order>(startIndex+1) && step.step_order <= (endIndex+1) && i !== startIndex) {
        result[i] = {...result[i], step_order: step.step_order-1}
      } 
    })

  } else {
    result.forEach((step, i)=>{
      if(step.step_order<(startIndex+1) && step.step_order >= (endIndex+1) && i !== startIndex) {
        result[i] = {...result[i], step_order: step.step_order+1}
      } 
    })
  }
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed); 
  return result;
};

const STAGE_DATA_TYPES = [
  "Daten", "Dokument", "Job", "Plot"
]

interface ConnectedProps {
  createStepLoading: boolean,
  deleteStepLoading: boolean,
  stepDataTemplates: any[],
  stage: any,
  workflowSteps: any[]
}

interface DispatchProps {
  fetchTemplateStages: typeof fetchTemplateStages
  createTemplateStage: typeof createTemplateStage
  fetchJobTemplates: typeof fetchJobTemplates
  createTemplateStep: typeof createTemplateStep,
  hideModal: typeof _hideModal,
  showModal: typeof _showModal,
  updateSteps: typeof updateStepOrder,
  fetchAreaStepData: typeof fetchTemplateStepData
  deleteStep: typeof deleteTemplateStep
}

interface RouterParams {
  workflow: string;
} 
type Props = ConnectedProps & DispatchProps & RouteComponentProps<RouterParams>

class WorkFlowStage extends Component<Props, any> {
  private stage;
  private history;

  constructor(props: any) {
    super(props);

    this.state = {
      items: props.workflowSteps || [],
      dragging: false,
      showConfirm: false,
      showDelete: -1
    };

    this.onDragEnd = this.onDragEnd.bind(this);
    this.stage = props.stage;
    this.history = props.history;
  }

  componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
    if (this.props.workflowSteps !== prevProps.workflowSteps) {
      this.setState({ items: this.props.workflowSteps })
    }

    if (this.props.stage !== this.stage) {
      this.stage = this.props.stage;
    }
  }

  onDragEnd(result: any) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index, // ! nicht order vom anderen
      result.destination.index // Neue order vom Step
    );
    this.setState({
      items,
      dragging: false
    });

    this.props.updateSteps(this.props.stage.id, items)
  }

  getStepData(step: string) {
    return this.props.stepDataTemplates.filter((_step:AreaWorkflowStepDataTemplate)=>{
      return _step.id === step
    })[0] || {}
    
  }

  getItemsByOrder() {
    return this.state.items.sort((a:AreaWorkflowStepTemplate,b:AreaWorkflowStepTemplate)=>a.step_order-b.step_order);
  }

  showEditModal(item: AreaWorkflowStepTemplate, type: 'stage' | 'step') {
    this.props.showModal(
      'edit-item-template',
      {
        type,
        template: item
      }
    );
  }

  navigateToWorkflow(id: string) {
    this.history.push('/workflow/' + id);
  }

  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd} onDragStart={()=>this.setState({dragging: true})}>
        <div className='d-flex'>
          <h3>{this.stage.stage_name}</h3>{this.state.items.length ? (
            <div>
            </div>
            ):''}
            <div>
              <span 
                  style={{marginLeft: "0.9em", cursor: "pointer"}}
                  onClick={()=>this.showEditModal(this.stage, 'stage')}
              >
                    <FontAwesomeIcon icon={faPen} />
              </span>
              <span 
                style={{marginLeft: "0.9em", cursor: "pointer"}} onClick={()=>this.setState({showConfirm: true})}>
                  <FontAwesomeIcon icon={faTrash} />
              </span>
              
            </div>
        </div>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {this.getItemsByOrder().map((item: AreaWorkflowStepTemplate, index: number) => (
                <div key={index} className='d-flex'>
                  <Draggable key={"item-"+item.id} draggableId={"item-"+item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                        data-tip data-for={`stepTip-${item.id}`}
                      >
                        {index+1}. {item.step_name}
                        <ReactTooltip id={`stepTip-${item.id}`} place="top" effect="solid" clickable={true}  afterShow={()=>this.props.fetchAreaStepData(item.step_data.id)}>
                          Name: {item.step_name} <br/>
                          Type: {STAGE_DATA_TYPES[this.getStepData(item.step_data.id)._type-1] || ""} <br />
                          Quelle: {(this.getStepData(item.step_data.id).result && this.getStepData(item.step_data.id).result.data)  || ""} < br />
                        </ReactTooltip>
                      </div>
                    )}
                  </Draggable>
                  {!this.state.dragging && 
                    (
                      <>
                        <button type="button" style={{marginLeft: "1em", marginTop: "1.1em", cursor: "pointer", border: "none"}} className='h-100 py-1'
                          onClick={()=>this.showEditModal(item, 'step')}
                        >
                          <FontAwesomeIcon icon={faPen} />
                        </button>

                        <button type="button" style={{marginLeft: "1em", marginTop: "1.1em", cursor: "pointer", border: "none", marginRight: item.step_data._type !== 4 ? '35px' : ''}} className='h-100 py-1'
                          onClick={()=>this.setState({showDelete: item.id})}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </button>

                        {item.step_data._type === 4 && (
                          <button type="button" style={{marginLeft: "1em", marginTop: "1.1em", cursor: "pointer", border: "none"}} className='h-100 py-1' title='Workflow anzeigen'
                            onClick={() => this.navigateToWorkflow(item.step_data.data)}
                          >
                            <FontAwesomeIcon icon={faArrowRight} />
                          </button>
                        )}
                      </>
                    )}
                    <ConfirmDropdown
                      show={this.state.showDelete === item.id}
                      loading={this.props.deleteStepLoading} 
                      onConfirm={()=>this.props.deleteStep(this.props.stage.id, item.id, this.props.match.params.workflow)} 
                      onBack={()=>this.setState({showDelete: -1})}
                  />

                </div>
              ))}
              
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
      createStepLoading: state.areas.createStepLoading,
      deleteStepLoading: state.areas.deleteTemplateStepLoading,
      stepDataTemplates: state.areas.stepDataTemplates || [],

  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
      fetchAreaStepData: (step: string) => dispatch(fetchTemplateStepData(step)),
      deleteStep: (stage:string, step:string, workflow:string) => dispatch(deleteTemplateStep(stage, step, workflow)),
      updateSteps: (stage:string, steps:any) => dispatch(updateStepOrder(stage, steps)),
      deleteStage: (stage: string, workflow:string) => dispatch(deleteTemplateStage(stage, workflow)),
      showModal: (name: string, data: any) => dispatch(_showModal(name, data)),
      hideModal: (name: string)=>dispatch(_hideModal(name))
  };
};


export default withRouter<any, any>(connect(mapStateToProps, mapDispatchToProps)(WorkFlowStage));
