import React, {FC} from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../../_metronic/layout/core'
import WorkflowPage from './WorkflowPage'

const WorkflowPageWrapper: FC = () => {

  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({"id": "AREA.MANAGEMENT.WORKFLOW"})}</PageTitle>
      <WorkflowPage />
    </>
  )
}

export default WorkflowPageWrapper
