/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../../_metronic/helpers'
import { ModalModel } from '../../modals/models/ModalModel'
import { getModal, _hideModal, _showModal } from '../../modals/redux/ModalRedux'
import { timeSince } from '../components/documents/AreaDocuments'
import { SkeletonRow } from '../components/SkeletonRow'
import { Workflow } from '../models/AreaModel'
import { createAreaWorkflow } from '../redux/AreaRedux'
import { WorkflowCreateModal } from './WorkflowCreateModal'


type Props = {
  className: string;
  templates: Workflow[];
  createWorkflow: typeof createAreaWorkflow
  createWorkflowLoading: boolean
  modals: ModalModel[]
  showModal: typeof _showModal
  hideModal: typeof _hideModal
  loading: boolean
}


const  WorkflowTemplateTable = (props: Props) => {
  const {className, templates, createWorkflow, createWorkflowLoading, modals, showModal, hideModal, loading} = props;
  const intl = useIntl();
  const [ search, setSearch ] = useState('')

  function filterTemplates(input: string) {
      return (templates.filter(
        (item:Workflow) =>
          (item.name && item.name.toLowerCase().includes(input.toLowerCase())
      )));
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>{intl.formatMessage({id: 'AREA.MANAGEMENT.WORKFLOW.TEMPLATE_TABLE'})}</span>
          <span className='text-muted mt-1 fw-bold fs-7'>{templates.length} {intl.formatMessage({id: 'AREA.MANAGEMENT.WORKFLOW.TEMPLATES'})}</span>
        </h3>
        
        <div
          className='card-toolbar d-flex'
        >
        <label className='col-lg-4 col-form-label fw-bold fs-6'>{intl.formatMessage({id: 'AREA.MANAGEMENT.SEARCH'})}</label>

        <div className='col-lg-8'>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
            onChange={(e:any)=>setSearch(e.target.value)}
          />
          {false && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>ERROR</div>
            </div>
          )}
        </div>
        </div>
      </div>
      <div className='block mt-1'>
        <button
          type='button'
          onClick={()=>showModal('create-template')}
          className='btn btn-primary'
          style={{float: "right", marginRight: "2em"}}
        >{intl.formatMessage({id: 'AREA.MANAGEMENT.WORKFLOW.CREATE_TEMPLATE'})}</button>
        <WorkflowCreateModal 
          show={getModal('create-template', modals)} handleClose={()=>hideModal('create-template')}
          createWorkflow={createWorkflow} loading={createWorkflowLoading} workflows={templates}/>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th>
                <th className='min-w-150px'>{intl.formatMessage({id: 'AREA.MANAGEMENT.TABLE.NAME'})}</th>
                <th className='min-w-140px'>{intl.formatMessage({id: 'AREA.MANAGEMENT.WORKFLOW.STAGES'})}</th>
                <th className='min-w-120px'>{intl.formatMessage({id: 'AREA.MANAGEMENT.WORKFLOW.STEPS'})}</th>
                <th className='min-w-120px'>{intl.formatMessage({id: 'AREA.MANAGEMENT.WORKFLOW.UPDATED'})}</th>
                <th className='min-w-20px text-end'>{intl.formatMessage({id: 'AREA.MANAGEMENT.TABLE.ACTIONS'})}</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {loading && <SkeletonRow rows={3} columns={5} />}
              {!loading && filterTemplates(search).map((template: Workflow, index: number) => (
                <tr key={index}>
                  <td>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        <Link to={"/workflow/"+template.id} className='text-dark fw-bolder text-hover-primary fs-6'>
                          {template.name}
                        </Link>
                      </div>
                    </div>
                  </td>
                  <td>
                    
                    <span className='text-muted fw-bold text-muted d-block fs-7'>
                      {template.stages.length} {intl.formatMessage({id: 'AREA.MANAGEMENT.WORKFLOW.STAGES'})}
                    </span>
                  </td>
                  <td>
                    <span className='text-muted fw-bold text-muted d-block fs-7'>
                    {template.stages.length} {intl.formatMessage({id: 'AREA.MANAGEMENT.WORKFLOW.STEPS'})}
                    </span>
                  </td>
                  <td>
                    <span className='text-muted fw-bold text-muted d-block fs-7'>
                      {timeSince(template.updated_at)} 
                    </span>
                  </td>
                  
                  <td>
                    <div className='d-flex justify-content-end flex-shrink-0'>
                      <Link
                        to={'/workflow/'+template.id}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                      </Link>
                      <button
                        disabled
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen027.svg'
                          className='svg-icon-3'
                        />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
              
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {WorkflowTemplateTable}
