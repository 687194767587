import React from 'react'
import { useIntl } from 'react-intl'

const Loading: React.FC = () => {
  const intl = useIntl();
   return (
      <span className='indicator-progress' style={{ display: 'block' }}>
        {intl.formatMessage({ id: 'AUTH.GENERAL.LOADING_UPDATE' })}
        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
      </span>
  )
}

export {Loading}
