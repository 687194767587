/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { ConfirmDropdown } from '../../../../../_metronic/partials/content/dropdown/ConfirmDropdown'
import { PartnerMember as PartnerMemberModel } from '../../models/AreaModel'

type Props = {
  className: string
  members: PartnerMemberModel []
  removePartnerMember: (member:string)=>void
}

const PartnerMembers: React.FC<Props> = ({className, members, removePartnerMember}) => {
  const intl = useIntl();
  const [showDelete, setShowDelete] = useState('')


  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>{intl.formatMessage({id: "AREA.MANAGEMENT.PARTNER.MEMBER"})}</span>

          <span className='text-muted fw-bold fs-7'>{members.length} {intl.formatMessage({id: "AREA.MANAGEMENT.PARTNER.MEMBER"})}</span>
        </h3>


      </div>
      <div className='card-body pt-3'>
        {members.map(member=>(
          <div className='d-flex align-items-sm-center mb-7'>
            <div className='symbol symbol-60px symbol-2by3 me-4'>
            <div
              className='symbol-label'
              style={{backgroundImage: `url(${toAbsoluteUrl(member.profile.image, true)})`}}
            ></div>
          </div>
          <div className='d-flex flex-row-fluid flex-wrap align-items-center'>
            <div className='flex-grow-1 me-2'>
              <Link className='text-dark text-hover-primary fs-6' to={'/profiles/'+member.profile.profile}>
                {member.profile.name}
              </Link>
            </div>
            <div>
              <div className='row'>
              <span className={`badge badge-light-${member.status === 1 ? 'success':'warning'} fs-8 fw-bolder my-2`}>{member.status === 1 ? 'Aktiv':'Ausstehend'}</span>
              </div>
              <div className='row'>
              <span className={`badge badge-danger fs-8 fw-bolder my-2 cursor-pointer`} onClick={()=>setShowDelete(member.id.toString())}>{intl.formatMessage({id: 'AREA.MANAGEMENT.REMOVE'})}</span>
              <ConfirmDropdown
                loading={false}
                show={showDelete===member.id}
                onConfirm={()=>{
                  removePartnerMember(member.id)
                  setShowDelete('')
                }}
                onBack={()=>setShowDelete('')}
                style={{position: "absolute", width: "20em"}}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
      </div>
    </div>
  )
}

export {PartnerMembers}