/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import { Component } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { RootState } from '../../../setup/redux/Store'
import { AppDispatch } from '../../../setup/redux/Store'
import { API_URL } from '../../../_metronic/helpers'
import { PartnerAreas } from '../areas/components/dashboard/PartnerAreas'
import { PartnerJobs } from '../areas/components/dashboard/PartnerJobs'
import { TodosList } from '../areas/components/todos/TodosList'
import { AreaJob, AreaModel, AreaTodo, AssignAreas, JobFilters, Partner } from '../areas/models/AreaModel'
import { addAreaMember, fetchAssignAreas, fetchPartnerList, fetchPartnersJobs, getPartner, setSelectedPartner } from '../areas/redux/AreaRedux'
import { ProfileModel } from '../auth/models/ProfileModel'
// import { PartnerAreasCard } from './PartnerAreasCard'
// import { PartnerWelcomeCard } from './PartnerWelcomeCard'
// import HasPerm from '../../../auth/redux/HasPerm'
// import { AreaJob } from '../../models/AreaModel'
// import { fetchAreaJobs } from '../../redux/AreaRedux'
// import { JobCard } from './JobCard'
// import { getTotalCost } from './JobFinance'

interface WorkflowProps {
  // area: string
}

interface WorkflowState {
  todos: AreaTodo[]
  todosLoading: boolean

}

interface ConnectedProps {
  partners: Partner[]
  loading: boolean
  selectedPartner: string
  profile: ProfileModel
  partnerJobs: AreaJob[]
  areas: AreaModel[]
  assignAreas: AssignAreas[]
  filters: JobFilters
  // partnerTodos: AreaTodo[]
}

interface DispatchProps {
  // fetchAreaJobs: typeof fetchAreaJobs
  fetchPartners: typeof fetchPartnerList
  setSelectedPartner: typeof setSelectedPartner
  fetchPartnersJobs: typeof fetchPartnersJobs
  fetchAssignAreas: typeof fetchAssignAreas
  addMember: typeof addAreaMember
  // fetchPartnerTodos: typeof fetchPartnerTodos
}

type Props = ConnectedProps & DispatchProps & WorkflowProps & WrappedComponentProps & RouteComponentProps


class PartnerDashboard extends Component<Props, WorkflowState> {

  constructor(props:Props) {
    super(props);
    this.state = {
      todos: [],
      todosLoading: false
    }
  }
  
  componentDidMount(){
    // this.props.fetchAreaJobs(this.props.area)
    this.props.fetchPartners()
    // this.props.setSelectedPartner('')
    if(!this.props.selectedPartner) {
      this.props.history.push('/partners')
    }
    const partner = getPartner(this.props.selectedPartner, this.props.partners)
    const fetchAll = (partner && partner.partner_type) === "PROVIDER"
    this.props.fetchPartnersJobs(this.props.selectedPartner, {...this.props.filters, page:undefined, all:fetchAll})
    this.props.fetchAssignAreas()
    // this.props.fetchPartnerTodos(this.props.selectedPartner)
    this.fetchTodos()
  }

  fetchTodos(){
    this.setState({todosLoading:true})
    axios.get(API_URL+`/partners/${this.props.selectedPartner}/todos`).then(
      res => {
        this.setState({todos: res.data, todosLoading: false})
      }
    )
  }


  render() {
    const partner = getPartner(this.props.selectedPartner, this.props.partners)
    return (
      <>
        <div className='d-flex flex-wrap flex-stack mb-6'>
          <h3 className='fw-bolder my-2'>
            
            {/* <span className='fs-6 text-gray-400 fw-bold ms-1'>Aktive</span> */}
          </h3>

        </div>

        {/* <div className='row g-6 g-xl-9 mt-6'>
          <div className='col-md-6 col-xl-4'>
            {partner && (
            <PartnerWelcomeCard 
              logo={partner.logo}
              name={partner.name}
              profile={this.props.profile}
              id={this.props.selectedPartner}
            />
            )}
          </div>
          <div className='col-md-6 col-xl-4'>
            <PartnerAreasCard 
              areas={0}
              partner={this.props.selectedPartner}
            />
          </div>
        </div> */}
        <div className='row g-6 g-xl-9 mt-6'>
          <div className='col-md-12'>
            {(partner && partner.partner_type) === "MANAGER" && (
              <TodosList todos={this.state.todos} className="mb-5" todosLoading={this.state.todosLoading}/>
            )}
            <PartnerJobs className='' jobs={this.props.partnerJobs} filters={this.props.filters } fetchJobs={
              (filters:JobFilters)=>this.props.fetchPartnersJobs(
                this.props.selectedPartner, filters
              )}/>
          </div>
          {(partner && partner.partner_type) === "MANAGER" && (
            <div className='col-md-5'>
              <PartnerAreas className='' 
                areas={this.props.assignAreas}
                member={partner.member}
                addMember={this.props.addMember}
              />
            </div>
          )}
         
        </div>
      </>
    )
  }
}


const mapStateToProps = (state: RootState) => {
  return {
      partners: state.areas.partnersList,
      loading: state.areas.partnersListLoading,
      selectedPartner: state.areas.selectedPartner,
      profile: state.auth.profile,
      partnerJobs: state.areas.partnerJobs,
      areas: state.areas.areas,
      assignAreas: state.areas.assignAreas,
      partnerTodos: state.areas.partnerTodos,
      filters: state.areas.jobFilters
      
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
      fetchAssignAreas: () => dispatch(fetchAssignAreas()),

      // fetchAreaJobs: (area: string) => dispatch(fetchAreaJobs(area)),
      fetchPartners: () => dispatch(fetchPartnerList()),
      setSelectedPartner: (partner:string) => dispatch(setSelectedPartner(partner)),
      fetchPartnersJobs: (partner:string, filters:JobFilters) => dispatch(fetchPartnersJobs(partner, filters)),
      addMember: (area:string, member:string) => dispatch(addAreaMember(area,member)),
      // fetchPartnerTodos: (partner:string) => dispatch(fetchPartnerTodos(partner)),

  };
};

export default withRouter(connect<ConnectedProps, DispatchProps>(mapStateToProps, mapDispatchToProps)(injectIntl(PartnerDashboard)));
