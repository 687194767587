/* eslint-disable jsx-a11y/anchor-is-valid */
import { Field, ErrorMessage } from 'formik'
import { useIntl } from 'react-intl';
import { FC } from 'react';
import { PartnerTypes } from '../../PartnerMemberModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Step1PartnerType: FC = () => {
  const intl = useIntl();

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>
          {intl.formatMessage({ id: "PARTNER.REGISTRATION.STEP_TYPE.TYPE_LABEL" })}
        </h2>
      </div>

      <div className='fv-row'>
        <div className='row'>
          {PartnerTypes.map((partnerType, index) => (
            <div className='col-md-4' key={index}>
              <Field
                type='radio'
                className='btn-check'
                name='type'
                value={partnerType.name}
                id={'partner_type_' + partnerType.name}
              />
              <label
                className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10'
                htmlFor={'partner_type_' + partnerType.name}
              >
                <FontAwesomeIcon icon={partnerType.icon} className="svg-icon-3x me-5" size='3x' />

                <span className='d-block fw-bold text-start'>
                  <span className='text-dark fw-bolder d-block fs-4 mb-2'>{intl.formatMessage({ id: `AREA.MANAGEMENT.PARTNERMEMBER.TYPE.${partnerType.name}` })}</span>
                </span>
              </label>
            </div>
          ))}

          <div className='fv-plugins-message-container invalid-feedback'>
            <ErrorMessage name='type' />
          </div>
        </div>
      </div>
    </div>
  )
}

export { Step1PartnerType }
