/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC, useState} from 'react'
import { useIntl } from 'react-intl'
import {toAbsoluteUrl} from '../../../helpers'
import {useLang, setLanguage} from '../../../i18n/Metronici18n'

const languages = [
  {
    lang: 'en',
    name: 'English',
    flag: toAbsoluteUrl('/media/flags/united-states.svg'),
  },
  {
    lang: 'de',
    name: 'Deutsch',
    flag: toAbsoluteUrl('/media/flags/germany.svg'),
  },
]

const Languages: FC = () => {
  const lang = useLang()
  const currentLanguage = languages.find((x) => x.lang === lang)
  const [ show, setShow ] = useState(false)
  const intl = useIntl();
  return (
    <div
      className='menu-item px-5'
      data-kt-menu-trigger='hover'
      data-kt-menu-flip='bottom'
    >
      <a href='#' className='menu-link px-5' onClick={()=>setShow(true)}>
        <span className='menu-title position-relative'>
        {intl.formatMessage({id: 'MENU.LANGUAGE'})}
          <span className='fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0'>
            {currentLanguage?.name}{' '}
            <img
              className='w-15px h-15px rounded-1 ms-2'
              src={currentLanguage?.flag}
              alt='language'
            />
          </span>
        </span>
      </a>

      <div className={`menu-sub menu-sub-dropdown w-175px py-5 ${show && 'show'}`}>
        {languages.map((l) => (
          <div
            className='menu-item px-3 mb-2'
            key={l.lang}
            onClick={() => {
              setLanguage(l.lang)
            }}
          >
            <a
              href='#'
              className={clsx('menu-link d-flex px-5', {active: l.lang === currentLanguage?.lang})}
            >
              <span className='symbol symbol-20px me-4'>
                <img className='rounded-1' src={l.flag} alt='metronic' />
              </span>
              {l.name}
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

export {Languages}
