
import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { AuthModel } from '../models/AuthModel';
import { ProfileModel } from '../models/ProfileModel';

interface AuthState {
  auth: AuthModel
  authLoading: boolean
  profile: ProfileModel
  profileLoading: boolean
}

const initialState: AuthState = {
    auth: {
      accessToken: "",
      refreshToken: "",
    },
    authLoading: false,
	  profile: {
      id: 0,
      user: 0,
      username: "",
      email: "",
      first_name: "",
      last_name: "",
      image_url: "",
      notifications: 0,
      public_profile: false,
      send_mails: false,
      date_joined: ''
      
    },
    profileLoading: false
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
      loginLoading: (state: Draft<typeof initialState>) => {
        state.authLoading = true;
      },
      loginSuccess: (state: Draft<typeof initialState>, action: PayloadAction<any>) => {
        state.auth.accessToken = action.payload.access;
        state.auth.refreshToken = action.payload.refresh;
        state.authLoading = false;
      },
      loginFail: (state: Draft<typeof initialState>) => {
        state.authLoading = false;
      },
      fetchProfileLoading: (state: Draft<typeof initialState>) => {
        state.profileLoading = true;
      },
      fetchProfileSuccess: (state: Draft<typeof initialState>, action: PayloadAction<any>) => {
        state.profile = action.payload.results[0];
        state.profileLoading = false;
      },
      fetchProfileFail: (state: Draft<typeof initialState>) => {
        state.profileLoading = false;
      },
      logoutSuccess: (state: Draft<typeof initialState>) => {
        state.auth.accessToken = "";
      },
      updateNotifications: (state: Draft<typeof initialState>, action: PayloadAction<any>) => {
        state.profile.notifications = action.payload;
      },
    }
});

// Reducers and actions
export const {
  loginLoading,
  loginSuccess,
  loginFail,
  fetchProfileLoading,
  fetchProfileSuccess,
  fetchProfileFail,
  logoutSuccess,
  updateNotifications
} = authSlice.actions;

export default authSlice.reducer;
