import React from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../../../setup/redux/Store';
import { ProfileModel } from '../models/ProfileModel';
import { HasPermission } from '../Utils';

interface HasPermsProps {
  children: React.ReactNode;
  permission: string;
}

interface HasPermsState {
}

interface ConnectedProps {
  profile: ProfileModel
}

type Props = ConnectedProps & HasPermsProps

class HasPerm extends React.Component<Props, HasPermsState> {
  render() {
    if (HasPermission(this.props.permission)) {
      if (!this.props.children){
        return <></>
      }
      return this.props.children;
    }

    return <></>;
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    profile: state.auth.profile
  };
};

export default connect<ConnectedProps>(mapStateToProps)(HasPerm);
