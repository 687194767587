/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { faSave, faTimes, faTrash } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, {useState, useRef} from 'react'
import {Modal} from 'react-bootstrap-v5'
import { StepperComponent } from '../../../_metronic/assets/ts/components'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { ConfirmDropdown } from '../../../_metronic/partials/content/dropdown/ConfirmDropdown'
import HasPerm from '../auth/redux/HasPerm'
import { AreaDocument } from './models/AreaModel'
import { deleteDocument, renameDocument } from './redux/AreaRedux'

type Props = {
  show: boolean
  document: AreaDocument
  handleClose: () => void
  handleRename: typeof renameDocument
  handleDelete: typeof deleteDocument 
  loading: boolean
}

const AreaDocumentChange: React.FC<Props> = ({show, handleClose, document, handleRename, handleDelete, loading}) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [ name, setName ] = useState(document.name)
  const [ showConfirm, setShowConfirm ] = useState(false)

  const handleChange = (e:React.FormEvent<HTMLInputElement>) => {
    setName(e.currentTarget.value)
  }

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }


  
  return (
    <Modal
      id={'kt_change_area_document_modal_'+document.id}
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-1000px h-auto'
      show={show}
      onHide={handleClose}
      onEntered={loadStepper}
    >
      <div className='container-xxl px-10 py-10'>
        <div className='modal-header py-2 d-flex justify-content-end border-0'>
          {/* begin::Close */}
          <div className='btn btn-icon btn-sm btn-light-primary' onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          {/* end::Close */}
        </div>

        <div className='modal-body'>
          {/*begin::Stepper */}
          <div
            className='d-flex flex-column flex-xl-row flex-row-fluid'
            id='kt_modal_create_app_stepper'
          >
            {/*begin::Aside */}
            <div className='d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px'>
              
            <div className='symbol symbol-75px mb-6'>
              <img src={toAbsoluteUrl(`/media/svg/files/${document.filetype}.svg`)} alt=''/>
              </div>
            </div>
            {/*begin::Aside */}

            {/*begin::Content */}
            <div className='d-flex flex-row-fluid justify-content-center'>
              {/*begin::Form */}
              <form
                className='pb-5 w-100 w-md-400px w-xl-500px'
                noValidate
                id='kt_modal_create_app_form'
              >
                {/*begin::Step 1 */}
                <HasPerm permission='areas.areadocument.change'>
                  <div className='pb-5 current' data-kt-stepper-element='content'>
                    <div className='w-100'>
                      {/*begin::Heading */}
                      <div className='pb-5 pb-lg-10'>
                        <h3 className='fw-bolder text-dark display-6'>Dokument bearbeiten</h3>
                      </div>
                      {/*begin::Heading */}

                      {/*begin::Form Group */}
                      <div className='fv-row mb-12'>
                        <label className='fs-6 fw-bolder text-dark form-label'>Name</label>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          name='appname'
                          placeholder=''
                          value={name}
                          onChange={handleChange}
                          
                        />
                      </div>
                      {/*end::Form Group */}

                    </div>
                  </div>
                </HasPerm>
                {/*end::Step 1 */}

                {/*begin::Actions */}
                <div className='d-flex justify-content-between pt-10'>
                  <div className='mr-2'>
                
                  </div>
                  <div>
                  <ConfirmDropdown
                    show={showConfirm} 
                    loading={loading}
                    text={"Dokument löschen?"}
                    onConfirm={()=>handleDelete(document.area, document.id)}
                    onBack={()=>setShowConfirm(false)}
                    /> 
                  <HasPerm permission='areas.areadocument.delete'>
                    <button
                      type='button'
                      className='btn btn-lg btn-danger fw-bolder py-4 ps-8 me-3'
                      disabled={loading}
                      onClick={()=>setShowConfirm(true)}
                    >
                      Löschen{' '}
                      <FontAwesomeIcon
                        icon={faTrash}
                        className='svg-icon-3 ms-1'
                      />
                       
                    </button>
                  </HasPerm>

                    <button
                      type='button'
                      className='btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3'
                      disabled={name === document.name || loading}
                      onClick={()=>handleRename(document.area, document.id, name)}

                    >
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Bitte warten...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                      {!loading && (
                      <>
                      Speichern{' '}
                      <FontAwesomeIcon
                        icon={faSave}
                        className='svg-icon-3 ms-1'
                      />
                      </>
                      )}</button>
                  
                  </div>
                </div>
                {/*end::Actions */}
              </form>
              {/*end::Form */}
            </div>
            {/*end::Content */}
          </div>
          {/* end::Stepper */}
        </div>
      </div>
    </Modal>
  )
}

export {AreaDocumentChange}
