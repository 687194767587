/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import {FC, useEffect, useState} from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { API_URL, toAbsoluteUrl } from '../../../_metronic/helpers'

type Props = {
  logo: string
  name: string
  id: string
  active: boolean
  is_manager: boolean
  setPartner: (id:string)=>void
}

const PartnerCard: FC<Props> = ({
  logo,
  name,
  id,
  active,
  is_manager,
  setPartner
}) => {

  const [stats, setStats] = useState<any>({});
  const history = useHistory()
  useEffect(()=>{

    axios.get(API_URL+`/partners/${id}/statistics`).then(res => setStats(res.data))
  }, [id, setStats])

  const intl = useIntl();
  return (
    <div
      className='card border border-2 border-gray-300 border-hover'
    >
      <div className='card-header border-0 pt-9'>
        <div className='card-title m-0'>
          <div className='symbol symbol-50px w-50px bg-light'>
            <img src={toAbsoluteUrl(logo, true)} alt='jobCard' className='p-3' />
          </div>
        </div>

        <div className='card-toolbar'>
          <span className={`badge badge-light-${active ? 'success':'warning'} fw-bolder me-auto px-4 py-3`}>
            {intl.formatMessage({id: `PARTNER.STATUS.${active ? 'ACTIVE':'PENDING'}`})}
          </span>
        </div>
      </div>

      <div className='card-body p-9'>
        <div className='fs-3 fw-bolder text-dark'>{name} {!active && intl.formatMessage({id: "PARTNER.STATUS.PENDING.MESSAGE"})}</div>

        <div className='d-flex flex-wrap mb-5'>
            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3'>
              <div className='fs-6 text-gray-800 fw-bolder'>{stats?.jobs}</div>
              <div className='fw-bold text-gray-400'>{intl.formatMessage({id: "AREA.MENU.JOBS"})}</div>
            </div>
          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
            <div className='fs-6 text-gray-800 fw-bolder'>{stats?.areas}</div>
            <div className='fw-bold text-gray-400'>{intl.formatMessage({id: "AREA.MANAGEMENT.AREAS"})}</div>
          </div>

        </div>

        <div
          className='h-4px w-100 bg-light mb-5'
        >
          <div
            className={`bg-${active ? 'success':'warning'} rounded h-4px`}
            role='progressbar'
            style={{width: `100%`}}
          ></div>
        </div>
        <div className='d-flex'>
          <button
            type='button'
            className='btn btn-lg btn-primary me-5'
            disabled={!active}
            onClick={()=>setPartner(id)}
          >
              {intl.formatMessage({id: "PARTNER.SELECT"})}
          </button>
          <button
            type='button'
            className='btn btn-lg btn-light-primary me-5'
            disabled={!active || !is_manager}
            onClick={()=>{
              setPartner(id)
              history.push('/partners/settings')
            }}
          >
              {intl.formatMessage({id: "AREA.MANAGEMENT.PARTNER.MEMBER"})}
          </button>
          {/* {!active && (
            <p className='mt-4'>{intl.formatMessage({id: "PARTNER.STATUS.PENDING.MESSAGE"})}</p>
          )} */}
        </div>
        
      </div>
    </div>
  )
}

export {PartnerCard}
