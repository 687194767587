import {MenuItem} from './MenuItem'
import { useIntl } from 'react-intl'
import HasPerm from '../../../../app/modules/auth/redux/HasPerm'

export function MenuInner() {

  const intl = useIntl()
  return (
    <>
        {/* PAGES */}
      <MenuItem 
        title={intl.formatMessage({id: "MENU.DASHBOARD"}).toLocaleUpperCase()}
        to='/partners/dashboard' />
      <MenuItem 
        title={"LISTE"}
        to='/areas' />
      <MenuItem 
        title={"KARTE"}
        to='/map' />
      <HasPerm permission='areas.areaworkflow.change'>
      <MenuItem 
        title={intl.formatMessage({id: "AREA.MANAGEMENT.WORKFLOW_EDITOR"}).toLocaleUpperCase()}
        to='/workflow' />
        </HasPerm>
      <MenuItem 
        title="PERSONEN"
        to='/profiles' />
      <HasPerm permission='areas.areaworkflow.change'>
        <MenuItem 
          title="ADMIN"
          to='/admin' />
      </HasPerm>


      {/* <MenuItem 
            title={intl.formatMessage({id: "PROJECTS.TITLE"}).toLocaleUpperCase()}
            to='/join' /> */}

      {/* <MenuItem title={"MAGAZIN"} to='/blog' /> */}

    </>
  )
}
