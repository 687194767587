//export const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname
export const toAbsoluteUrl = (pathname: string, remote?: boolean) => {
	if(remote) {
		if(pathname.includes('http')) {
			return pathname
		}
	return  process.env.REACT_APP_TPP_API + pathname
	}
	return process.env.PUBLIC_URL + pathname
}

export function isNotEmpty(obj: unknown) {
	return obj !== undefined && obj !== null && obj !== ''
  }

export const API_URL = process.env.REACT_APP_TPP_API
export const PRICE_LIST_PARTNER = process.env.REACT_APP_TPP_PRICE_LIST_PARTNER