/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { faTimes, faTreeAlt, faTruck, faUsers } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import { Component } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { connect } from 'react-redux'
import { RootState } from '../../../setup/redux/Store'
import { AppDispatch } from '../../../setup/redux/Store'
import { API_URL, toAbsoluteUrl } from '../../../_metronic/helpers'
import { Partner } from '../areas/models/AreaModel'
import { addAreaMember, fetchPartnerList } from '../areas/redux/AreaRedux'
import { ProfileModel } from '../auth/models/ProfileModel'

interface WorkflowProps {
  area: string
  show: boolean
  partnerType?: string
  hideModal: ()=>void
}

interface WorkflowState {
  partnerType: string
  partner: string
  partnerMembers: any[]
  partners: Partner[]

}

interface ConnectedProps {
  loading: boolean
  selectedPartner: string
  profile: ProfileModel
}

interface DispatchProps {
  // fetchAreaJobs: typeof fetchAreaJobs
  fetchPartners: typeof fetchPartnerList
  addMember: typeof addAreaMember
}

type Props = ConnectedProps & DispatchProps & WorkflowProps & WrappedComponentProps


class PartnerMemberModal extends Component<Props, WorkflowState> {

  constructor(props:Props){
    super(props);
    this.state = {
      partnerType: "",
      partner: "",
      partnerMembers: [],
      partners: []
    }
  }
  
  componentDidMount(){
    this.fetchPartners()
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<WorkflowState>, snapshot?: any): void {
    if((prevState.partner!==this.state.partner) && this.state.partner){
      this.fetchPartnerMembers()
    }
  }

  partners(){
    return this.state.partners.filter(partner=>partner.partner_type===`${this.state.partnerType}`)
  }

  fetchPartnerMembers(){
    axios.get(API_URL+`/partners/${this.state.partner}/members`).then(
      res => this.setState({partnerMembers: res.data.results})
    )
  }

  fetchPartners(){
    axios.get(API_URL+`/partners?partner=${this.props.selectedPartner}`).then(
      res => this.setState({partners: res.data.results})
    )
  }


  render() {
    return (
      <Modal
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog-centered mw-1000px h-auto'
        show={this.props.show}
        onHide={this.props.hideModal}
      >
        <div className='container-xxl px-10 py-10'>
          <div className='modal-header py-2 d-flex justify-content-end border-0'>
            {/* begin::Close */}
            <div className='btn btn-icon btn-sm btn-light-primary' onClick={this.props.hideModal}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </div>
            {/* end::Close */}
        </div>
  
          <div className='modal-body'>
            {/*begin::Stepper */}
            <div
              className='d-flex flex-column flex-xl-row flex-row-fluid'
            >
              {/*begin::Aside */}
              <div className='d-flex justify-content-center justify-content-xl-start flex-row-auto '>
                
              </div>
              {/*begin::Aside */}
  
              {/*begin::Content */}
              <div className='d-flex flex-row-fluid justify-content-center'>
                {/*begin::Form */}
                <form
                >
                  {/*begin::Step 1 */}
                  <div className='pb-5 current' data-kt-stepper-element='content'>
                    <div className='w-100'>
                      {/*begin::Heading */}
                      <div className='pb-5 pb-lg-10'>
                        <h3 className='fw-bolder text-dark display-6'>{this.props.intl.formatMessage({id: 'AREA.MANAGEMENT.PARTNERS'})}</h3>
                      </div>
                      {/*begin::Heading */}
  
                      {/*begin::Form Group */}
                      <div className='fv-row'>
                        <label className='fs-6 fw-bolder text-dark form-label'>{this.props.intl.formatMessage({id: 'AREA.MANAGEMENT.PARTNER.TYPE'})}</label>
                        <div className='d-flex flex-wrap'>
                       {PartnerTypes.map((partnerType:any)=>(
                          <label
                            className={`btn btn-outline btn-outline-dashed btn-outline-default p-2 d-flex align-items-center mb-10 me-2 ${this.state.partnerType===partnerType.name?"active":""}`}
                            onClick={()=>this.setState({partnerType: partnerType.name, partner: "", partnerMembers: []})}
                          >
     
                          <FontAwesomeIcon icon={partnerType.icon} className="svg-icon-3x me-5" size='3x'/>
                          <span className='d-block fw-bold text-start'>
                            <span className='text-dark fw-bolder d-block fs-4 mb-2'>{this.props.intl.formatMessage({id: `AREA.MANAGEMENT.PARTNERMEMBER.TYPE.${partnerType.name}`})}</span>
                            <span className='text-gray-400 fw-bold fs-6'>
                              {partnerType.description}
                            </span>
                          </span>
                          </label>)
                       )}
                       </div>
                      
                      </div>
                      {this.state.partnerType && (
                        <div className='fv-row mb-12'>
                        <label className='fs-6 fw-bolder text-dark form-label'>Partner</label>
                        <div className='d-flex flex-wrap'>

                        {this.partners().map((partner:Partner)=>(
                          <label
                            className={`btn btn-outline btn-outline-dashed btn-outline-default p-2 d-flex align-items-center mb-2 me-2 ${this.state.partner===partner.id?"active":""}`}
                            onClick={()=>this.setState({partner: partner.id, partnerMembers: []})}
                          >
                          <div className='symbol symbol-50px w-50px bg-light'>
                            <img src={toAbsoluteUrl(partner.logo, true)} alt='partnerLogo' className='p-3' />
                          </div>
                          <span className='d-block fw-bold text-start'>
                            <span className='text-dark fw-bolder d-block fs-4 mb-2'>{partner.name}</span>
                          </span>
                          </label>
                        ))}
                        </div>
                      </div>
                      )}
                      {this.state.partner && (
                        <div className='fv-row mb-12'>
                        <label className='fs-6 fw-bolder text-dark form-label'>{this.props.intl.formatMessage({id: 'AREA.MANAGEMENT.PARTNER.MEMBER'})}</label>
                        <div className='d-flex flex-wrap'>

                        {this.state.partnerMembers.map((member:any)=>(
                          <label
                            className={`btn btn-outline btn-outline-dashed btn-outline-default p-2 d-flex align-items-center mb-2 me-2`}
                            onClick={()=>this.props.addMember(this.props.area, member.id)}
                          >
                          <div className='symbol symbol-50px w-50px bg-light'>
                            <img src={toAbsoluteUrl(member.profile.image, true)} alt='memberImage' className='p-3' />
                          </div>
                          <span className='d-block fw-bold text-start'>
                            <span className='text-dark fw-bolder d-block fs-4 mb-2'>{member.profile.name}</span>
                          </span>
                          </label>
                        ))}
                        </div>
                      </div>
                      )}
                      
                      {/*end::Form Group */}
  
                    </div>
                  </div>
                  {/*end::Step 1 */}
  
                  {/*begin::Actions */}
                  <div className='d-flex justify-content-between pt-10'>
                    <div className='mr-2'>
                  
                    </div>

                  </div>
                  {/*end::Actions */}
                </form>
                {/*end::Form */}
              </div>
              {/*end::Content */}
            </div>
            {/* end::Stepper */}
          </div>
          <div>
  
          </div>
      </Modal>
    )
  }
}


const mapStateToProps = (state: RootState) => {
  return {
      partners: state.areas.partnersList,
      loading: state.areas.partnersListLoading,
      selectedPartner: state.areas.selectedPartner,
      profile: state.auth.profile,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
      fetchPartners: () => dispatch(fetchPartnerList()),
      addMember: (area:string, member:string) => dispatch(addAreaMember(area,member))

  };
};

export default connect<ConnectedProps, DispatchProps>(mapStateToProps, mapDispatchToProps)(injectIntl(PartnerMemberModal));

export const PartnerTypes = [
  {
    name: "MANAGER",
    icon: faTreeAlt,
    description: ""
  },
  {
    name: "AUTHORITY",
    icon: faUsers,
    description: ""
  },
  {
    name: "PROVIDER",
    icon: faTruck,
    description: ""
  },
]