/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { AppDispatch } from '../../../setup/redux/Store';
import {PageTitle} from '../../../_metronic/layout/core'

import { AreaTable, AREA_LIMIT_ALL, AREA_LIMIT_MIN } from '../../../_metronic/partials/widgets/tables/AreaTable';
import { ChangedItem } from '../../modules/areas/components/settings/AreaDetails';
import { AREA_MAP_PROPS, deleteArea, fetchAreas, fetchAreasFilters, fetchPartnerList, updateArea } from '../../modules/areas/redux/AreaRedux';
import { HasPermission } from '../../modules/auth/Utils';
import { _hideModal, _showModal } from '../../modules/modals/redux/ModalRedux';
import { getPartner } from '../../modules/areas/redux/AreaRedux';
import { TableFilters } from '../../modules/areas/models/AreaModel';
import axios from 'axios';
import { API_URL } from '../../../_metronic/helpers';

class DashboardPage extends React.Component<any, any> {

  constructor(props:any) {
    super(props)
    this.state = {
      map_view: this.props.mapView,
      mapProps: {...this.props.areasMapProps, map_view: this.props.mapView},
      kpis : {}
    }
  }

  componentDidMount() {
    axios.get(API_URL+"/kpi").then(res=>this.setState({kpis:res.data}))
    let filterAssigned = false
    let tableFilters:TableFilters = this.props.tableFilters;
    if(this.props.location.search === '?assigned=True'){
      filterAssigned = true
    }
    if(this.props.location.search === '?filter_trees=to_plant'){
      tableFilters.trees = 'to_plant'
    }
    if(this.props.location.search === '?filter_trees=planted'){
      tableFilters.trees = 'planted'
    }
    if(this.props.location.search.includes('?filter_jobs')){
      tableFilters.jobs = this.props.location.search.split("=")[1]
    }
    if(this.props.location.search.includes('?filter_todos')){
      tableFilters.todos = this.props.location.search.split("=")[1]
    }
    if(this.props.location.search.includes('?filter_plots')){
      tableFilters.plots = this.props.location.search.split("=")[1]
    }
    if(this.props.partner){
      filterAssigned = true
    }
    
    if(this.props.partner){
      const partner = getPartner(this.props.selectedPartner, this.props.partners)
      const fetchAll = (partner && partner.partner_type) === "PROVIDER"
      if (fetchAll) {
        filterAssigned = false
      }
    }

    this.props.fetchAreas(
      this.props.areasListPage,
      AREA_LIMIT_MIN,
      filterAssigned,
      "",
      this.props.filterStatus,
      {...AREA_MAP_PROPS, map_view: this.state.map_view},
      [],
      false,
      this.props.partner,
      tableFilters

    );
    this.props.fetchFilters();

  this.props.fetchPartners();

  }

  componentDidUpdate(prevProps:any, prevState:any, snapshot:any) {
    if(prevState.map_view !== this.state.map_view){
      const mapProps = {...this.state.mapProps};
      this.setState({mapProps})

      mapProps.map_view = this.state.map_view;
      if(this.state.map_view) {
        this.props.fetchAreas(
          this.props.areasListPage,
          AREA_LIMIT_ALL,
          this.props.areasListFilter,
          "",
          this.props.filterStatus,
          mapProps,
          [],
          false,
          this.props.partner,
          this.props.tableFilters
        )
      } else {
        this.props.fetchAreas(
          this.props.areasListPage,
          AREA_LIMIT_MIN,
          this.props.areasListFilter,
          "",
          this.props.filterStatus,
          AREA_MAP_PROPS,
          [],
          false,
          this.props.partner,
          this.props.tableFilters
        )
        this.setState({mapProps: AREA_MAP_PROPS})
      }
    }
  }

  render() {
    return(
      <>

    {/* begin::Row */}
    <div className='row gy-5 gx-xl-8'>
      <div className='col-xl-12'>
        {this.props.tableFilters?.workflow && (
        <div className={`card mb-5 mb-xl-8`}>
          <div className='card-header border-0 pt-1'>
            <h3 className='card-title align-items-start flex-column'>Aufgaben Gruppen</h3>
          </div>
          <div className='card-body pt-0'>
            {this.state.kpis.areas_workflow_group && Object.keys(this.state.kpis?.areas_workflow_group[this.props.tableFilters.workflow]).map((group:string)=>(
              <button
                className='btn btn-secondary btn-sm me-2'
                onClick={()=>this.props.fetchAreas(
                  0,
                  AREA_LIMIT_MIN,
                  this.props.areasListFilter,
                  "",
                  "",
                  AREA_MAP_PROPS,
                  [],
                  false,
                  this.props.partner,
                  {...this.props.tableFilters, group}
                )}
                >
                  {(group || "Unbekannt")+" - "+this.state.kpis?.areas_workflow_group[this.props.tableFilters.workflow][group]}
              </button>
            ))}
          </div>
        </div>
        )}

          <AreaTable 
            className='mb-5 mb-xl-8'
            areas={this.props.areas}
            fetchAreas={this.props.fetchAreas}
            loading={this.props.updateLoading || this.props.loading}
            modals={this.props.modals}
            showModal={this.props.showModal}
            hideModal={this.props.hideModal}
            deleteArea={this.props.deleteArea}
            deleteAreaLoading={this.props.deleteAreaLoading}
            count={this.props.areasListCount}
            limit={this.props.areasListLimit}
            page={this.props.areasListPage}
            filter={this.props.areasListFilter}
            filters={this.props.areasFilter}
            filtersLoading={this.props.areasFiltersLoading}
            filterStatus={this.props.filterStatus}
            map_view={this.state.map_view}
            mapProps={this.state.mapProps}
            areaDetails={this.props.areaDetails}
            partner={this.props.partner}
            partners={this.props.partners}
            filterHistory={this.props.filterHistory}
            tableFilters={this.props.tableFilters}
            />
      </div>
    </div>
    {/* end::Row */}
  </>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
      user: state.user,
      modals: state.modals.modals,
      areas: state.areas.areas,
      areasListCount: state.areas.areasListCount,
      areasListPage: state.areas.areasListPage,
      areasListLimit: state.areas.areasListLimit,
      areasListFilter: state.areas.areasListFilter,
      loading: state.areas.areasLoading,
      deleteAreaLoading: state.areas.deleteAreaLoading,
      areasFilter: state.areas.areasFilter,
      areasFiltersLoading: state.areas.areasFilterLoading,
      filterStatus: state.areas.areasListStatus,
      areasMapProps: state.areas.areasMapProps,
      areaDetails: state.areas.areaDetails,
      updateLoading: state.areas.updateLoading,
      partner: state.areas.selectedPartner,
      partners: state.areas.partners,
      filterHistory: state.areas.filterHistory,
      tableFilters: state.areas.tableFilters
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
      fetchAreas: (
        offset:number=0, limit:number=25,
        filter?: boolean, search?:string,
        status?:string, mapProps:any=AREA_MAP_PROPS,
        ids?:string[], ordering?:boolean, partner?:string,
        tableFilters?:TableFilters
        ) => dispatch(fetchAreas(offset, limit, filter, search, status, mapProps, ids, ordering, partner, tableFilters)),
      showModal: (name:string)=>dispatch(_showModal(name)),
      hideModal: (name:string)=>dispatch(_hideModal(name)),
      deleteArea: (area:string)=>dispatch(deleteArea(area)),
      fetchFilters: ()=>dispatch(fetchAreasFilters()),
      updateArea: (area:string, changedItems: ChangedItem[]) => dispatch(updateArea(area, changedItems)),
      fetchPartners: () => dispatch(fetchPartnerList)
  };
};

const DP:any = withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardPage));


const DashboardWrapper: FC = () => {
  const intl = useIntl();
  const history = useHistory();

  if (!HasPermission('areas.area.view')) {
    history.push('/error/403');
    return <></>;
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.AREAS_LIST'})}</PageTitle>
      <DP mapView={false}/>
    </>
  )
}

export {DashboardWrapper}

const MapWrapper: FC = () => {
  const intl = useIntl();
  const history = useHistory();

  if (!HasPermission('areas.area.view')) {
    history.push('/error/403');
    return <></>;
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'AREA.MANAGEMENT.AREAS.MAP'})}</PageTitle>
      <DP mapView={true}/>
    </>
  )
}

export {MapWrapper}

export const getWorkflowStatus = (message:string) => {
  if(!message) {
    return message
  }
  let final_message = message;
  if(message.includes('DECLINED')) final_message = final_message.replace('DECLINED', 'Abgelehnt')
  if(message.includes(':area:')) final_message = final_message.replaceAll(':area:', '📍')
  if(message.includes(':plot:')) final_message = final_message.replaceAll(':plot:', '🌳')
  if(message.includes(':error:')) final_message = final_message.replaceAll(':error:', '⛔')
  return final_message

}