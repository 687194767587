/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { connect } from 'react-redux'
import { AppDispatch, RootState } from '../../../../setup/redux/Store'
import { KTSVG } from '../../../../_metronic/helpers'
import { Workflow } from '../models/AreaModel'
import { createAreaWorkflow, fetchAreaWorkflows } from '../redux/AreaRedux'
import { WorkflowTemplateTable } from './WorkflowTemplateTable'
import { _hideModal, _showModal } from '../../modals/redux/ModalRedux'
import { ModalModel } from '../../modals/models/ModalModel'


interface WorkflowProps {
}


interface ConnectedProps1 {
  workflows: Workflow[]
  createWorkflowLoading: boolean
  modals: ModalModel[]
  loading: boolean
}

interface DispatchProps {
  fetchWorkflows: typeof fetchAreaWorkflows
  createWorkflow: typeof createAreaWorkflow
  hideModal: typeof _hideModal
  showModal: typeof _showModal
}

type Props = ConnectedProps1 & DispatchProps & WorkflowProps & WrappedComponentProps

class WorkflowPage extends React.Component<Props> {

  componentDidMount() {
    this.props.fetchWorkflows()
  }

  render(): React.ReactNode {
    const intl = this.props.intl;
    return (
      <>
        <div className='card mb-10'>
          <div className='card-body d-flex align-items-center py-8'>
            {/* begin::Icon */}
            <div className='d-flex h-80px w-80px flex-shrink-0 flex-center position-relative'>
              <KTSVG
                path='/media/icons/duotune/abstract/abs051.svg'
                className='svg-icon-primary position-absolute opacity-15'
                svgClassName='h-80px w-80px'
              />
              <KTSVG
                path='/media/icons/duotune/coding/cod009.svg'
                className='svg-icon-3x svg-icon-primary position-absolute'
              />
            </div>
            {/* end::Icon */}
  
            {/* begin::Description */}
            <div className='ms-6'>
              <p className='list-unstyled text-gray-600 fw-bold fs-6 p-0 m-0'>
                {intl.formatMessage({id: "AREA.MANAGEMENT.WORKFLOW.DESCRIPTION"})}
              </p>
            </div>
            {/* end::Description */}
          </div>
        </div>
        <div className='card card-custom'>
      
          {/* begin::Form */}
          <form className='form'>
            {/* begin::Body */}
            <div className='card-body'>
              <div className='tab-content pt-3'>
                <div>
                  <WorkflowTemplateTable 
                    className='card-xxl-stretch mb-5 mb-xl-8' 
                    templates={this.props.workflows}
                    createWorkflow={this.props.createWorkflow}
                    createWorkflowLoading={this.props.createWorkflowLoading}
                    modals={this.props.modals}
                    showModal={this.props.showModal}
                    hideModal={this.props.hideModal}
                    loading={this.props.loading}
                    />
                </div>
              </div>
            </div>
            {/* end::Body */}
  
    
          </form>
          {/* end::Form */}
        </div>
      </>
    )
  }
  
}

const mapStateToProps = (state: RootState) => {
  return {
      workflows: state.areas.workflows,
      createWorkflowLoading: state.areas.createWorkflowLoading,
      modals: state.modals.modals,
      loading: state.areas.workflowsLoading
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
      fetchWorkflows: () => dispatch(fetchAreaWorkflows()),
      createWorkflow: (name:string) => dispatch(createAreaWorkflow(name)),
      showModal: (name:string)=>dispatch(_showModal(name)),
      hideModal: (name:string)=>dispatch(_hideModal(name)),

  };
};

export default connect<ConnectedProps1, DispatchProps>(mapStateToProps, mapDispatchToProps)(injectIntl(WorkflowPage));
