import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../../../../setup/redux/Store';
import { ModalModel } from '../models/ModalModel';

export interface ModalState {
	modals: ModalModel[]
}

const initialState: ModalState = {
	modals: []
};

export const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
	showModal: (state: Draft<typeof initialState>, action: PayloadAction<any>) => {
		state.modals.push({
			id: action.payload.modal,
			data: action.payload.data
		});
	},
	hideModal: (state: Draft<typeof initialState>, action: PayloadAction<any>) => {
		state.modals = state.modals.filter(modal => modal.id !== action.payload.modal);
	}
}})

export const _showModal = (modal: string, data: any = null) => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: showModal.type,
			payload: {
				modal,
				data
			}
		});
	};
};

export const _hideModal = (modal: string) => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: hideModal.type,
			payload: {modal}
		});
	};
};

export const getModal = (modal: string, modals: ModalModel[]) => {
	let exists = modals.filter((m: ModalModel) => m.id === modal);

	if (exists.length) {
		return true;
	} else {
		return false;
	}
}

export const getModalData = (modal: string, modals: ModalModel[]) => {
	let foundModal = modals.find((m: ModalModel) => m.id === modal);

	if (foundModal === undefined) {
		return null;
	}

	return foundModal.data;
}

export const {
	showModal, hideModal
} = modalSlice.actions;

export default modalSlice.reducer
