/* eslint-disable jsx-a11y/anchor-is-valid */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {FC, useState} from 'react'
import { faDownload, faEdit } from '@fortawesome/pro-duotone-svg-icons'
import { AreaDocument } from './models/AreaModel'
import { API_URL, toAbsoluteUrl } from '../../../_metronic/helpers'
import { AreaDocumentChange } from './AreaChangeDocument'
import { deleteDocument, renameDocument } from './redux/AreaRedux'

type Props = {
  icon: string
  title: string
  description: string
  document: AreaDocument
  loading: boolean
  handleRename: typeof renameDocument
  handleDelete: typeof deleteDocument
  link?: string
}

const AreaDocumentCard: FC<Props> = ({icon, title, description, link, document, loading, handleRename, handleDelete}) => {
  const [ show, setShow ] = useState(false);
  return (
    <div className='card h-100'>
      <div className='d-flex justify-content-between'>
        <div><AreaDocumentChange 
          show={show} 
          handleClose={()=>setShow(false)} 
          document={document}
          loading={loading}
          handleRename={handleRename}
          handleDelete={handleDelete}/></div>
        <div>
          <div 
          style={{marginRight: "0.5em", cursor: "pointer"}}
          onClick={()=>setShow(true)}
          >
            <FontAwesomeIcon icon={faEdit} />
        </div>
        <div 
          style={{marginRight: "0.5em", marginTop: "0.5em", cursor: "pointer"}}
          onClick={()=>window.location.assign(`${API_URL}/documents/${document.id}/download`)}
          >
            <FontAwesomeIcon icon={faDownload} />
        </div>
        </div>
        
        
        </div>
      <div className='card-body d-flex jussstify-content-center text-center flex-column p-8'>
        
        <a href={link||""} target="_blank" rel="noreferrer" className='text-gray-800 text-hover-primary d-flex flex-column'>
          <div className='symbol symbol-75px mb-6'>
            <img src={toAbsoluteUrl(icon)} alt='' />
          </div>
          <div className='fs-5 fw-bolder mb-2'>{title}</div>
        </a>
        <div className='fs-7 fw-bold text-gray-400 mt-auto'>{description}</div>
      </div>
    </div>
  )
}

export {AreaDocumentCard}
