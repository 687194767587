import React, {useEffect, useState} from 'react'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {Toolbar} from './components/toolbar/Toolbar'
import {Content} from './components/Content'
import {PageDataProvider, useLayout} from './core'
import {useLocation} from 'react-router-dom'
import {MenuComponent} from '../../_metronic/assets/ts/components'
import clsx from 'clsx'
import AreaSearchModal from '../../app/modules/areas/components/AreaSearchModal'
import { Jira } from './components/Jira'

const MasterLayout: React.FC = ({children}) => {
  const {classes} = useLayout()
  const [showSearch, setShowSearch] = useState(false);
  const keydownHandler = (e:any)=>{
    if(e.keyCode===13 && e.ctrlKey) setShowSearch(true)
  }
  document.addEventListener('keydown',keydownHandler);
  
  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
    
  },)

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <HeaderWrapper setShowSearch={()=>setShowSearch(true)}/>

          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <Toolbar />
            <div
              className={clsx(
                'd-flex flex-column-fluid align-items-start',
                classes.contentContainer.join(' ')
              )}
              id='kt_post'
            >
              <AreaSearchModal
                show={showSearch}
                hideModal={()=>setShowSearch(false)}
                />
              <AsideDefault />
              <Content>{children}</Content>
            </div>
          </div>
          <Footer />
        </div>
      </div>

      <Jira />
    </PageDataProvider>
  )
}

export {MasterLayout}
