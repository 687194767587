/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import { Component } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { connect } from 'react-redux'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { RootState } from '../../../setup/redux/Store'
import { AppDispatch } from '../../../setup/redux/Store'
import { API_URL } from '../../../_metronic/helpers'
import { AddPartnerMember } from '../areas/components/dashboard/AddPartnerMember'
import { PartnerMembers } from '../areas/components/dashboard/PartnerMembers'
import { PartnerMember } from '../areas/models/AreaModel'
import { fetchPartnerMembers } from '../areas/redux/AreaRedux'

interface WorkflowProps {
  // area: string
}

interface WorkflowState {

}

interface ConnectedProps {
  selectedPartner: string
  partnerMembers: PartnerMember[]
}

interface DispatchProps {
  fetchPartnerMembers: typeof fetchPartnerMembers
}

type Props = ConnectedProps & DispatchProps & WorkflowProps & WrappedComponentProps & RouteComponentProps


class PartnerManage extends Component<Props, WorkflowState> {
  
  componentDidMount(){
    if(!this.props.selectedPartner) {
      this.props.history.push('/partners')
    }
    this.props.fetchPartnerMembers(this.props.selectedPartner)
  }

  addPartnerMember = (profile:string) => {
    axios.post(API_URL+"/partners/"+this.props.selectedPartner+"/members", {partner:this.props.selectedPartner, profile: parseInt(profile), status: 1}).then(()=>{
      this.props.fetchPartnerMembers(this.props.selectedPartner)
    })
  }

  deletePartnerMember = (member:string) => {
    axios.delete(API_URL+"/partners/"+this.props.selectedPartner+"/members/"+member).then(()=>{
      this.props.fetchPartnerMembers(this.props.selectedPartner)
    })
  }


  render() {
    return (
      <>
        <div className='d-flex flex-wrap flex-stack mb-6'>
          <h3 className='fw-bolder my-2'>
            
            {/* <span className='fs-6 text-gray-400 fw-bold ms-1'>Aktive</span> */}
          </h3>

          <div className='d-flex flex-wrap my-2'>
            {/* <HasPerm permission='areas.areajob.add'> */}
              <Link
                to={`/partners/dashboard`}
                className='btn btn-primary btn-sm'
              >
                {this.props.intl.formatMessage({id: "AUTH.GENERAL.BACK_BUTTON"})}
              </Link>
            {/* </HasPerm> */}
          </div>
        </div>

        <div className='row g-6 g-xl-9 mt-6'>
          <div className='col-md-6'>
          <PartnerMembers className='' members={this.props.partnerMembers} removePartnerMember={this.deletePartnerMember}/>

          </div>
          <div className='col-md-6'>
          <AddPartnerMember className='' addPartnerMember={this.addPartnerMember} removePartnerMember={this.deletePartnerMember} members={this.props.partnerMembers}/>

          </div>
        </div>
        <div className='row g-6 g-xl-9 mt-6'>
          <div className='col-md-7'>
          </div>
          <div className='col-md-5'>
          </div>
        </div>
      </>
    )
  }
}


const mapStateToProps = (state: RootState) => {
  return {
      selectedPartner: state.areas.selectedPartner,
      partnerMembers: state.areas.partnerMembers
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
      fetchPartnerMembers: (partner:string) => dispatch(fetchPartnerMembers(partner))
  };
};

export default withRouter(connect<ConnectedProps, DispatchProps>(mapStateToProps, mapDispatchToProps)(injectIntl(PartnerManage)));
