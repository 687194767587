/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useIntl } from 'react-intl'
import { AssignAreas } from '../../models/AreaModel'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-duotone-svg-icons'
import { addAreaMember } from '../../redux/AreaRedux'

type Props = {
  className: string
  areas: AssignAreas[]
  addMember: typeof addAreaMember
  member: string
}

const PartnerAreas: React.FC<Props> = ({className, areas, addMember, member}) => {
  const intl = useIntl();


  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>{intl.formatMessage({id: "AREA.MANAGEMENT.AREAS"})}</span>

          <span className='text-muted fw-bold fs-7'>{areas.length} {intl.formatMessage({id: "AREA.MANAGEMENT.AREAS.UNASSIGNED"})}</span>
        </h3>


      </div>
      <div className='card-body pt-3'>
        {areas.map(area=>(
          <div className='d-flex align-items-sm-center mb-7'>
            <div className='symbol symbol-60px symbol-2by3 me-4'>
            <div
              className='symbol-label'
              style={{backgroundImage: `url(${toAbsoluteUrl(area.image, true)})`}}
            ></div>
          </div>
            <div className='d-flex flex-row-fluid flex-wrap align-items-center'>
              <div className='flex-grow-1'>
                {area.name}
                <span className='text-muted fw-bold d-block pt-1'>{area.locations.length ? area.locations[0].location_mark:area.name}</span>
              </div>
              <span className='text-muted fw-bold d-block pt-1 me-10'>{area.size} ha</span>
              <span className={`badge badge-light-info fs-8 fw-bolder my-2`} style={{marginRight: "1em"}}>{area.distance} km</span>
              <button 
                className={`btn btn-success badge badge-success fs-8 fw-bolder my-2`}
                onClick={()=>addMember(area.id, member)}
                >
                  <FontAwesomeIcon icon={faCheck}/> {intl.formatMessage({id: "AREA.MANAGEMENT.AREAS.ASSIGN"})}
                </button>
            </div>
          </div>
        ))}
        {!areas.length && (
          <span className='text-muted fw-bold d-block pt-1'>{intl.formatMessage({id: "AREAS.JOBS.NO_AREAS"})}</span>
        )}
        
      </div>
    </div>
  )
}

export {PartnerAreas}