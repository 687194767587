/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { connect } from 'react-redux'
import { RootState, AppDispatch } from '../../../../../setup/redux/Store'
import HasPerm from '../../../auth/redux/HasPerm'
import { ModalModel } from '../../../modals/models/ModalModel'
import { getModal, _hideModal, _showModal } from '../../../modals/redux/ModalRedux'
import { AreaJob } from '../../models/AreaModel'
import { fetchAreaJobs, updateJob } from '../../redux/AreaRedux'
import CreateJobModal from './CreateJobModal'
import { JobList } from './JobList'

interface WorkflowProps {
  area: string
}

interface WorkflowState {

}

interface ConnectedProps {
  jobs: AreaJob[]
  loading: boolean
  modals: ModalModel[]
}

interface DispatchProps {
  fetchAreaJobs: typeof fetchAreaJobs
  updateJob: typeof updateJob
  hideModal: typeof _hideModal
  showModal: typeof _showModal

}

type Props = ConnectedProps & DispatchProps & WorkflowProps & WrappedComponentProps


class Jobs extends Component<Props, WorkflowState> {
  
  componentDidMount(){
    this.props.fetchAreaJobs(this.props.area)
  }

  render() {
    return (
      <>
        <div className='d-flex flex-wrap flex-stack mb-6'>
          <h3 className='fw-bolder my-2'>
            
            {/* <span className='fs-6 text-gray-400 fw-bold ms-1'>Aktive</span> */}
          </h3>

          <div className='d-flex flex-wrap my-2'>
            {/* <div className='me-4'>
              <select
                name='status'
                data-control='select2'
                data-hide-search='true'
                className='form-select form-select-sm form-select-white w-125px'
                defaultValue='Active'
              >
                <option value='Active'>Active</option>
                <option value='Approved'>In Progress</option>
                <option value='Declined'>To Do</option>
                <option value='In Progress'>Completed</option>
              </select>
            </div> */}
            <HasPerm permission='areas.areajob.add'>
              <button
                onClick={()=>this.props.showModal('create-job')}
                className='btn btn-primary btn-sm'
              >
                Neuer Auftrag
              </button>
            </HasPerm>
          </div>
        </div>

        <div className='row g-6 g-xl-9'>
          <JobList 
            className='mb-5 mb-xl-8'
            jobs={this.props.jobs}
            updateJob={this.props.updateJob}
            showModal={()=>this.props.showModal('create-job')} 
            loading={this.props.loading}
            />
          <CreateJobModal 
            area={this.props.area}
            show={getModal('create-job', this.props.modals)} 
            hideModal={()=>this.props.hideModal('create-job')}
          />
          {/* {this.props.jobs.map((job: AreaJob, index: number) => (
            <div key={index} className='col-md-6 col-xl-4'>
              <Link to={`/area/${this.props.area}/jobs/${job.id}`} 
                className='card border border-2 border-gray-300 border-hover'>
                  <JobCard
                    icon={getJobIcon(job.name)}
                    badgeColor={StatusMapping[job.status-1].badge}
                    status={this.props.intl.formatMessage({id: StatusMapping[job.status-1].name})}
                    statusColor={StatusMapping[job.status-1].badge}
                    title={job.name}
                    description={job.description}
                    date={new Date(job.due_date).toLocaleDateString(this.props.intl.locale)}
                    totalCosts={getTotalCost(job)}
                  />
              </Link>
            </div>
          ))} */}
        </div>
      </>
    )
  }
}

export const StatusMapping = [
  {
    name: "AREA.MANAGEMENT.JOB.STATUS.OPENED",
    badge: "info"
  },
  {
    name: "AREA.MANAGEMENT.JOB.STATUS.ASSIGNED",
    badge: "warning"
  },
  {
    name: "AREA.MANAGEMENT.JOB.STATUS.IN_PROGRESS",
    badge: "warning"
  },
  {
    name: "AREA.MANAGEMENT.JOB.STATUS.DONE",
    badge: "success"
  },
  {
    name: "AREA.MANAGEMENT.JOB.STATUS.CANCELED",
    badge: "danger"
  },
]



const mapStateToProps = (state: RootState) => {
  return {
      jobs: state.areas.jobs,
      loading: state.areas.jobsLoading,
      modals: state.modals.modals,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {

      fetchAreaJobs: (area: string) => dispatch(fetchAreaJobs(area)),
      updateJob: (area:string, job:string, updated:any)=>dispatch(updateJob(area, job, updated)),
      showModal: (name: string) => dispatch(_showModal(name)),
      hideModal: (name: string) => dispatch(_hideModal(name)),

  };
};

export default connect<ConnectedProps, DispatchProps>(mapStateToProps, mapDispatchToProps)(injectIntl(Jobs));
