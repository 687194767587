
import { Action, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../../../../setup/redux/Store';
import { PartnerComment } from '../../auth/models/ProfileModel';
import { _hideModal } from '../../modals/redux/ModalRedux';
import { ChangedItem } from '../components/settings/AreaDetails';
import { AreaTodo, AreaDocument, AreaFunding, AreaFundingTag, AreaJob, AreaJobTemplate, AreaManager, AreaModel, AreaOwnerDetails, AreaPlot, AreasFilter, AreaWorkflowPOC, AreaWorkflowStageTemplate, AreaWorkflowStepDataTemplate, AreaWorkflowStepTemplate, ForestRanger, Partner, ValueMapping, Workflow, AssignAreas, PartnerMember, TableFilters, JobFilters } from '../models/AreaModel';

export const AREA_MAP_PROPS = {
	center: {
		lat: 49.41569,
		lng: 10.62551
	},
	zoom: 10,
	map_view: false,
}

export interface AreaState {
	areasLoading: boolean
	areas: AreaModel[]
	updateStepOrderLoading: boolean
	documents: AreaDocument[]
	documentsLoading: boolean
	uploadAreaDocumentLoading: boolean
	jobTemplatesLoading: boolean
	jobTemplates: AreaJobTemplate[]
	documentActionLoading: boolean
	updateNotesLoading: boolean
	workflowsLoading: boolean
	workflows: Workflow[]
	createWorkflowLoading: boolean
	areaWorkflowStageTemplatesLoading: boolean
	areaWorkflowStageTemplates: AreaWorkflowStageTemplate[]
	areaWorkflowStepTemplatesLoading: boolean
	areaWorkflowStepTemplates: AreaWorkflowStepTemplate[]
	stepDataTemplates: AreaWorkflowStepDataTemplate[]
	areaWorkflowStepDataTemplatesLoading: boolean
	createTemplateStageLoading: boolean
	createTemplateStepLoading: boolean
	updateTemplateStageLoading: boolean
	updateTemplateStepLoading: boolean
	deleteTemplateStepLoading: boolean
	deleteTemplateStageLoading: boolean
	areaManagers: AreaManager[]
	areaManagersLoading: boolean
	areaManagerLoading: boolean
	fetchAreaForestRangerLoading: boolean
	forestRangers: ForestRanger[]
	areaWorkflowPoc: AreaWorkflowPOC[]
	areaWorkflowPocLoading: boolean
	plotWorkflowPoc: AreaWorkflowPOC[]
	plotWorkflowPocLoading: boolean
	updateLoading: boolean
	createPlotLoading: boolean
	plotsLoading: boolean
	plots: AreaPlot[]
	plotLoading: boolean
	jobs: AreaJob[]
	jobsLoading: boolean
	jobPriceRecordLoading: boolean
	valueMappingLoading: boolean
	fundingsLoading: boolean
	fundings: AreaFunding[]
	createFundingLoading: boolean
	updateFundingLoading: boolean
	updateJobLoading: boolean
	partners: Partner[]
	partnersLoading: boolean
	deleteAreaLoading: boolean
	areasManagersLoading: boolean
	fetchAreaOwnerLoading: boolean
	ownerDetails: AreaOwnerDetails[]
	areasListPage: number
	areasListCount: number
	areasListLimit: number
	areasListFilter: boolean
	areasFilter: AreasFilter
	areasFilterLoading: boolean
	areasListStatus: string
	areasMapProps: any
	areaDetails: AreaModel[]
	areasTodos: AreaTodo[]
	areasTodosLoading: boolean
	areaTodosLoading: boolean
	areaTodos: AreaTodo[]
	partnerJobsLoading: boolean
	partnerJobs: AreaJob[]
	partnersList: Partner[]
	partnersListLoading: boolean
	selectedPartner: string
	jobMemberLoading: boolean
	assignAreas: AssignAreas[]
	assignAreasLoading: boolean
	areaPartnersLoading: boolean
	areaPartners: any,
	fetchPartnerMembersLoading: boolean
	partnerMembers: PartnerMember[]
	editAreaOwnerLoading: boolean
	fetchPartnerTodosLoading: boolean,
	partnerTodos: AreaTodo[],
	fetchTodoCommentsLoading: boolean
	todoComments: PartnerComment[]
	areaComments: PartnerComment[]
	areaCommentsLoading: boolean
	jobCommentsLoading: boolean
	jobComments: PartnerComment[]
	filterHistory: string[]
	partnerServicesLoading: boolean
	partnerServices: any[]
	addPartneLoading: boolean
	tableFilters: TableFilters
	jobFilters: JobFilters
}

const initialState: AreaState = {
	areasLoading: false,
	areas: [],
	updateStepOrderLoading: false,
	documents: [],
	documentsLoading: false,
	uploadAreaDocumentLoading: false,
	jobTemplatesLoading: false,
	jobTemplates: [],
	documentActionLoading: false,
	updateNotesLoading: false,
	workflowsLoading: false,
	workflows: [],
	createWorkflowLoading: false,
	areaWorkflowStageTemplatesLoading: false,
	areaWorkflowStageTemplates: [],
	areaWorkflowStepTemplates: [],
	areaWorkflowStepTemplatesLoading: false,
	stepDataTemplates: [],
	areaWorkflowStepDataTemplatesLoading: false,
	createTemplateStageLoading: false,
	createTemplateStepLoading: false,
	updateTemplateStageLoading: false,
	updateTemplateStepLoading: false,
	deleteTemplateStepLoading: false,
	deleteTemplateStageLoading: false,
	areaManagersLoading: false,
	areaManagerLoading: false,
	areaManagers: [],
	fetchAreaForestRangerLoading: false,
	forestRangers: [],
	areaWorkflowPoc: [],
	areaWorkflowPocLoading: false,
	plotWorkflowPoc: [],
	plotWorkflowPocLoading: false,
	updateLoading: false,
	createPlotLoading: false,
	plotsLoading: false,
	plots: [],
	plotLoading: false,
	jobs: [],
	jobsLoading: false,
	jobPriceRecordLoading: false,
	valueMappingLoading: false,
	fundingsLoading: false,
	fundings: [],
	createFundingLoading: false,
	updateFundingLoading: false,
	updateJobLoading: false,
	partners: [],
	partnersLoading: false,
	deleteAreaLoading: false,
	areasManagersLoading: false,
	fetchAreaOwnerLoading: false,
	ownerDetails: [],
	areasListCount: 0,
	areasListPage: 0,
	areasListLimit: 25,
	areasListFilter: false,
	areasFilter: {
		"status": [],
		"workflow": []
	},
	areasFilterLoading: false,
	areasListStatus: '',
	areasMapProps: AREA_MAP_PROPS,
	areaDetails: [],
	areasTodos: [],
	areasTodosLoading: false,
	areaTodosLoading: false,
	areaTodos: [],
	partnerJobsLoading: false,
	partnerJobs: [],
	partnersList: [],
	partnersListLoading: false,
	selectedPartner: '',
	jobMemberLoading: false,
	assignAreas: [],
	assignAreasLoading: false,
	areaPartnersLoading: false,
	areaPartners: {},
	fetchPartnerMembersLoading: false,
	partnerMembers: [],
	editAreaOwnerLoading: false,
	fetchPartnerTodosLoading: false,
	partnerTodos: [],
	fetchTodoCommentsLoading: false,
	todoComments: [],
	areaComments: [],
	areaCommentsLoading: false,
	jobCommentsLoading: false,
	jobComments: [],
	filterHistory: [],
	partnerServicesLoading: false,
	partnerServices: [],
	addPartneLoading: false,
	tableFilters: {},
	jobFilters: {
		all: true,
	}
};

export const areaSlice = createSlice({
	name: 'area',
	initialState,
	reducers: {
		fetchAreasLoading: (state: Draft<typeof initialState>) => {
			state.areasLoading = true;
			state.documentActionLoading = false
			state.updateLoading = false
		},
		fetchAreasSuccess: (state: Draft<typeof initialState>, action: PayloadAction<any>) => {
			state.areasLoading = false;

			if (action.payload.target.ids && action.payload.target.ids.length) {
				action.payload.results.map((area: AreaModel) => {
					const index = state.areas.findIndex(_area => String(_area.id) === String(area.id));
					const detailIndex = state.areaDetails.findIndex(_area => String(_area.id) === String(area.id));
					if (index === -1) {
						state.areas.push(area);
					} else {
						state.areas[index] = area;
					}
					if (detailIndex === -1) {
						state.areaDetails.push(area);
					} else {
						state.areaDetails[detailIndex] = area;
					}
					return area
				})
				return
			}
			state.areasMapProps = action.payload.target.mapProps;
			state.areas = action.payload.results;
			state.areasListCount = action.payload.count;
			state.areasListPage = action.payload.target.offset;
			state.areasListLimit = action.payload.target.limit;
			state.areasListFilter = action.payload.target.filter;
			state.areasListStatus = action.payload.target.status;
			state.tableFilters = action.payload.target.tableFilters
			if(action.payload.target.status){
				state.filterHistory.push(action.payload.target.status)
			}
		},
		fetchAreasFail: (state: Draft<typeof initialState>) => {
			state.areasLoading = false;
		},
		updateStepOrderLoading: (state: Draft<typeof initialState>) => {
			state.updateStepOrderLoading = true;
		},
		updateStepOrderSuccess: (state: Draft<typeof initialState>) => {
			state.updateStepOrderLoading = false;
		},
		updateStepOrderFail: (state: Draft<typeof initialState>) => {
			state.updateStepOrderLoading = false;
		},
		fetchAreaDocumentsLoading: (state: Draft<typeof initialState>) => {
			state.documentsLoading = true;
		},
		fetchAreaDocumentsSuccess: (state: Draft<typeof initialState>, action: PayloadAction<any>) => {
			state.documentsLoading = false;
			state.documents = action.payload.results
		},
		fetchAreaDocumentsFail: (state: Draft<typeof initialState>) => {
			state.documentsLoading = false;
		},
		uploadAreaDocumentLoading: (state: Draft<typeof initialState>) => {
			state.uploadAreaDocumentLoading = true;
		},
		uploadAreaDocumentSuccess: (state: Draft<typeof initialState>) => {
			state.uploadAreaDocumentLoading = false;
		},
		uploadAreaDocumentFail: (state: Draft<typeof initialState>) => {
			state.uploadAreaDocumentLoading = false;
		},
		jobTemplatesLoading: (state: Draft<typeof initialState>) => {
			state.jobTemplatesLoading = true;
		},
		jobTemplatesSuccess: (state: Draft<typeof initialState>, action: PayloadAction<any>) => {
			state.jobTemplatesLoading = false;
			state.jobTemplates = action.payload.results
		},
		jobTemplatesFail: (state: Draft<typeof initialState>) => {
			state.jobTemplatesLoading = false;
		},
		documentActionLoading: (state: Draft<typeof initialState>) => {
			state.documentActionLoading = true;
		},
		documentActionSuccess: (state: Draft<typeof initialState>) => {
			state.documentActionLoading = false;
		},
		documentActionFail: (state: Draft<typeof initialState>) => {
			state.documentActionLoading = false;
		},
		updateNotesLoading: (state: Draft<typeof initialState>) => {
			state.updateNotesLoading = false;
		},
		updateNotesSuccess: (state: Draft<typeof initialState>) => {
			state.updateNotesLoading = false;
		},
		updateNotesFail: (state: Draft<typeof initialState>) => {
			state.updateNotesLoading = false;
		},
		fetchWorkflowsLoading: (state: Draft<typeof initialState>) => {
			state.workflowsLoading = true;
		},
		fetchWorkflowsSuccess: (state: Draft<typeof initialState>, action: PayloadAction<any>) => {
			state.workflowsLoading = false;
			state.workflows = action.payload.results
		},
		fetchWorkflowsFail: (state: Draft<typeof initialState>) => {
			state.workflowsLoading = false;
		},
		createWorkflowLoading: (state: Draft<typeof initialState>) => {
			state.createWorkflowLoading = true;
		},
		createWorkflowSuccess: (state: Draft<typeof initialState>) => {
			state.createWorkflowLoading = false;
		},
		createWorkflowFail: (state: Draft<typeof initialState>) => {
			state.createWorkflowLoading = false;
		},
		fetchTemplateStagesLoading: (state: Draft<typeof initialState>) => {
			state.areaWorkflowStageTemplatesLoading = true;
		},
		fetchTemplateStagesSuccess: (state: Draft<typeof initialState>, action: PayloadAction<any>) => {
			state.areaWorkflowStageTemplates = action.payload.results;
			state.areaWorkflowStageTemplatesLoading = false;
		},
		fetchTemplateStagesFail: (state: Draft<typeof initialState>) => {
			state.areaWorkflowStageTemplatesLoading = false;
		},
		fetchTemplateStepsLoading: (state: Draft<typeof initialState>) => {
			state.areaWorkflowStepTemplatesLoading = true;
		},
		fetchTemplateStepsSuccess: (state: Draft<typeof initialState>, action: PayloadAction<any>) => {
			action.payload.results.forEach((step: AreaWorkflowStepTemplate) => {
				const index = state.areaWorkflowStepTemplates.findIndex(_step => _step && _step.id === step.id);
				index === -1 ? state.areaWorkflowStepTemplates = [...state.areaWorkflowStepTemplates, step] : state.areaWorkflowStepTemplates[index] = step;

			})
			state.areaWorkflowStepTemplatesLoading = false;
		},
		fetchTemplateStepsFail: (state: Draft<typeof initialState>) => {
			state.areaWorkflowStepTemplatesLoading = false;
		},
		fetchAreaStepDataTemplatesLoading: (state: Draft<typeof initialState>) => {
			state.areaWorkflowStepDataTemplatesLoading = true;
		},
		fetchAreaStepDataTemplatesSuccess: (state: Draft<typeof initialState>, action: PayloadAction<any>) => {
			action.payload.results.forEach((step: AreaWorkflowStepDataTemplate) => {
				const index = state.stepDataTemplates.findIndex(_step => _step.id === step.id);
				index === -1 ? state.stepDataTemplates.push(step) : state.stepDataTemplates[index] = step;
			})

			state.areaWorkflowStepDataTemplatesLoading = false;
		},
		fetchAreaStepDataTemplatesFail: (state: Draft<typeof initialState>) => {
			state.areaWorkflowStepDataTemplatesLoading = false;
		},
		createTemplateStageLoading: (state: Draft<typeof initialState>) => {
			state.createTemplateStageLoading = true;
		},
		createTemplateStageSuccess: (state: Draft<typeof initialState>) => {
			state.createTemplateStageLoading = false;
		},
		createTemplateStageFail: (state: Draft<typeof initialState>) => {
			state.createTemplateStageLoading = false;
		},
		createTemplateStepLoading: (state: Draft<typeof initialState>) => {
			state.createTemplateStepLoading = true;
		},
		createTemplateStepSuccess: (state: Draft<typeof initialState>) => {
			state.createTemplateStepLoading = false;
		},
		createTemplateStepFail: (state: Draft<typeof initialState>) => {
			state.createTemplateStepLoading = false;
		},
		updateTemplateStageLoading: (state: Draft<typeof initialState>) => {
			state.updateTemplateStageLoading = true;
		},
		updateTemplateStageSuccess: (state: Draft<typeof initialState>) => {
			state.updateTemplateStageLoading = false;
		},
		updateTemplateStageFail: (state: Draft<typeof initialState>) => {
			state.updateTemplateStageLoading = false;
		},
		updateTemplateStepLoading: (state: Draft<typeof initialState>) => {
			state.updateTemplateStepLoading = true;
		},
		updateTemplateStepSuccess: (state: Draft<typeof initialState>) => {
			state.updateTemplateStepLoading = false;
		},
		updateTemplateStepFail: (state: Draft<typeof initialState>) => {
			state.updateTemplateStepLoading = false;
		},
		deleteTemplateStepLoading: (state: Draft<typeof initialState>) => {
			state.deleteTemplateStepLoading = true;
		},
		deleteTempalteStepSuccess: (state: Draft<typeof initialState>, action: PayloadAction<any>) => {
			state.areaWorkflowStepTemplates.filter(step => step.id !== action.payload.target)

			state.deleteTemplateStepLoading = false;
		},
		deleteTemplateStepFail: (state: Draft<typeof initialState>) => {
			state.deleteTemplateStepLoading = false;
		},
		deleteTemplateStageLoading: (state: Draft<typeof initialState>) => {
			state.deleteTemplateStageLoading = true;
		},
		deleteTemplateStageSuccess: (state: Draft<typeof initialState>) => {
			state.deleteTemplateStageLoading = false;
		},
		deleteTemplateStageFail: (state: Draft<typeof initialState>) => {
			state.deleteTemplateStageLoading = false;
		},
		fetchAreaManagersLoading: (state: Draft<typeof initialState>) => {
			state.areaManagersLoading = true;
		},
		fetchAreaManagersSuccess: (state: Draft<typeof initialState>, action: PayloadAction<any>) => {
			state.areaManagersLoading = false;
			state.areaManagers = action.payload
		},
		fetchAreaManagersFail: (state: Draft<typeof initialState>) => {
			state.areaManagersLoading = false;
		},
		fetchAreasManagersLoading: (state: Draft<typeof initialState>) => {
			state.areasManagersLoading = true;
		},
		fetchAreasManagersSuccess: (state: Draft<typeof initialState>, action: PayloadAction<any>) => {
			state.areasManagersLoading = false;
			const index = state.areas.findIndex(area => String(area.id) === String(action.payload.target));
			const indexDetails = state.areaDetails.findIndex(area => String(area.id) === String(action.payload.target));

			if (index !== -1) {
				state.areas[index].forest_farmers = action.payload.results;
			}
			if (indexDetails !== -1) {
				state.areaDetails[indexDetails].forest_farmers = action.payload.results;
			}
		},
		fetchAreasManagersFail: (state: Draft<typeof initialState>) => {
			state.areasManagersLoading = false;
		},
		areaManagerLoading: (state: Draft<typeof initialState>) => {
			state.areaManagerLoading = true;
		},
		areaManagerSuccess: (state: Draft<typeof initialState>) => {
			state.areaManagerLoading = false;
		},
		areaManagerFail: (state: Draft<typeof initialState>) => {
			state.areaManagerLoading = false;
		},
		fetchAreaForestRangerLoading: (state: Draft<typeof initialState>) => {
			state.fetchAreaForestRangerLoading = true;
		},
		fetchAreaForestRangerSuccess: (state: Draft<typeof initialState>, action: PayloadAction<any>) => {
			state.fetchAreaForestRangerLoading = false;
			action.payload.results.forEach((ranger: ForestRanger) => {
				const index = state.forestRangers.findIndex(_ranger => _ranger.area === action.payload.target);
				index === -1 ? state.forestRangers.push({ ...ranger, area: action.payload.target }) : state.forestRangers[index] = { ...ranger, area: action.payload.target };
			})

			state.areaWorkflowStepDataTemplatesLoading = false;
		},
		fetchAreaForestRangerFail: (state: Draft<typeof initialState>) => {
			state.fetchAreaForestRangerLoading = false;
		},
		fetchAreaWorkflowPocLoading: (state: Draft<typeof initialState>) => {
			state.areaWorkflowPocLoading = true;
		},
		fetchAreaWorkflowPocSuccess: (state: Draft<typeof initialState>, action: PayloadAction<any>) => {
			const index = state.areaWorkflowPoc.findIndex(workflow => String(workflow.ref) === `area_${action.payload.target}`);
			index === -1 ? state.areaWorkflowPoc.push(action.payload) : state.areaWorkflowPoc[index] = action.payload;
			state.areaWorkflowPocLoading = false;
		},
		fetchAreaWorkflowPocFail: (state: Draft<typeof initialState>) => {
			state.areaWorkflowPocLoading = false;
		},
		fetchPlotWorkflowPocLoading: (state: Draft<typeof initialState>) => {
			state.plotWorkflowPocLoading = true;
		},
		fetchPlotWorkflowPocSuccess: (state: Draft<typeof initialState>, action: PayloadAction<any>) => {
			const index = state.plotWorkflowPoc.findIndex(workflow => String(workflow.ref) === `plot_${action.payload.target}`);
			index === -1 ? state.plotWorkflowPoc.push(action.payload) : state.plotWorkflowPoc[index] = action.payload;
			state.plotWorkflowPocLoading = false;
		},
		fetchPlotWorkflowPocFail: (state: Draft<typeof initialState>) => {
			state.plotWorkflowPocLoading = false;
		},
		updateAreaLoading: (state: Draft<typeof initialState>) => {
			state.updateLoading = true;
		},
		updateAreaSuccess: (state: Draft<typeof initialState>) => {
			state.updateLoading = false;
		},
		updateAreaFail: (state: Draft<typeof initialState>) => {
			state.updateLoading = false;
		},
		createPlotLoading: (state: Draft<typeof initialState>) => {
			state.createPlotLoading = true;
		},
		createPlotSuccess: (state: Draft<typeof initialState>) => {
			state.createPlotLoading = false;
		},
		createPlotFail: (state: Draft<typeof initialState>) => {
			state.createPlotLoading = false;
		},
		fetchPlotsLoading: (state: Draft<typeof initialState>) => {
			state.plotsLoading = true;
		},
		fetchPlotsSuccess: (state: Draft<typeof initialState>, action: PayloadAction<any>) => {

			state.plots = action.payload.results
			state.plotsLoading = false;
		},
		fetchPlotsFail: (state: Draft<typeof initialState>) => {
			state.plotsLoading = false;
		},
		changePlotLoading: (state: Draft<typeof initialState>) => {
			state.plotLoading = true;
		},
		changePlotSuccess: (state: Draft<typeof initialState>, action: PayloadAction<any>) => {
			state.plotLoading = false;
		},
		changePlotFail: (state: Draft<typeof initialState>) => {
			state.plotLoading = false;
		},
		fetchJobsLoading: (state: Draft<typeof initialState>) => {
			state.jobsLoading = true;
		},
		fetchJobsSuccess: (state: Draft<typeof initialState>, action: PayloadAction<any>) => {

			state.jobs = action.payload.results;
			state.jobsLoading = false;
		},
		fetchJobsFail: (state: Draft<typeof initialState>) => {
			state.jobsLoading = false;
		},
		deletePlotLoading: (state: Draft<typeof initialState>) => {
			state.plotLoading = true;
		},
		deletePlotSuccess: (state: Draft<typeof initialState>, action: PayloadAction<any>) => {

			state.plotLoading = false;
		},
		deletePlotFail: (state: Draft<typeof initialState>) => {
			state.plotLoading = false;
		},
		jobPriceRecordLoading: (state: Draft<typeof initialState>) => {
			state.jobPriceRecordLoading = true;
		},
		jobPriceRecordSuccess: (state: Draft<typeof initialState>, action: PayloadAction<any>) => {

			state.jobPriceRecordLoading = false;
		},
		jobPriceRecordFail: (state: Draft<typeof initialState>) => {
			state.jobPriceRecordLoading = false;
		},
		valueMappingLoading: (state: Draft<typeof initialState>) => {
			state.valueMappingLoading = true;
		},
		valueMappingSuccess: (state: Draft<typeof initialState>) => {
			state.valueMappingLoading = false;
		},
		valueMappingFail: (state: Draft<typeof initialState>) => {
			state.valueMappingLoading = false;
		},
		fetchFundingLoading: (state: Draft<typeof initialState>) => {
			state.fundingsLoading = true;
		},
		fetchFundingSuccess: (state: Draft<typeof initialState>, action: PayloadAction<any>) => {
			state.fundings = action.payload.results;
			state.fundingsLoading = false;
		},
		fetchFundingFail: (state: Draft<typeof initialState>) => {
			state.fundingsLoading = false;
		},
		createFundingLoading: (state: Draft<typeof initialState>) => {
			state.createFundingLoading = true;
		},
		createFundingSuccess: (state: Draft<typeof initialState>) => {
			state.createFundingLoading = false;
		},
		createFundingFail: (state: Draft<typeof initialState>) => {
			state.createFundingLoading = false;
		},
		updateFundingLoading: (state: Draft<typeof initialState>) => {
			state.updateFundingLoading = true;
		},
		updateFundingSuccess: (state: Draft<typeof initialState>) => {
			state.updateFundingLoading = false;
		},
		updateFundingFail: (state: Draft<typeof initialState>) => {
			state.updateFundingLoading = false;
		},
		updateJobLoading: (state: Draft<typeof initialState>) => {
			state.updateJobLoading = true;
		},
		updateJobSuccess: (state: Draft<typeof initialState>) => {
			state.updateJobLoading = false;
		},
		updateJobFail: (state: Draft<typeof initialState>) => {
			state.updateJobLoading = false;
		},
		fetchPartnerLoading: (state: Draft<typeof initialState>) => {
			state.partnersLoading = true;
		},
		fetchParnterSuccess: (state: Draft<typeof initialState>, action: PayloadAction<any>) => {
			state.partners = action.payload.results;
			state.partnersLoading = false;

			if(action.payload.results.length === 1){
				state.selectedPartner = action.payload.results[0].id
			}
		},
		fetchPartnerFail: (state: Draft<typeof initialState>) => {
			state.partnersLoading = false;
		},
		deleteAreaLoading: (state: Draft<typeof initialState>) => {
			state.deleteAreaLoading = true;
		},
		deleteAreaSuccess: (state: Draft<typeof initialState>) => {
			state.deleteAreaLoading = false;
		},
		deleteAreaFail: (state: Draft<typeof initialState>) => {
			state.deleteAreaLoading = false;
		},
		fetchAreaOwnerLoading: (state: Draft<typeof initialState>) => {
			state.fetchAreaOwnerLoading = true;
		},
		fetchAreaOwnerSuccess: (state: Draft<typeof initialState>, action: PayloadAction<any>) => {
			state.fetchAreaOwnerLoading = false;
			const index = state.ownerDetails.findIndex((e: AreaOwnerDetails) => (
				e.area.toString() === action.payload.target.toString()
			))
			if (index === -1) {
				state.ownerDetails.push(
					{ ...action.payload, area: action.payload.target }
				)
			} else {
				state.ownerDetails[index] = { ...action.payload, area: action.payload.target }
			}
		},
		fetchAreaOwnerFail: (state: Draft<typeof initialState>) => {
			state.fetchAreaOwnerLoading = false;
		},
		fetchAreasFilterLoading: (state: Draft<typeof initialState>) => {
			state.areasFilterLoading = true;
		},
		fetchAreasFilterSuccess: (state: Draft<typeof initialState>, action: PayloadAction<any>) => {
			state.areasFilterLoading = false;
			state.areasFilter = action.payload
		},
		fetchAreasFilterFail: (state: Draft<typeof initialState>) => {
			state.areasFilterLoading = false;
		},
		fetchAreasTodosLoading: (state: Draft<typeof initialState>) => {
			state.areasTodosLoading = true;
		},
		fetchAreasTodosSuccess: (state: Draft<typeof initialState>, action: PayloadAction<any>) => {
			state.areasTodosLoading = false;
			state.areasTodos = action.payload.results
		},
		fetchAreasTodosFail: (state: Draft<typeof initialState>) => {
			state.areasTodosLoading = false;
		},
		fetchAreaTodosLoading: (state: Draft<typeof initialState>) => {
			state.areaTodosLoading = true;
		},
		fetchAreaTodosSuccess: (state: Draft<typeof initialState>, action: PayloadAction<any>) => {
			state.areaTodosLoading = false;
			state.areaTodos = action.payload.results
		},
		fetchAreaTodosFail: (state: Draft<typeof initialState>) => {
			state.areaTodosLoading = false;
		},
		fetchPartnerJobsLoading: (state: Draft<typeof initialState>) => {
			state.partnerJobsLoading = true;
		},
		fetchPartnerJobsSuccess: (state: Draft<typeof initialState>, action: PayloadAction<any>) => {
			state.partnerJobsLoading = false;
			state.partnerJobs = action.payload.results
		},
		fetchPartnerJobsFail: (state: Draft<typeof initialState>) => {
			state.partnerJobsLoading = false;
		},
		fetchPartnerListLoading: (state: Draft<typeof initialState>) => {
			state.partnersListLoading = true;
		},
		fetchPartnerListSuccess: (state: Draft<typeof initialState>, action: PayloadAction<any>) => {
			state.partnersListLoading = false;
			state.partnersList = action.payload.results
		},
		fetchPartnerListFail: (state: Draft<typeof initialState>) => {
			state.partnersListLoading = false;
		},
		setSelectedPartner: (state: Draft<typeof initialState>, action: PayloadAction<any>) => {
			state.selectedPartner = action.payload;
		},
		addJobMemberLoading: (state: Draft<typeof initialState>) => {
			state.jobMemberLoading = true;
		},
		addJobMemberSuccess: (state: Draft<typeof initialState>, action: PayloadAction<any>) => {
			state.jobMemberLoading = false;
		},
		addJobMemberFail: (state: Draft<typeof initialState>) => {
			state.jobMemberLoading = false;
		},
		removeJobMemberLoading: (state: Draft<typeof initialState>) => {
			state.jobMemberLoading = true;
		},
		removeJobMemberSuccess: (state: Draft<typeof initialState>, action: PayloadAction<any>) => {
			state.jobMemberLoading = false;
		},
		removeJobMemberFail: (state: Draft<typeof initialState>) => {
			state.jobMemberLoading = false;
		},
		fetchAssignAreasLoading: (state: Draft<typeof initialState>) => {
			state.assignAreasLoading = true
		},
		fetchAssignAreasSuccess: (state: Draft<typeof initialState>, action: PayloadAction<any>) => {
			state.assignAreasLoading = false
			state.assignAreas = action.payload
		},
		fetchAssignAreasFail: (state: Draft<typeof initialState>) => {
			state.assignAreasLoading = false
		},
		fetchAreaPartnersLoading: (state: Draft<typeof initialState>) => {
			state.areaPartnersLoading = true
		},
		fetchAreaPartnersSuccess: (state: Draft<typeof initialState>, action: PayloadAction<any>) => {
			state.areaPartnersLoading = false
			if (action.payload.length) {
				state.areaPartners[action.payload[0]["area"]] = action.payload
			} else {
				state.areaPartners = []
			}
		},
		fetchPartnerServicesLoading: (state: Draft<typeof initialState>) => {
			state.partnerServicesLoading = true;
		},
		fetchPartnerServicesSuccess: (state: Draft<typeof initialState>, action: PayloadAction<any>) => {
			state.partnerServicesLoading = false;
			state.partnerServices = action.payload.results
		},
		fetchPartnerServicesFail: (state: Draft<typeof initialState>) => {
			state.partnerServicesLoading = false;
		},
		fetchAreaPartnersFail: (state: Draft<typeof initialState>) => {
			state.areaPartnersLoading = false
		},
		areaPartnersLoading: (state: Draft<typeof initialState>) => {
			state.areaPartnersLoading = true
		},
		areaPartnersSuccess: (state: Draft<typeof initialState>) => {
			state.areaPartnersLoading = false
		},
		areaPartnersFail: (state: Draft<typeof initialState>) => {
			state.areaPartnersLoading = false
		},
		fetchPartnerMembersLoading: (state: Draft<typeof initialState>) => {
			state.fetchPartnerMembersLoading = true
		},
		fetchPartnerMembersSuccess: (state: Draft<typeof initialState>, action: PayloadAction<any>) => {
			state.fetchPartnerMembersLoading = false
			state.partnerMembers = action.payload.results
		},
		fetchPartnerMembersFail: (state: Draft<typeof initialState>) => {
			state.fetchPartnerMembersLoading = false
		},
		editAreaOwnerLoading:(state: Draft<typeof initialState>) => {
			state.editAreaOwnerLoading = false
		},
		editAreaOwnerSuccess:(state: Draft<typeof initialState>) => {
			state.editAreaOwnerLoading = false
		},
		editAreaOwnerFail:(state: Draft<typeof initialState>) => {
			state.editAreaOwnerLoading = false
		},
		fetchPartnerTodosLoading: (state: Draft<typeof initialState>) => {
			state.fetchPartnerTodosLoading = true
		},
		fetchPartneTodosSuccess: (state: Draft<typeof initialState>, action: PayloadAction<any>) => {
			state.fetchPartnerTodosLoading = false
			state.partnerTodos = action.payload
		},
		fetchPartnerTodosFail: (state: Draft<typeof initialState>) => {
			state.fetchPartnerTodosLoading = false
		},
		fetchTodoCommentsLoading: (state: Draft<typeof initialState>) => {
			state.fetchTodoCommentsLoading = true
		},
		fetchTodoCommentsSuccess: (state: Draft<typeof initialState>, action: PayloadAction<any>) => {
			state.fetchTodoCommentsLoading = false
			state.todoComments = action.payload.results
		},
		fetchTodoCommentsFail: (state: Draft<typeof initialState>) => {
			state.fetchTodoCommentsLoading = false
		},
		fetchAreaCommentsLoading: (state: Draft<typeof initialState>) => {
			state.areaCommentsLoading = true
		},
		fetchAreaCommentsSuccess: (state: Draft<typeof initialState>, action: PayloadAction<any>) => {
			state.areaCommentsLoading = false
			state.areaComments = action.payload.results
		},
		fetchAreaCommentsFail: (state: Draft<typeof initialState>) => {
			state.jobCommentsLoading = false
		},
		fetchJobCommentsLoading: (state: Draft<typeof initialState>) => {
			state.jobCommentsLoading = true
		},
		fetchJobCommentsSuccess: (state: Draft<typeof initialState>, action: PayloadAction<any>) => {
			state.jobCommentsLoading = false
			state.jobComments = action.payload.results
		},
		fetchJobCommentsFail: (state: Draft<typeof initialState>) => {
			state.jobCommentsLoading = false
		},
		addPartnerLoading: (state: Draft<typeof initialState>) => {
			state.addPartneLoading = true;
		},
		addPartneSuccess: (state: Draft<typeof initialState>, action: PayloadAction<any>) => {
			state.addPartneLoading = false;
		},
		addPartneFail: (state: Draft<typeof initialState>) => {
			state.addPartneLoading = false;
		},
		fetchPartnersJobsLoading: (state: Draft<typeof initialState>) => {
			state.partnerJobsLoading = true;
		},
		fetchPartnersJobsSuccess: (state: Draft<typeof initialState>, action: PayloadAction<any>) => {
			state.partnerJobsLoading = false;
			state.partnerJobs = action.payload.results
			state.jobFilters = action.payload.target.filters
			state.jobFilters.count = action.payload.count
			state.jobFilters.next = action.payload.next
			state.jobFilters.previous = action.payload.previous
		},
		fetchPartnersJobsFail: (state: Draft<typeof initialState>) => {
			state.partnerJobsLoading = false;
		},
	}
})


export const fetchAreas = (offset: number = 0, limit: number = 25, filter?: boolean, search?: string, status: string = '', mapProps: any = null, ids: string[] = [], ordering?: boolean, partner?: string, tableFilters?: TableFilters) => {
	let url = `/areas?limit=${mapProps.map_view ? 2000 : limit}&offset=${offset}`;
	if (!ids.length) {
		if (filter && filter === true) url += '&assigned=true';
		if (search) url += `&search=${search}`;
		if (status) url += `&status=${status}`;
		if (mapProps["map_view"]) url += `&raw=true&map=true&zoom=${mapProps['zoom']}&center_lat=${mapProps['center']['lat']}&center_lng=${mapProps['center']['lng']}`
	} else {
		url += `&ids=${ids.toString()}`
	}
	if (ordering) {
		url += '&ordering=created_at'
	} else {
		url += '&ordering=-created_at'
	}
	if (partner) {
		url += `&partner=${partner}`
	}
	if (tableFilters) {
		if(tableFilters.workflow) {
			url += `&workflow=${tableFilters.workflow}`
		}
		if(tableFilters.managers) {
			url += `&managers=${tableFilters.managers.join()}`
		}
		if(tableFilters.group !== undefined) {
			url += `&group=${tableFilters.group}`
		}
		if(tableFilters.trees !== undefined) {
			url += `&filter_trees=${tableFilters.trees}`
		}
		if(tableFilters.jobs !== undefined) {
			url += `&filter_jobs=${tableFilters.jobs}`
		}
		if(tableFilters.todos !== undefined) {
			url += `&filter_todos=${tableFilters.todos}`
		}
		if(tableFilters.plots !== undefined) {
			url += `&filter_plots=${tableFilters.plots}`
		}

		if(tableFilters.return_url){
			return url
		}
		
	}
	return (dispatch: AppDispatch) => {
		dispatch({
			type: 'apiCall',
			payload: {
				url,
				method: 'GET',
				onSuccess: actions.fetchAreasSuccess.type,
				onError: actions.fetchAreasFail.type,
				onLoading: actions.fetchAreasLoading.type,
				target: { offset, limit, filter, status, mapProps, ids, tableFilters }
			},
		});
	};
};

export const deleteArea = (area: string) => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: 'apiCall',
			payload: {
				url: '/areas/' + area,
				method: 'DELETE',
				onSuccess: actions.deleteAreaSuccess.type,
				onError: actions.deleteAreaFail.type,
				onLoading: actions.deleteAreaLoading.type,
				onSuccessAction: [
					fetchAreas,
					() => _hideModal('delete-area-' + area)
				]
			},
		});
	};
}

export const updateStepOrder = (stage: string, steps: any) => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: 'apiCall',
			payload: {
				url: '/area-workflow-update',
				method: 'POST',
				data: { steps: steps },
				onSuccess: actions.updateStepOrderSuccess.type,
				onError: actions.updateStepOrderFail.type,
				onLoading: actions.updateStepOrderLoading.type,
				onSuccessAction: () => fetchTemplateSteps(stage),
			},
		});
	};
};

export const fetchAreaDocuments = (area: string) => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: 'apiCall',
			payload: {
				url: '/areas/' + area + '/documents',
				method: 'GET',
				onSuccess: actions.fetchAreaDocumentsSuccess.type,
				onError: actions.fetchAreaDocumentsFail.type,
				onLoading: actions.fetchAreaDocumentsLoading.type,
			},
		});
	};
};


export const uploadAreaDocument = (area: string, file: File) => {
	return (dispatch: AppDispatch) => {
		const fd = new FormData()
		fd.append("file", file)
		dispatch({
			type: 'apiCall',
			payload: {
				url: '/areas/' + area + '/documents',
				method: 'POST',
				data: fd,
				onSuccess: actions.uploadAreaDocumentSuccess.type,
				onError: actions.uploadAreaDocumentFail.type,
				onLoading: actions.uploadAreaDocumentLoading.type,
				onSuccessAction: () => fetchAreaDocuments(area)
			},
		});
	};
};

export const fetchJobTemplates = () => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: 'apiCall',
			payload: {
				url: '/job-templates',
				method: 'GET',
				onSuccess: actions.jobTemplatesSuccess.type,
				onError: actions.jobTemplatesFail.type,
				onLoading: actions.jobTemplatesLoading.type,
			},
		});
	};
};

export const renameDocument = (area: string, document: string, name: string) => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: 'apiCall',
			payload: {
				url: `/areas/${area}/documents/${document}`,
				method: 'PATCH',
				data: { name },
				onSuccess: actions.documentActionSuccess.type,
				onError: actions.documentActionFail.type,
				onLoading: actions.documentActionLoading.type,
				onSuccessAction: () => fetchAreaDocuments(area)
			},
		});
	};
};

export const deleteDocument = (area: string, document: string) => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: 'apiCall',
			payload: {
				url: `/areas/${area}/documents/${document}`,
				method: 'DELETE',
				onSuccess: actions.documentActionSuccess.type,
				onError: actions.documentActionFail.type,
				onLoading: actions.documentActionLoading.type,
				onSuccessAction: () => fetchAreaDocuments(area)
			},
		});
	};
};

export const updateNotes = (area: string, notes: string) => {
	return (dispatch: AppDispatch) => {
		const fd = new FormData();
		fd.append("notes", notes)
		dispatch({
			type: 'apiCall',
			payload: {
				url: `/areas/${area}`,
				method: 'PATCH',
				data: fd,
				onSuccess: actions.documentActionSuccess.type,
				onError: actions.documentActionFail.type,
				onLoading: actions.documentActionLoading.type,
				onSuccessAction: () => fetchAreas(0, 0, false, '', '', {}, [area])
			},
		});
	};
};

export const fetchAreaWorkflows = () => {
	return (dispatch: AppDispatch) => {

		dispatch({
			type: 'apiCall',
			payload: {
				url: `/area-workflow`,
				method: 'GET',
				onSuccess: actions.fetchWorkflowsSuccess.type,
				onError: actions.fetchWorkflowsFail.type,
				onLoading: actions.fetchWorkflowsLoading.type,
			},
		});
	};
};

export const createAreaWorkflow = (name: string) => {
	return (dispatch: AppDispatch) => {

		dispatch({
			type: 'apiCall',
			payload: {
				url: `/area-workflow`,
				method: 'POST',
				data: { name },
				onSuccess: actions.createWorkflowSuccess.type,
				onError: actions.createWorkflowFail.type,
				onLoading: actions.createWorkflowLoading.type,
				onSuccessAction: [
					fetchAreaWorkflows,
					() => _hideModal('create-template')
				]
			},
		});
	};
};

export const fetchTemplateSteps = (stage: string) => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: 'apiCall',
			payload: {
				url: '/area-workflow-stage/' + stage + "/steps",
				method: 'GET',
				onSuccess: actions.fetchTemplateStepsSuccess.type,
				onError: actions.fetchTemplateStepsFail.type,
				onLoading: actions.fetchTemplateStepsLoading.type,
			},
		});
	};
};



export const fetchTemplateStages = (workflow: string) => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: 'apiCall',
			payload: {
				url: '/area-workflow/' + workflow + '/stages',
				method: 'GET',
				onSuccess: actions.fetchTemplateStagesSuccess.type,
				onError: actions.fetchTemplateStagesFail.type,
				onLoading: actions.fetchTemplateStagesLoading.type,
				onSuccessListFetch: { fn: fetchTemplateSteps, key: "id" }
			},
		});
	};
};

export const fetchTemplateStepData = (step: string) => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: 'apiCall',
			payload: {
				url: '/area-workflow-step/' + step + '/data',
				method: 'GET',
				onSuccess: actions.fetchAreaStepDataTemplatesSuccess.type,
				onError: actions.fetchAreaStepDataTemplatesFail.type,
				onLoading: actions.fetchAreaStepDataTemplatesLoading.type
			},
		});
	};
};

export const createTemplateStage = (workflow: string, name: string) => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: 'apiCall',
			payload: {
				url: '/area-workflow/' + workflow + '/stages',
				method: 'POST',
				data: { stage_name: name },
				onSuccess: actions.createTemplateStageSuccess.type,
				onError: actions.createTemplateStageFail.type,
				onLoading: actions.createTemplateStageLoading.type,
				onSuccessAction: [
					() => fetchTemplateStages(workflow),
					() => _hideModal('create-item-template')
				]
			},
		});
	};
};

export const createTemplateStep = (stage: string, name: string, _type: number, source: string, workflow: string) => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: 'apiCall',
			payload: {
				url: '/area-workflow-stage/' + stage + '/steps',
				method: 'POST',
				data: { type: _type, step_data: source, step_name: name },
				onSuccess: actions.createTemplateStageSuccess.type,
				onError: actions.createTemplateStageFail.type,
				onLoading: actions.createTemplateStageLoading.type,
				onSuccessAction: [
					() => fetchTemplateStages(workflow),
					() => _hideModal('create-item-template')
				]
			},
		});
	};
};

export const updateTemplateStep = (workflow: string, stage: string, step: string, name: string, source: string, type: number) => {
	return (dispatch: AppDispatch) => {
		const data = {
			step_name: name,
			data: source,
			_type: type,
			job: ''
		}
		if(type.toString()==="3"){
			data.job = source
		}
		dispatch({
			type: 'apiCall',
			payload: {
				url: '/area-workflow-stage/' + stage + '/steps/' + step,
				method: 'PUT',
				data,
				onSuccess: actions.updateTemplateStepSuccess.type,
				onError: actions.updateTemplateStepFail.type,
				onLoading: actions.updateTemplateStepLoading.type,
				onSuccessAction: [
					() => fetchTemplateSteps(stage),
					() => _hideModal('edit-item-template')
				]
			},
		});
	};
};

export const updateTemplateStage = (workflow: string, stage: string, name: string) => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: 'apiCall',
			payload: {
				url: '/area-workflow/' + workflow + '/stages/' + stage,
				method: 'PUT',
				data: { stage_name: name },
				onSuccess: actions.updateTemplateStageSuccess.type,
				onError: actions.updateTemplateStageFail.type,
				onLoading: actions.updateTemplateStageLoading.type,
				onSuccessAction: [
					() => fetchTemplateStages(workflow),
					() => _hideModal('edit-item-template')
				]
			},
		});
	};
};

export const deleteTemplateStep = (stage: string, step: string, workflow: string) => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: 'apiCall',
			payload: {
				url: '/area-workflow-stage/' + stage + "/steps/" + step,
				method: 'DELETE',
				onSuccess: actions.deleteTempalteStepSuccess.type,
				onError: actions.deleteTemplateStepFail.type,
				onLoading: actions.deleteTemplateStepFail.type,
				onSuccessAction: () => fetchTemplateStages(workflow),
				target: step
			},
		});
	};
};

export const deleteTemplateStage = (stage: string, workflow: string) => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: 'apiCall',
			payload: {
				url: '/area-workflow/' + workflow + "/stages/" + stage,
				method: 'DELETE',
				onSuccess: actions.deleteTemplateStageSuccess.type,
				onError: actions.deleteTemplateStageFail.type,
				onLoading: actions.deleteTemplateStageLoading.type,
				onSuccessAction: () => fetchTemplateStages(workflow),
				target: stage
			},
		});
	};
};

export const fetchAreaManagers = () => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: 'apiCall',
			payload: {
				url: '/area-manager',
				method: 'GET',
				onSuccess: actions.fetchAreaManagersSuccess.type,
				onError: actions.fetchAreaManagersFail.type,
				onLoading: actions.fetchAreaManagersLoading.type
			},
		});
	};
};

export const fetchAreasManagers = (area: string) => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: 'apiCall',
			payload: {
				url: `/area/${area}/managers`,
				method: 'GET',
				onSuccess: actions.fetchAreasManagersSuccess.type,
				onError: actions.fetchAreasManagersFail.type,
				onLoading: actions.fetchAreasManagersLoading.type,
				target: area
			},
		});
	};
};


export const addAreaManager = (area: string, manager: string) => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: 'apiCall',
			payload: {
				url: '/area-manager',
				method: 'POST',
				data: { area, manager },
				onSuccess: actions.areaManagerSuccess.type,
				onError: actions.areaManagerFail.type,
				onLoading: actions.areaManagerLoading.type,
				onSuccessAction: () => fetchAreasManagers(area)
			},
		});
	};
};

export const removeAreaManager = (area: string, manager: string) => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: 'apiCall',
			payload: {
				url: '/area-manager',
				method: 'DELETE',
				data: { area, manager },
				onSuccess: actions.areaManagerSuccess.type,
				onError: actions.areaManagerFail.type,
				onLoading: actions.areaManagerLoading.type,
				onSuccessAction: () => fetchAreasManagers(area)
			},
		});
	};
};

export const fetchAreaForestRanger = (area: string) => {

	return (dispatch: AppDispatch) => {
		dispatch({
			type: 'apiCall',
			payload: {
				url: '/forest-ranger/' + area,
				method: 'GET',
				onSuccess: actions.fetchAreaForestRangerSuccess.type,
				onError: actions.fetchAreaForestRangerFail.type,
				onLoading: actions.fetchAreaForestRangerLoading.type,
				target: area,
			},
		});
	};
};

export const fetchAreaWorkflowPoc = (area: string) => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: 'apiCall',
			payload: {
				url: '/area/' + area + '/workflow',
				method: 'GET',
				onSuccess: actions.fetchAreaWorkflowPocSuccess.type,
				onError: actions.fetchAreaWorkflowPocFail.type,
				onLoading: actions.fetchAreaWorkflowPocLoading.type,
				target: area
			},
		});
	};
};

export const fetchPlotWorkflowPoc = (plot: string) => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: 'apiCall',
			payload: {
				url: '/plot/' + plot + '/workflow',
				method: 'GET',
				onSuccess: actions.fetchPlotWorkflowPocSuccess.type,
				onError: actions.fetchPlotWorkflowPocFail.type,
				onLoading: actions.fetchPlotWorkflowPocLoading.type,
				target: plot
			},
		});
	};
};


export const updateArea = (area: string, changeditems: ChangedItem[]) => {
	return (dispatch: AppDispatch) => {
		const fd = new FormData();
		changeditems.map(item => fd.append(item.name, item.value))
		dispatch({
			type: 'apiCall',
			payload: {
				url: '/areas/' + area,
				method: 'PATCH',
				data: fd,
				onSuccess: actions.updateAreaSuccess.type,
				onError: actions.updateAreaFail.type,
				onLoading: actions.updateAreaLoading.type,
				onSuccessAction: () => fetchAreas(0, 0, false, '', '', {}, [area])
			},
		});
	};
};

export const createPlot = (area: string, name: string, size: number) => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: 'apiCall',
			payload: {
				url: '/areas/' + area + '/plots',
				method: 'POST',
				data: { area, name, size },
				onSuccess: actions.createPlotSuccess.type,
				onError: actions.createPlotFail.type,
				onLoading: actions.createPlotLoading.type,
				onSuccessAction: [
					() => fetchAreaPlots(area),
					() => _hideModal('create-area-plot')
				]
			},
		});
	};
};

export const fetchAreaPlots = (area: string) => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: 'apiCall',
			payload: {
				url: '/areas/' + area + '/plots',
				method: 'GET',
				onSuccess: actions.fetchPlotsSuccess.type,
				onError: actions.fetchPlotsFail.type,
				onLoading: actions.fetchPlotsLoading.type,

			},
		});
	};
};

export const changePlot = (area: string, plot: string, changeditems: ChangedItem[]) => {
	return (dispatch: AppDispatch) => {
		const fd = new FormData();
		changeditems.map(item => fd.append(item.name, item.value))
		dispatch({
			type: 'apiCall',
			payload: {
				url: `areas/${area}/plots/${plot}`,
				method: 'PATCH',
				data: fd,
				onSuccess: actions.changePlotSuccess.type,
				onError: actions.changePlotFail.type,
				onLoading: actions.changePlotLoading.type,
				onSuccessAction: () => fetchAreaPlots(area)
			},
		});
	};
};


export const fetchAreaJobs = (area: string, plot?: string) => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: 'apiCall',
			payload: {
				url: `/areas/${area}/jobs${(plot && '?plot=' + plot) || ''}`,
				method: 'GET',
				onSuccess: actions.fetchJobsSuccess.type,
				onError: actions.fetchJobsFail.type,
				onLoading: actions.fetchJobsLoading.type,

			},
		});
	};
};
export const deleteAreaPlot = (area: string, plot: string) => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: 'apiCall',
			payload: {
				url: `/areas/${area}/plots/${plot}`,
				method: 'DELETE',
				onSuccess: actions.deletePlotSuccess.type,
				onError: actions.deletePlotFail.type,
				onLoading: actions.deletePlotLoading.type,
				onSuccessAction: () => fetchAreaPlots(area)

			},
		});
	};
};
export const saveValueMapping = (area: string, plot: string, valueMapping: ValueMapping[]) => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: 'apiCall',
			payload: {
				url: `/value-mapping`,
				method: 'POST',
				data: valueMapping,
				onSuccess: actions.valueMappingSuccess.type,
				onError: actions.valueMappingFail.type,
				onLoading: actions.valueMappingLoading.type,
				onSuccessAction: () => fetchAreaJobs(area, plot)

			},
		});
	};
};

export const fetchPlotFundings = (plot: string) => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: 'apiCall',
			payload: {
				url: `/plots/${plot}/fundings`,
				method: 'GET',
				onSuccess: actions.fetchFundingSuccess.type,
				onError: actions.fetchFundingFail.type,
				onLoading: actions.fetchFundingLoading.type,

			},
		});
	};
};

export const createPlotFunding = (plot: string, name: string) => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: 'apiCall',
			payload: {
				url: `/plots/${plot}/fundings`,
				method: 'POST',
				data: { name },
				onSuccess: actions.createFundingSuccess.type,
				onError: actions.createFundingFail.type,
				onLoading: actions.createFundingLoading.type,
				onSuccessAction: [
					() => fetchPlotFundings(plot),
					() => _hideModal('create-plot-funding')
				]

			},
		});
	};
};

export const updateFunding = (plot: string, funding: string, changedItems: ChangedItem[], tags: AreaFundingTag[]) => {
	return (dispatch: AppDispatch) => {
		const fdFunding = new FormData();
		changedItems.map(ci => fdFunding.append(ci.name, ci.value))
		dispatch({
			type: 'apiCall',
			payload: {
				url: `/plots/${plot}/fundings/${funding}`,
				method: 'PATCH',
				data: fdFunding,
				onSuccess: actions.updateFundingSuccess.type,
				onError: actions.updateFundingFail.type,
				onLoading: actions.updateFundingLoading.type,
				onSuccessAction: [
					() => fetchPlotFundings(plot),
				]

			},
		});
		tags.forEach((tag, index) => {
			const tagSplitted = tag.id.split('/')
			if (tagSplitted.length === 1) {
				// Update existing tag
				dispatch({
					type: 'apiCall',
					payload: {
						url: `/fundings/${funding}/tags/${tag.id}`,
						method: 'PATCH',
						data: { name: tag.name, value: tag.value },
						onSuccess: actions.updateFundingSuccess.type,
						onError: actions.updateFundingFail.type,
						onLoading: actions.updateFundingLoading.type,
						onSuccessAction: [
							() => fetchPlotFundings(plot),
						]
					},
				});
			} else {
				dispatch({
					type: 'apiCall',
					payload: {
						url: `/fundings/${funding}/tags`,
						method: 'POST',
						data: { name: tag.name, value: tag.value },
						onSuccess: actions.updateFundingSuccess.type,
						onError: actions.updateFundingFail.type,
						onLoading: actions.updateFundingLoading.type,
						onSuccessAction: [
							() => fetchPlotFundings(plot),
						]
					},
				});
			}
		})
	};
};

export const updateJob = (area: string, job: string, updated: any) => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: 'apiCall',
			payload: {
				url: `/areas/${area}/jobs/${job}`,
				method: 'PATCH',
				data: updated,
				onSuccess: actions.updateJobSuccess.type,
				onError: actions.updateJobFail.type,
				onLoading: actions.updateJobLoading.type,
				onSuccessAction: [
					() => fetchAreaJobs(area)
				]

			},
		});
	};
};

export const deleteJob = (area: string, job: string) => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: 'apiCall',
			payload: {
				url: `/areas/${area}/jobs/${job}`,
				method: 'DELETE',
				onSuccess: actions.updateJobSuccess.type,
				onError: actions.updateJobFail.type,
				onLoading: actions.updateJobLoading.type,
				onSuccessAction: [
					() => fetchAreaJobs(area)
				]

			},
		});
	};
};

export const fetchPartners = () => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: 'apiCall',
			payload: {
				url: `/partners`,
				method: 'GET',
				onSuccess: actions.fetchParnterSuccess.type,
				onError: actions.fetchPartnerFail.type,
				onLoading: actions.fetchPartnerLoading.type,

			},
		});
	};
};

export const fetchAreaOwnerDetails = (area: string) => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: 'apiCall',
			payload: {
				url: `/area/${area}/owner`,
				method: 'GET',
				onSuccess: actions.fetchAreaOwnerSuccess.type,
				onError: actions.fetchAreaOwnerFail.type,
				onLoading: actions.fetchAreaOwnerLoading.type,
				target: area

			},
		});
	};
};

export const fetchAreasFilters = () => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: 'apiCall',
			payload: {
				url: `/area-filters`,
				method: 'GET',
				onSuccess: actions.fetchAreasFilterSuccess.type,
				onError: actions.fetchAreasFilterFail.type,
				onLoading: actions.fetchAreasFilterLoading.type,

			},
		});
	};
};

export const fetchAreasTodos = () => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: 'apiCall',
			payload: {
				url: `/areas-todos`,
				method: 'GET',
				onSuccess: actions.fetchAreasTodosSuccess.type,
				onError: actions.fetchAreasTodosFail.type,
				onLoading: actions.fetchAreasTodosLoading.type,

			},
		});
	};
};

export const fetchPartnersJobs = (partner:string, filters:JobFilters) => {
	return (dispatch: AppDispatch) => {
		let url = `/partners/${partner}/jobs?all=${filters.all}`
		if(filters.search) {
			url += `&search=${filters.search}`
		}
		if(filters.sort) {
			url += `&ordering=${filters.sort_direction||''}${filters.sort}`
		}
		if(filters.page){
			if(process.env.REACT_APP_TPP_API?.includes('https://')){
				url = filters.page.replace("http://", "https://")
			}
			else {
				url = filters.page
			}
		}
		dispatch({
			type: 'apiCall',
			payload: {
				url: url,
				method: 'GET',
				onSuccess: actions.fetchPartnersJobsSuccess.type,
				onError: actions.fetchPartnersJobsFail.type,
				onLoading: actions.fetchPartnersJobsLoading.type,
				target: {filters}

			},
		});
	};
};

export const fetchAreaTodos = (area: string) => apiCall(`/areas/${area}/todos`, 'GET', 'fetchAreaTodos');
export const fetchPartnerJobs = () => apiCall(`/partner/jobs`, 'GET', 'fetchPartnerJobs');
export const fetchPartnerList = () => apiCall('/partners', 'GET', 'fetchPartnerList');
export const fetchPartnerServices = () => apiCall(`/partners/services`, 'GET', 'fetchPartnerServices');
export const fetchAssignAreas = () => apiCall('/areas/assign', 'GET', 'fetchAssignAreas');
export const fetchPartnerMembers = (partner:string) => apiCall(`/partners/${partner}/members`, 'GET', 'fetchPartnerMembers');
export const fetchPartnerTodos = (partner:string) => apiCall(`/partners/${partner}/todos`, 'GET', 'fetchPartnerTodos');
export const fetchTodoComments = (todo:string) => apiCall(`/todos/${todo}/comments`, 'GET', 'fetchTodoComments');
export const fetchAreaComments = (area:string) => apiCall(`/areas/${area}/comments`, 'GET', 'fetchAreaComments');
export const fetchJobComments = (job:string) => apiCall(`/jobs/${job}/comments`, 'GET', 'fetchJobComments');

export const addJobMember = (job: string, area: string, member: string) => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: 'apiCall',
			payload: {
				url: `/jobs/${job}/members`,
				method: 'POST',
				data: { member },
				onSuccess: actions.addJobMemberSuccess.type,
				onError: actions.addJobMemberFail.type,
				onLoading: actions.addJobMemberLoading.type,
				onSuccessAction: () => fetchAreaJobs(area)


			},
		});
	};
};
export const removeJobMember = (job: string, area: string, member: string) => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: 'apiCall',
			payload: {
				url: `/jobs/${job}/members`,
				method: 'DELETE',
				data: { member },
				onSuccess: actions.removeJobMemberSuccess.type,
				onError: actions.removeJobMemberFail.type,
				onLoading: actions.removeJobMemberFail.type,
				onSuccessAction: () => fetchAreaJobs(area)

			},
		});
	};
};

export const addAreaMember = (area: string, member: string) => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: 'apiCall',
			payload: {
				url: `/areas/${area}/partners`,
				method: 'POST',
				data: { member },
				onSuccess: actions.addJobMemberSuccess.type,
				onError: actions.addJobMemberFail.type,
				onLoading: actions.addJobMemberLoading.type,
				onSuccessAction: [
					() => _hideModal('partner-member'),
					() => fetchAreaPartners(area),
					() => fetchAssignAreas()
				]
			},
		});
	};
};

export const removeAreaMember = (area: string, member: string) => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: 'apiCall',
			payload: {
				url: `/areas/${area}/partners`,
				method: 'DELETE',
				data: { member },
				onSuccess: actions.removeJobMemberSuccess.type,
				onError: actions.removeJobMemberFail.type,
				onLoading: actions.removeJobMemberFail.type,
				onSuccessAction: () => fetchAreaPartners(area)

			},
		});
	};
};

export const setSelectedPartner = (partner: string) => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: actions.setSelectedPartner.type,
			payload: partner,
		});
	};
};

export const fetchAreaPartners = (area: string) => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: 'apiCall',
			payload: {
				url: `/areas/${area}/partners`,
				method: 'GET',
				onSuccess: actions.fetchAreaPartnersSuccess.type,
				onError: actions.fetchAreaPartnersFail.type,
				onLoading: actions.fetchAreaPartnersLoading.type,
			},
		});
	};
};

export const editAreaOwner = (area: string, phone:string, address:string, first_name:string, last_name:string, mail:string) => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: 'apiCall',
			payload: {
				url: `/area/${area}/owner`,
				method: 'PATCH',
				data: {phone, address, first_name, last_name, mail},
				onSuccess: actions.editAreaOwnerSuccess.type,
				onError: actions.editAreaOwnerFail.type,
				onLoading: actions.editAreaOwnerLoading.type,
				onSuccessAction: [
					() => _hideModal('areaowner-edit'),
					() => fetchAreaOwnerDetails(area),
				]
			},
		});
	};
};

export const addPartner = (type: string, name: string, homepage: string, services: string[]) => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: 'apiCall',
			payload: {
				url: `/partners`,
				method: 'POST',
				data: {
					partner_type: type,
					name,
					homepage,
					services
				},
				onSuccess: actions.addJobMemberSuccess.type,
				onError: actions.addJobMemberFail.type,
				onLoading: actions.addJobMemberLoading.type
			},
		});
	};
};

export const createJob = (area: string, template:string) => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: 'apiCall',
			payload: {
				url: `/jobs/create`,
				method: 'POST',
				data: {area,template},
				onSuccess: actions.editAreaOwnerSuccess.type,
				onError: actions.editAreaOwnerFail.type,
				onLoading: actions.editAreaOwnerLoading.type,
				onSuccessAction: [
					() => _hideModal('create-job'),
					() => fetchAreaJobs(area),
				]
			},
		});
	};
};

export const editTodo = (area: string, todo:string, changedItems: ChangedItem[]) => {
	return (dispatch: AppDispatch) => {
		const fd = new FormData();
		changedItems.map(item => fd.append(item.name, item.value))
		dispatch({
			type: 'apiCall',
			payload: {
				url: `/areas/${area}/todos/${todo}`,
				method: 'PATCH',
				data: fd,
				onSuccess: actions.editAreaOwnerSuccess.type,
				onError: actions.editAreaOwnerFail.type,
				onLoading: actions.editAreaOwnerLoading.type,
				onSuccessAction: [
					() => fetchAreaTodos(area),
				]
			},
		});
	};
};

// apiCall function so we don't write so much boilerplate code.
// actions parameter is the key of the actions excluding Success,Fail or Loading. It will get the correct actions automatically.
export const apiCall = (url: string, method: string, action?: string, data?: any, target?: any, onSuccess?: Action, onError?: Action, onLoading?: Action) => {
	let _onSuccess: Action | undefined = onSuccess;
	let _onLoading: Action | undefined = onError;
	let _onError: Action | undefined = undefined;
	if (action && !onSuccess && !onError && !onLoading) {
		const _actions: any = actions
		_onSuccess = _actions[`${action}Success`]
		_onError = _actions[`${action}Fail`]
		_onLoading = _actions[`${action}Loading`]
	}
	const payload: any = {
		type: 'apiCall',
		payload: {
			url,
			method,
			onSuccess: _onSuccess?.type,
			onError: _onError?.type,
			onLoading: _onLoading?.type,
		},
	}
	if (data) {
		payload.payload["data"] = data;
	}
	if (target) {
		payload.payload["target"] = target;
	}
	return (dispatch: AppDispatch) => {
		dispatch(payload);
	};
}


export const actions = areaSlice.actions

export default areaSlice.reducer;

export const getPartner = (id: string, partners: Partner[]) => (
	partners.filter(partner => partner.id === id)[0]
)