import store from "../../../setup/redux/Store";

export const HasPermission = (permission: string) => {
    const permissions = store.getState().auth.profile.permissions;

    if (permissions == null) {
        return false;
    }

    if (permission.startsWith('!')) {
        return permissions.indexOf(permission) === -1;
    }

    return permissions.indexOf(permission) > -1;
};
