import axios from 'axios'
import React, {FC, useEffect, useState} from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { API_URL } from '../../../../_metronic/helpers'
import { PageTitle } from '../../../../_metronic/layout/core'
import { fetchProfileSuccess, loginSuccess } from '../../auth'
import { ProfileModel } from '../../auth/models/ProfileModel'

type ParamPage = {
  profile: string
}

const AdminSwitchProfilePage: FC = () => {
  const { profile } = useParams<ParamPage>();
  const dispatch = useDispatch()
  const [_profile, setProfile] = useState<ProfileModel>()
  const [loading, setLoading] = useState(false)
  const intl = useIntl()
  const history = useHistory()
  useEffect(()=>{
    if(!_profile || !(parseInt(profile)===_profile.id)) {
      setLoading(true)
      axios.get(API_URL+'/i/profiles/'+profile).then(res=>{
        setProfile(res.data)
        setLoading(false)
      })
    }
  }, [_profile, profile])

  const login = () => {
    dispatch({ type: fetchProfileSuccess.type, payload: {results: [_profile]} })
    dispatch({ type: loginSuccess.type, payload: { access: _profile?.token } })
    history.push('/')
  }
  return (
    <>
      <PageTitle breadcrumbs={[]}>{"ADMIN - PROFILE SWITCH"}</PageTitle>
      <div className='mt-10'>
        <div className='card'>
          <div className='card-header'>
            <div className='card-toolbar'>
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'GENERAL.LOADING'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
              <h3>{intl.formatMessage({id:'AUTH.LOGIN.SWITCH'})} {_profile?.first_name} {_profile?.last_name}</h3>
            </div>
          </div>
          <div className='card-body align-items-center'>
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'GENERAL.LOADING'})}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
          {_profile?.token && (
            <button className='btn btn-primary' onClick={login} >{intl.formatMessage({id:'AREA.MANAGEMENT.LIST.NEXT'})}</button>
          )}

          </div>
        </div>
      </div>
    </>
  )
}

export default AdminSwitchProfilePage
