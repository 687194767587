/* eslint-disable jsx-a11y/anchor-is-valid */
import { faArrowDown, faArrowRight, faArrowUp, faTimes } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { API_URL } from '../../../../../_metronic/helpers'
import HasPerm from '../../../auth/redux/HasPerm'
import { AreaTodo } from '../../models/AreaModel'
import { fetchAreaTodos } from '../../redux/AreaRedux'
import { getDaysToEnd } from '../dashboard/AreasTodos'
import { SkeletonRow } from '../SkeletonRow'

type Props = {
  className: string
  todos: AreaTodo[]
  area?: string
  fetchTodos?: typeof fetchAreaTodos
  todosLoading: boolean
  showManager?: boolean
}

const TodosList: React.FC<Props> = ({className, todos, area, fetchTodos, todosLoading, showManager} ) => {
  const intl = useIntl();
  const [selected, setSelected] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState('');
  const [sortDirection, setSortDirection] = useState('');
  const [paginationCount, setPaginationCount] = useState(area?'all':'10');
  const TodoStatus = [
    intl.formatMessage({id: "AREA.TODO.STATUS.OPEN"}),
    intl.formatMessage({id: "AREA.TODO.STATUS.IN_PROGRESS"}),
    intl.formatMessage({id: "AREA.TODO.STATUS.DONE"}),
    intl.formatMessage({id: "AREA.TODO.STATUS.SKIPPED"})
  ]
  const selectAll = (event: React.ChangeEvent<HTMLInputElement>)=>{
    const todos_list = todos.map(todo=>todo.id);
    setSelected(event.target.value === '0' ? todos_list:[]);
  }
  const selectTodo = (event: React.ChangeEvent<HTMLInputElement>)=>{
    const index = selected.indexOf(event.target.value);
    const currentSelected = selected;
    if(index === -1){
      currentSelected.push(event.target.value);
    }else{
      currentSelected.splice(index,1);
    }
    setSelected([...currentSelected]);
  }
  const onStatusChange = () => {
    setLoading(true)
    selected.map((todo, index)=>(
      axios.patch(API_URL+`/areas/${area}/todos/${todo}`, {status: 3}).then(()=>{
        if(selected.length-1===index){
          setLoading(false);
          fetchTodos && fetchTodos(area||'')
        }
      })
    ))
  }
  const onStatusChangeOpen = () => {
    setLoading(true)
    selected.map((todo, index)=>(
      axios.patch(API_URL+`/areas/${area}/todos/${todo}`, {status: 1}).then(()=>{
        if(selected.length-1===index){
          setLoading(false);
          fetchTodos && fetchTodos(area||'')
        }
      })
    ))
  }
  const filterTodos = () => {
    let _todos = [...todos];
    if (sort === "title") {
      _todos = _todos.sort((a:any, b:any) =>
      a[sort]?.localeCompare(b[sort]));
    }
    if (sort === "manager") {
      _todos = _todos.sort((a:any, b:any) =>
      a?.ref_details.manager?.localeCompare(b?.ref_details.manager));
    }
    if (sortDirection === "-"){
      _todos = _todos.reverse()
    }

    if(paginationCount === "all"){

      return _todos;
    }
    _todos = _todos.slice(0, parseInt(paginationCount));
    return _todos;
  }
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>{intl.formatMessage({id: "AREA.MENU.TODOS.LIST"})}</span>
          <span className='text-muted mt-1 fw-bold fs-7'>{todos.length} {intl.formatMessage({id: "AREA.MENU.TODOS"})}</span>
        </h3>
        <div className='card-toolbar'>
        <div className='d-flex'>
          {area && selected.length > 0 && (
            <div className='d-flex'>
              <button 
                type='button'
                className='btn btn-sm btn-secondary btn-color-muted btn-active-light-success me-2'
                onClick={onStatusChangeOpen}
                disabled={loading}
                >
                  {TodoStatus[0]}
                </button>
                <button 
                  type='button'
                  className='btn btn-sm btn-secondary btn-color-muted btn-active-light-success me-2'
                  onClick={onStatusChange}
                  disabled={loading}
                  >
                    {TodoStatus[2]}
                  </button>
              </div>
          )}
         <label className='col-form-label fw-bold fs-6'>{intl.formatMessage({id: 'AREA.MANAGEMENT.LIMIT'})}</label>
          <select
            className='form-select form-select-solid'
            defaultValue={paginationCount}
            onChange={(e:any)=>{
             setPaginationCount(e.target.value)
            }}
          >
            <option value='5'>5</option>
            <option value='10'>10</option>
            <option value='25'>25</option>
            <option value='all'>All</option>
          </select>
          </div>
      </div>
      </div>
      
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <HasPerm permission='areas.areaworkflow.change'>
                  {fetchTodos && (
                  <th className='w-25px'>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value={selected.length === todos.length ? '1': '0'}
                        checked={selected.length === todos.length}
                        disabled={!area}
                        onChange={selectAll}
                      />
                    </div>
                  </th>
                  )}
                </HasPerm>
                <th 
                  className='min-w-150px'>
                    Titel <FontAwesomeIcon 
                      className='cursor-pointer me-2'
                      onClick={()=>{
                        setSort('title')
                        setSortDirection(sortDirection === "+"?"-":"+"||"")
                      }
                      } 
                      icon={sort==="title"&&sortDirection === "+"? faArrowUp:faArrowDown}/>
                    {sort==="title"&&(<FontAwesomeIcon className='cursor-pointer mr-2' onClick={()=>setSort('')} icon={faTimes}/>)}</th>
                <th className='min-w-140px'>Deadline <FontAwesomeIcon  className='cursor-pointer' 
                  onClick={()=>{
                    setSort('')
                    setSortDirection('')
                    }
                  }
                  icon={faArrowDown}/></th>
                <th className='min-w-120px'>Status</th>
                {showManager && (
                  <th 
                  className='min-w-120px'>
                    Manager <FontAwesomeIcon 
                      className='cursor-pointer me-2'
                      onClick={()=>{
                        setSort('manager')
                        setSortDirection(sortDirection === "+"?"-":"+"||"")
                      }
                      }
                      icon={sort==="manager" && sortDirection === "+"? faArrowUp:faArrowDown}/>
                    {sort==="manager"&&(<FontAwesomeIcon className='cursor-pointer mr-2' onClick={()=>setSort('')} icon={faTimes}/>)}</th>
                )}
                {showManager && (
                  <th 
                    className='min-w-120px'>
                    Kommentar
                  </th>
                )}
                <th className='min-w-50px text-end'></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {todosLoading && <SkeletonRow rows={5} columns={fetchTodos?4:3}/>}
              {!todosLoading && filterTodos().map(todo=>(
                <tr>
                  <HasPerm permission='areas.areaworkflow.change'>
                    {fetchTodos && (
                      <td>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                          <input className='form-check-input widget-9-check' 
                            type='checkbox' value={todo.id} 
                            checked={selected.indexOf(todo.id) === -1 ? false:true}
                            disabled={!area}
                            onChange={selectTodo}/>
                            
                        </div>
                      </td>
                    )}
                  </HasPerm>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                      <Link 
                        to={todo.area ? `/area/${todo.area}/todos/${todo.id}` : todo.ref_details.area?`/area/${todo.ref_details.area}/todos/${todo.id}`:`todos/${todo.id}`}
                        className='text-dark fw-bolder text-hover-primary fs-6'
                        >
                        {todo.title}
                      </Link>
                      <span className='text-muted fw-bold text-muted d-block fs-7'>
                        {showManager && todo.ref_details?.name?todo.ref_details.name:todo.description.substring(0,50)}
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                {todo.deadline && todo.status < 3 && (
                  <div className={`badge badge-light-${getTodoCompletionColor(todo.completion)} fw-bolder me-auto px-4 py-3`}>{getDaysToEnd(todo.deadline)} {intl.formatMessage({id: "AREAS.TODOS.DAYS_REMAINING"})}</div>
                )}
                </td>
                <td className='text-end'>
                  <div className='d-flex flex-column w-100 me-2'>
                  <span className={`badge badge-light-${StatusMapping[todo.status-1].badge} fw-bolder me-auto px-4 py-3`}>
                    {TodoStatus[todo.status-1]}
                  </span>
                  </div>
                </td>
                {showManager && (
                  <td>
                    {todo.ref_details.manager}
                  </td>
                )}
                  {showManager && (
                    <td>
                      {todo.last_comment ? new Date(todo.last_comment*1000).toLocaleDateString('de'): ""}
                    </td>
                )}
                <td>
                  <div className='d-flex justify-content-end flex-shrink-0'>
                    <Link
                      to={todo.area ? `/area/${todo.area}/todos/${todo.id}` : todo.ref_details.area?`/area/${todo.ref_details.area}/todos/${todo.id}`:`todos/${todo.id}`}
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <FontAwesomeIcon icon={faArrowRight} />
                    </Link>
                  </div>
                </td>
              </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {TodosList}

export const StatusMapping = [
  {
    badge: "info"
  },
  {
    badge: "warning"
  },
  {
    badge: "success"
  },
  {
    badge: "success"
  }
]

export const getTodoCompletionColor = (percent:number) => {
  if(percent >= 50 && percent < 80){
    return 'warning'
  }
  if(percent >= 80){
    return 'danger'
  }
  return 'primary'
}