/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {getUsers} from './_requests'
import {useQueryRequest} from './QueryRequestProvider'
import { QUERIES } from '../../../../../../../_metronic/helpers/crud-helper/consts'
import { createResponseContext, stringifyRequestQuery } from '../../../../../../../_metronic/helpers/crud-helper/helpers'
import { initialQueryResponse, initialQueryState, PaginationState } from '../../../../../../../_metronic/helpers/crud-helper/models'
import { WithChildren } from '../../../../../../../_metronic/helpers/react18MigrationHelpers'
import { ProfileModel } from '../../../../../auth/models/ProfileModel'

const QueryResponseContext = createResponseContext<ProfileModel>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.USERS_LIST}-${query}`,
    () => {
      return getUsers(query)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseCurrentPage = () => {
  const {response } = useQueryResponse()
  let next = response?.next
  let prev = response?.previous
  let page = 0
  if(next){
    page = parseInt(next.split('&page=')[1][0])-1
  }
  if(prev && !page && prev.includes('&page=')){
    page = parseInt(prev.split('&page=')[1][0])+1
  }
  if(!page) {
    page = 2
  }

  
  return page
}

const useQueryResponseCount = () => {
  const {response } = useQueryResponse()
  return response?.count
}

const useQueryResponseNext = () => {
  const {response } = useQueryResponse()
  return response?.next
}

const useQueryResponseData = () => {
  const {response} = useQueryResponse()
  
  return response?.results || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState
  }

  const {response} = useQueryResponse()
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState
  }

  return response.payload.pagination
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
  useQueryResponseCurrentPage,
  useQueryResponseCount,
  useQueryResponseNext
}
