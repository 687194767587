/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useIntl } from 'react-intl'
import { AreaJob, JobFilters } from '../../models/AreaModel'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { StatusMapping } from '../jobs/Jobs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowLeft, faArrowRight, faArrowUp } from '@fortawesome/pro-duotone-svg-icons'

type Props = {
  className: string
  jobs: AreaJob[]
  fetchJobs: (filters:JobFilters) => void
  filters: JobFilters
}

const PartnerJobs: React.FC<Props> = ({className, jobs, fetchJobs, filters}) => {
  const intl = useIntl();
  let timer:any = 0;
  const searchChange = (search:string) => {
    if(timer) clearTimeout(timer);
    timer = setTimeout(()=>{
      fetchJobs({...filters, search, page})
    }, 500)
  }

  const page = undefined;

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>{intl.formatMessage({id: "AREA.MENU.JOBS"})}</span>

          <span className='text-muted fw-bold fs-7'>{filters.count} {intl.formatMessage({id: "AREA.MENU.JOBS"})}</span>
        </h3>
        <div className='card-toolbar'>

              <label className='col-form-label fw-bold fs-6'>{intl.formatMessage({id: 'AREA.MANAGEMENT.SEARCH'})}</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder={intl.formatMessage({id: 'AREA.MANAGEMENT.SEARCH'})}
                  onChange={(e:any)=>searchChange(e.target.value)}
                  defaultValue={filters.search||""}
                />
        </div>

      </div>
      <div className='card-body pt-3'>
      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-3'>
        <thead>
          <tr>
            <th className='p-0 w-50px'></th>
            <th className='p-0 min-w-150px fw-bold text-hover-primary cursor-pointer'
              onClick={()=>fetchJobs({...filters, sort: 'name', sort_direction: filters.sort_direction?'':'-', page})}>
              <div className='d-flex'>
              <p className='me-2'>Name</p>
              {filters.sort==='name' && (
                <span className=''><FontAwesomeIcon icon={filters.sort_direction===""?faArrowDown:faArrowUp} /></span>
              )}
              </div>
            </th>
            <th className='p-0 min-w-150px fw-bold text-hover-primary cursor-pointer'
              onClick={()=>fetchJobs({...filters, sort: 'area', sort_direction: filters.sort_direction?'':'-', page})}>
              <div className='d-flex'>
              <p className='me-2'>Ort</p>
              {filters.sort==='area' && (
                <span className=''><FontAwesomeIcon icon={filters.sort_direction===""?faArrowDown:faArrowUp} /></span>
              )}
              </div>
            </th>
            <th className='p-0 min-w-140px fw-bold text-hover-primary cursor-pointer'
              onClick={()=>fetchJobs({...filters, sort: 'description', sort_direction: filters.sort_direction?'':'-', page})}>
              <div className='d-flex'>
              <p className='me-2'>Beschreibung</p>
              {filters.sort==='description' && (
                <span className=''><FontAwesomeIcon icon={filters.sort_direction===""?faArrowDown:faArrowUp} /></span>
              )}
              </div>
            </th>
            <th className='p-0 min-w-140px fw-bold text-hover-primary cursor-pointer'
              onClick={()=>fetchJobs({...filters, sort: 'due_date', sort_direction: filters.sort_direction?'':'-', page})}>
              <div className='d-flex'>
              <p className='me-2'>Fertig bis</p>
              {filters.sort==='due_date' && (
                <span className=''><FontAwesomeIcon icon={filters.sort_direction===""?faArrowDown:faArrowUp} /></span>
              )}
              </div>
            </th>
            <th className='p-0 min-w-40px fw-bold text-end text-hover-primary cursor-pointer'
              onClick={()=>fetchJobs({...filters, sort: 'status', sort_direction: filters.sort_direction?'':'-', page})}>
              <div className='d-flex'>
              <p className='me-2'>Status</p>
              {filters.sort==='status' && (
                <span className=''><FontAwesomeIcon icon={filters.sort_direction===""?faArrowDown:faArrowUp} /></span>
              )}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
        {jobs.map(job=>(
          <tr>
            <td>
              <div className='symbol symbol-50px me-2'>
                <span className='symbol-label bg-light-success'>
                <div
                  className='symbol-label'
                  style={{backgroundImage: `url(${toAbsoluteUrl(job.area_details.image, true)})`}}
                ></div>
                </span>
              </div>
            </td>
            <td>
              <div className='flex-grow-1 me-2'>
                <Link to={`/area/${job.area}/jobs/${job.id}`} className='text-gray-800 fw-bolder text-hover-primary fs-6'>
                  {job.name}
                </Link>
              </div>
            </td>
            <td>
              <div className='flex-grow-1 me-2'>
                <Link to={`/area/${job.area_details.area}`} className='text-muted fw-bod text-hover-primary fs-6'>
                  {job.area_details.name}
                </Link>
              </div>
            </td>
            <td>
              <div className='flex-grow-1 me-2'>
              <Link to={`/area/${job.area}/jobs/${job.id}`} className='text-gray-800 fw-bod text-hover-primary fs-6'>
                  {job.description.length>60?job.description.slice(0,60)+"...":job.description}
                </Link>
              </div>
            </td>
            <td className='text-muted fw-bold'>
              <span className={`badge badge-light-${job.completion > 100 ? 'danger':'success'} fs-8 fw-bolder my-2`}>
                {job.due_date?new Date(job.due_date).toLocaleDateString('de'):""}
              </span>
              </td>
            <td className='text-dark fw-bolder fs-6 pe-0'>
              <span className={`badge badge-light-${job.status === 1 ? 'info':'warning'} fs-8 fw-bolder my-2`}>{intl.formatMessage({id: StatusMapping[job.status-1].name})} - {job.status_result.data}</span>
            </td>
          </tr>
        ))}
        </tbody>
        </table>
        <div className='d-flex justify-content-end'>
          <div className='mr-2 '>
            <button
              type='button'
              onClick={()=>fetchJobs({...filters, page:filters.previous})}
              className='btn btn-lg btn-light-primary me-3'
              disabled={!filters.previous}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
              {intl.formatMessage({id: 'AREA.MANAGEMENT.LIST.BACK'})}
            </button>
          </div>
          <div>
            <button 
              type='button'
              onClick={()=>fetchJobs({...filters, page:filters.next})}
              className='btn btn-lg btn-primary me-3'
              disabled={!filters.next}>
              <span className='indicator-label'>
                {intl.formatMessage({id: 'AREA.MANAGEMENT.LIST.NEXT'})}
              <FontAwesomeIcon icon={faArrowRight} />
              </span>
              
            </button>
          </div>
        </div>
        {!jobs.length && (
          <span className='text-muted fw-bold d-block pt-1'>{intl.formatMessage({id: "AREAS.JOBS.NO_JOBS"})}</span>
        )}
        
      </div>
    </div>
  )
}

export {PartnerJobs}