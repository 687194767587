/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'

type Props = {
  role: string
}

export const ROLE_COLORS = [
  {
    id: "Administrator",
    color: "danger",
  },
  {
    id: "Platform User",
    color: "info",
  },
  {
    id: "Partner - AreaManager",
    color: "success",
  },
  {
    id: "Partner",
    color: "warning",
  }
]

export const getRoleColors = (role:string) => {
  let color = ROLE_COLORS.filter(color=>color.id===role)
  if(color.length){
    return color[0].color
  }
  return ROLE_COLORS[1].color
}

const UserRoleCell: FC<Props> = ({role}) => (
  <span className={`badge badge-light-${getRoleColors(role)} fs-8 fw-bolder me-2`}>{role}</span>
  )

export {UserRoleCell}
