import {useQuery} from 'react-query'
import {UserEditModalForm} from './UserEditModalForm'
import {useListView} from '../core/ListViewProvider'
import {getUserById} from '../core/_requests'
import { QUERIES } from '../../../../../../../_metronic/helpers/crud-helper/consts'
import { API_URL, isNotEmpty } from '../../../../../../../_metronic/helpers'
import { useEffect, useState } from 'react'
import axios from 'axios'

const UserEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const [roles, setRoles] = useState<any[]>([])

  useEffect(()=>{
    axios.get(API_URL+'/i/roles').then(res=>setRoles(res.data))
  },[])
  const {
    isLoading,
    data: user,
    error,
  } = useQuery(
    `${QUERIES.USERS_LIST}-user-${itemIdForUpdate}`,
    () => {
      return getUserById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )
  if (!isLoading && !error && user) {
    return <UserEditModalForm isUserLoading={isLoading} user={user} roles={roles} />
  }

  return null
}

export {UserEditModalFormWrapper}
