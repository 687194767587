import { FormattedMessage, useIntl } from 'react-intl'
import {useListView} from '../../core/ListViewProvider'
import { useQueryResponseCount } from '../../core/QueryResponseProvider'
import {UsersListToolbar} from './UserListToolbar'
import {UsersListGrouping} from './UsersListGrouping'
import {UsersListSearchComponent} from './UsersListSearchComponent'
import { useQueryResponseLoading } from '../../core/QueryResponseProvider'

const UsersListHeader = () => {
  const {selected} = useListView()
  const loading = useQueryResponseLoading()
  const count = useQueryResponseCount()
  const intl = useIntl();
  return (
    <div className='card-header border-0 pt-6'>
      <div>
        <div className='d-flex flex-row'>
          <UsersListSearchComponent />
        </div>
        
        <div className='d-flex flex-row'>
          <span className='text-muted mt-1 fw-bold fs-7'>
            {loading ? (
              <span className='indicator-progress' style={{display: 'block'}}>
                <FormattedMessage id='GENERAL.LOADING' />
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            ):
            (
              <>{count} {intl.formatMessage({id: 'SEARCH.USER'})}</>
            )}
          </span>
        </div>
      </div>
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <UsersListGrouping /> : <UsersListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {UsersListHeader}
