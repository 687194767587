
import {combineReducers} from 'redux'

import authReducer from '../../app/modules/auth/redux/AuthRedux'
import areasReducer from '../../app/modules/areas/redux/AreaRedux'
import ModalReducer from '../../app/modules/modals/redux/ModalRedux'

export const rootReducer = combineReducers({
  auth: authReducer,
  areas: areasReducer,
  modals: ModalReducer,
  // accounts: AccountReducer
})

export type RootState = ReturnType<typeof rootReducer>
