/* eslint-disable jsx-a11y/anchor-is-valid */
import { faArrowDown, faArrowUp } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { _showModal } from '../../../modals/redux/ModalRedux'
import { AreaJob } from '../../models/AreaModel'
import { updateJob } from '../../redux/AreaRedux'
import { getDaysToEnd } from '../dashboard/AreasTodos'
import { SkeletonRow } from '../SkeletonRow'
import { getTodoCompletionColor } from '../todos/TodosList'
import { StatusMapping } from './Jobs'

type Props = {
  className: string
  jobs: AreaJob[]
  updateJob?: typeof updateJob
  showModal?: typeof _showModal
  loading: boolean
  showManager?: boolean
}

const JobList: React.FC<Props> = ({className, jobs, updateJob, loading, showManager}) => {
  const intl = useIntl()

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>{intl.formatMessage({id: "AREA.MENU.JOBS.LIST"})}</span>
          <span className='text-muted mt-1 fw-bold fs-7'>{jobs.length} {intl.formatMessage({id: "AREA.MENU.JOBS"})}</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='min-w-120px'></th>
                <th className='min-w-150px'>Titel</th>
                <th className='min-w-140px'>Deadline</th>
                <th className='min-w-120px'>Status</th>
                {showManager && (
                  <th className='min-w-120px'>Manager</th>
                )}
                {updateJob && (
                  <th className='min-w-100px text-end'></th>
                )}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {loading && (
                <SkeletonRow rows={3} columns={4} />
              )}
              {!loading && jobs.map((job, index)=>(
                <tr>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-45px me-5'>
                        <img src={toAbsoluteUrl(getJobIcon(job.name))} alt='jobCard' className='p-3' />
                      </div>
                     </div>
                  </td>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                      <Link to={showManager?`/area/${job.area_details.area}/jobs/${job.id}`:`jobs/${job.id}`} className='text-dark fw-bolder text-hover-primary fs-6'>
                        {job.name}
                      </Link>
                      <span className='text-muted fw-bold text-muted d-block fs-7'>
                        {showManager?job.area_details.name:job.description.substring(0,50)}
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                {job.due_date && (
                  getDaysToEnd(job.due_date) >= 0 ? (
                    <div className={`badge badge-light-${getTodoCompletionColor(job.completion)} fw-bolder me-auto px-4 py-3`}>{getDaysToEnd(job.due_date)} {intl.formatMessage({id: "AREAS.TODOS.DAYS_REMAINING"})}</div>
                  ):(
                    job.status <= 3 ? (
                      <div className={`badge badge-light-${getTodoCompletionColor(job.completion)} fw-bolder me-auto px-4 py-3`}>{getDaysToEnd(job.due_date)} {intl.formatMessage({id: "AREAS.TODOS.DAYS_OVER"})}</div>

                      ):(
                        <div className={`badge badge-light-${getTodoCompletionColor(job.completion)} fw-bolder me-auto px-4 py-3`}>{new Date(job.due_date).toLocaleDateString('de')}</div>
                      )
                  )
                )}
                </td>
                <td className='text-end'>
                  <div className='d-flex flex-column w-100 me-2'>
                  <span className={`badge badge-light-${StatusMapping[job.status-1].badge} fw-bolder me-auto px-4 py-3`}>
                    {intl.formatMessage({id: StatusMapping[job.status-1].name})}
                  </span>
                  </div>
                </td>
                {showManager && (
                  <td>
                    <div className='d-flex justify-content-end flex-shrink-0'>
                      {job.area_details.manager}
                    </div>
                  </td>
                )}
                {updateJob && (
                <td>
                  <div className='d-flex justify-content-end flex-shrink-0'>
                    {index !== 0 && (
                      <button
                      onClick={()=>updateJob(job.area, job.id, {order: job.order-1})}
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <FontAwesomeIcon icon={faArrowUp} />
                    </button>
                    )}
                    {index+1 !== jobs.length && (
                    <button
                      onClick={()=>updateJob(job.area, job.id, {order: job.order+1})}
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <FontAwesomeIcon icon={faArrowDown} />
                    </button>
                    )}
                  </div>
                </td>
                )}
              </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {JobList}

const IconMapping = [
  {
    name: "gras",
    icon: "/media/icons/jobs/grass.png"
  },
  {
    name: "zaun",
    icon: "/media/icons/jobs/fence.png"
  },
  {
    name: "pflanz",
    icon: "/media/icons/jobs/soil.png"
  },
  {
    name: "räumung",
    icon: "/media/icons/jobs/wood.png"
  },
]

const getJobIcon = (name:string) => {
  let i = '/media/icons/jobs/fence.png'
  IconMapping.forEach((icon:any)=>{
    if(name.toLocaleLowerCase().includes(icon.name)){
      i = icon.icon
    }
  })
  return i
  
}