/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'

const Step4Completed: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-8 pb-lg-10'>
        <h2 className='fw-bolder text-dark'>Your Are Done!</h2>
      </div>

      <div className='mb-0'>
        <div className='fs-6 text-gray-600 mb-5'>
          Wir freuen uns auf eine gute Zusammenarbeit.
        </div>

        <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
          <KTSVG
            path='/media/icons/duotune/general/gen044.svg'
            className='svg-icon-2tx svg-icon-warning me-4'
          />
          <div className='d-flex flex-stack flex-grow-1'>
            <div className='fw-bold'>
              <h4 className='text-gray-800 fw-bolder'>Achtung!</h4>
              <div className='fs-6 text-gray-600'>
                Dein Partner Profil wird noch überprüft, bevor es freigeschaltet wird.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { Step4Completed }
