/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../../../../../_metronic/helpers'
import { ProfileModel } from '../../../../../../auth/models/ProfileModel'

type Props = {
  user: ProfileModel
}

const UserInfoCell: FC<Props> = ({user}) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
      <a href='#'>
        <div className='symbol-label'>
          <img src={toAbsoluteUrl(`${user.image_url}`, true)} alt={user.first_name} className='w-100' />
        </div>
      </a>
    </div>
    <div className='d-flex flex-column'>
      <Link to={'/profiles/'+user.id} className='text-gray-800 text-hover-primary mb-1'>
        {user.first_name} {user.last_name}{(!user.first_name && !user.last_name) && user.email}
      </Link>
      <span>{user.email}</span>
    </div>
  </div>
)

export {UserInfoCell}
