/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useQueryResponseCurrentPage, useQueryResponseLoading, useQueryResponseNext, useQueryResponsePagination} from '../../core/QueryResponseProvider'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import { Key } from 'react'

const mappedLabel = (label: string): string => {
  if (label === '&laquo; Previous') {
    return 'Previous'
  }

  if (label === 'Next &raquo;') {
    return 'Next'
  }

  return label
}

const UsersListPagination = () => {
  const pagination = useQueryResponsePagination()
  const page = useQueryResponseCurrentPage()
  const next = useQueryResponseNext()
  const isLoading = useQueryResponseLoading()
  const {updateState} = useQueryRequest()
  const updatePage = (_page: number | null) => {
    if (!_page || isLoading || page === _page) {
      return
    }

    updateState({page:_page||1, items_per_page: pagination.items_per_page || 10})
  }

  const links:Array<{label: string; active: boolean; url: string | null; page: number | null}> = []
  if(page > 1){
    links.push({
      label: 'Previous',
      active: false,
      url: null,
      page: page-1
    })
  }
  links.push({
    label: page.toString(),
    active: true,
    url: null,
    page: page
  })
  if(next){
    links.push({
      label: 'Next',
      active: false,
      url: null,
      page: page+1
    })
  }


  return (
    <div className='row'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_users_paginate'>
          <ul className='pagination'>
            {links
              ?.map((link) => {
                return {...link, label: mappedLabel(link.label)}
              })
              .map((link: { label: Key | null | undefined; page: number | null }) => (
                <li
                  key={link.label}
                  className={clsx('page-item', {
                    // active: pagination.page === link.page,
                    // disabled: isLoading,
                    // previous: link.label === 'Previous',
                    // next: link.label === 'Next',
                  })}
                >
                  <a
                    className={clsx('page-link', {
                      'page-text': link.label === 'Previous' || link.label === 'Next',
                      'me-5': link.label === 'Previous',
                    })}
                    onClick={() => updatePage(link.page)}
                    style={{cursor: 'pointer'}}
                  >
                    {mappedLabel(link.label?.toString()||'')}
                  </a>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export {UsersListPagination}
