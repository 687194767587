import { ID, Response } from "../../../../../../../_metronic/helpers/crud-helper/models"
import { ProfileModel } from "../../../../../auth/models/ProfileModel"

export type User = {
  id?: ID
  name?: string
  first_name?: string
  last_name?:string
  avatar?: string
  email?: string
  position?: string
  role?: string
  last_login?: string
  two_steps?: boolean
  joined_day?: string
  online?: boolean
  initials?: {
    label: string
    state: string
  }
}

export type UsersQueryResponse = Response<Array<ProfileModel>>

export const initialUser: ProfileModel = {
  image_url: 'avatars/300-6.jpg',
  current_position: '90599',
  role: 'Administrator',
  first_name: '',
  last_name: '',
  email: '',
  id: 0,
  user: 0,
  username: "",
  notifications: 0,
  send_mails: false,
  public_profile: false
}
