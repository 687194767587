/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { connect } from 'react-redux'
import { AppDispatch, RootState } from '../../../../setup/redux/Store'
import { API_URL } from '../../../../_metronic/helpers'
import { JobList } from '../components/jobs/JobList'
import { TodosList } from '../components/todos/TodosList'
import { AreaJob, AreaModel, AreaTodo, KPI, PartnerComment } from '../models/AreaModel'
import ReactApexChart from 'react-apexcharts'
import { Stats } from './Stats'
import { faChartArea, faLayerGroup, faPeopleGroup, faUserHelmetSafety } from '@fortawesome/pro-duotone-svg-icons'
import { AreaCommentLog } from '../components/dashboard/AreaCommentLog'
import { AreaChart } from '../../../modules/areas/components/dashboard/AreaChart';
import { AREA_MAP_PROPS, fetchAreas } from '../redux/AreaRedux'
import { RouteComponentProps, withRouter } from 'react-router-dom'


interface AdminProps {
}

interface ConnectedProps1 {
  areas: AreaModel[]
}

interface DispatchProps {
  fetchAreas: typeof fetchAreas
}

interface AdminPageState {
  loading: boolean
  todos: AreaTodo[]
  jobs: AreaJob[]
  kpiLoading: boolean
  kpi?: KPI
  comments: PartnerComment[]
}

type Props = ConnectedProps1 & DispatchProps & AdminProps & WrappedComponentProps & RouteComponentProps

class AdminPage extends React.Component<Props, AdminPageState> {

  constructor(props: Props | Readonly<Props>) {
    super(props)
    this.state = {
      loading: false,
      todos: [],
      jobs: [],
      kpiLoading: false,
      kpi: undefined,
      comments: []
    }
    
  }

  componentDidMount() {
    this.setState({loading:true, kpiLoading: true})
    axios.get(API_URL+"/kpi/todos").then(todos=>axios.get(API_URL+"/kpi/jobs").then(jobs=>{
      this.setState({loading: false, todos:todos.data.results, jobs:jobs.data.results})
    })).catch(()=>this.setState({loading:false}))
    axios.get(API_URL+"/kpi").then(kpis=>this.setState({kpiLoading:false, kpi: kpis.data}))
    axios.get(API_URL+"/kpi/comments").then(comments=>this.setState({comments: comments.data.results}))
    this.props.fetchAreas(0, 0, false, '', 'month', {...AREA_MAP_PROPS, map_view: true}, [], true);
  }
  

  render(): React.ReactNode {
    const intl = this.props.intl;

    // const getWorkflowGroupValues = (workflow:string):number[] => {
    //   return Object.values(this.state.kpi?.areas_workflow_group[workflow])||[]
    // }
    
    return (
      <>
      {this.state.kpi && (
      <>
      <div className='row'>
        <div className='col-md-2'>
          <Stats
            className='card-xl-stretch mb-xl-8'
            icon={faLayerGroup}
            color='white'
            iconColor='primary'
            title={this.state.kpi.new_areas.toString()}
            description={intl.formatMessage({id: 'AREA.MANAGEMENT.NEW_AREAS'})}
          />
        </div>
        <div className='col-md-2'>
          <Stats
            className='card-xl-stretch mb-xl-8'
            icon={faLayerGroup}
            color='white'
            iconColor='primary'
            title={this.state.kpi.all_areas.toString()}
            description={intl.formatMessage({id: 'AREA.MANAGEMENT.AREAS'})}
          />
        </div>
        <div className='col-md-2'>
          <Stats
            className='card-xl-stretch mb-xl-8'
            icon={faChartArea}
            color='white'
            iconColor='primary'
            title={this.state.kpi.total_size.toLocaleString('de')+" "+intl.formatMessage({id: 'AREA.MANAGEMENT.UNIT'})}
            description={intl.formatMessage({id: 'AREA.MANAGEMENT.ALL_AREAS_SIZE'})}
          />
        </div>
        <div className='col-md-2'>
          <Stats
            className='card-xl-stretch mb-xl-8'
            icon={faChartArea}
            color='white'
            iconColor='primary'
            title={this.state.kpi.total_size_plant_area.toLocaleString('de')+" "+intl.formatMessage({id: 'AREA.MANAGEMENT.UNIT'})}
            description={intl.formatMessage({id: 'AREA.MANAGEMENT.ALL_PLOT_SIZE'})}
          />
        </div>
        <div className='col-md-2'>
          <Stats
            className='card-xl-stretch mb-xl-8'
            icon={faUserHelmetSafety}
            color='white'
            iconColor='primary'
            title={this.state.kpi.area_managers.toString()}
            description={intl.formatMessage({id: 'AREA.MANAGEMENT.TABLE.FORSTFARMER'})}
          />
        </div>
        <div className='col-md-2'>
          <Stats
            className='card-xl-stretch mb-xl-8'
            icon={faPeopleGroup}
            color='white'
            iconColor='primary'
            title={this.state.kpi.total_area_owners.toString()}
            description={intl.formatMessage({id: 'AREA.MANAGEMENT.AERA_OWNER'})}
          />
        </div>
      </div>
      {/* <div className='row mb-5'>
        <div className='col-md-12'>
          <div className='card'>
            <div className='card-body pt-0 pb-2'>
              <div className='row'>
                <div className='col-md-4'>
                  <div className={`text-inverse-white fw-bolder fs-2 mb-2 mt-5`}>Ø{this.state.kpi.avg_time_to_init.toString()}</div>
                  <div className={`fw-bold text-inverse-white fs-7`}>{intl.formatMessage({id: 'AREA.MANAGEMENT.KPI.AVERAGE_DAYS_TO_CONTACT'})}</div>
                </div>
                <div className='col-md-4'>
                  <div className={`text-inverse-white fw-bolder fs-2 mb-2 mt-5`}>Ø{this.state.kpi.avg_time_to_approval.toString()}</div>
                  <div className={`fw-bold text-inverse-white fs-7`}>{intl.formatMessage({id: 'AREA.MANAGEMENT.KPI.AVERAGE_DAYS_TO_APPROVAL'})}</div>
                </div>
                <div className='col-md-3'>
                  <div className={`text-inverse-white fw-bolder fs-2 mb-2 mt-5`}>Ø{this.state.kpi.avg_time_to_plant.toString()}</div>
                  <div className={`fw-bold text-inverse-white fs-7`}>{intl.formatMessage({id: 'AREA.MANAGEMENT.KPI.AVERAGE_DAYS_TO_PLANT'})}</div>
                </div>
                <div className='col-md-1'>
                  <FontAwesomeIcon className='mt-5 opacity-25' icon={faTachometerAverage} size="4x" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      
      <div className='row'>
        <div className='col-md-3 '>
          <div className='card card-xl-stretch mb-xl-8' style={{height: "15em"}}>
          <div className='card-header'>
              <div className='card-title'>
                {intl.formatMessage({id: 'AREA.MANAGEMENT.TABLE.TREES'})}
              </div>
            </div>
            <ReactApexChart 
              options={
                {labels: [
                  (this.state.kpi.trees_to_plant-this.state.kpi.trees_planted).toLocaleString('de')+" "+intl.formatMessage({id: 'AREA.MANAGEMENT.SETTINGS.TREES_TO_PLANT'}),
                  this.state.kpi.trees_planted.toLocaleString('de')+" "+intl.formatMessage({id: 'AREA.MANAGEMENT.SETTINGS.TREES_PLANTED'}),
                ],
                colors:['#ffc700', '#4EBC97'],
                chart: {
                  events: {
                    dataPointSelection: (event: any, chartContext: any, config: any) => {
                      if(config.w.config.labels[config.dataPointIndex].includes(intl.formatMessage({id: 'AREA.MANAGEMENT.SETTINGS.TREES_PLANTED'}))){
                        this.props.history.push('/areas?filter_trees=planted')
                      }
                      if(config.w.config.labels[config.dataPointIndex].includes(intl.formatMessage({id: 'AREA.MANAGEMENT.SETTINGS.TREES_TO_PLANT'}))){
                        this.props.history.push('/areas?filter_trees=to_plant')
                      }
                  }}
                }}
              } 
                series={[(this.state.kpi.trees_to_plant-this.state.kpi.trees_planted), this.state.kpi.trees_planted]}
                type="pie"
                width={"100%"} 
                height={"150px"}
                minHeight={"150px"}

            />
          </div>
        </div>
        <div className='col-md-3 '>
        <div className='card card-xl-stretch mb-xl-8' style={{height: "15em"}}>
            <div className='card-header'>
              <div className='card-title'>
                {intl.formatMessage({id: 'AREA.MENU.JOBS'})}
              </div>
            </div>
            <ReactApexChart 
              options={
                {labels: [
                  this.state.kpi.jobs.open.toLocaleString('de')+" "+intl.formatMessage({id: 'AREA.MANAGEMENT.JOB.STATUS.OPENED'}),
                  this.state.kpi.jobs.in_progress.toLocaleString('de')+" "+intl.formatMessage({id: 'AREA.MANAGEMENT.JOB.STATUS.IN_PROGRESS'}),
                  this.state.kpi.jobs.done_or_skip.toLocaleString('de')+" "+intl.formatMessage({id: 'AREA.MANAGEMENT.JOB.STATUS.DONE'})
                ],
                colors:['#7e8299', '#ffc700', '#4EBC97'],
                chart: {
                  events: {
                    dataPointSelection: (event: any, chartContext: any, config: any) => {
                      if(config.w.config.labels[config.dataPointIndex].includes(intl.formatMessage({id: 'AREA.MANAGEMENT.JOB.STATUS.OPENED'}))){
                        this.props.history.push('/areas?filter_jobs=open')
                      }
                      if(config.w.config.labels[config.dataPointIndex].includes(intl.formatMessage({id: 'AREA.MANAGEMENT.JOB.STATUS.IN_PROGRESS'}))){
                        this.props.history.push('/areas?filter_jobs=in_progress')
                      }
                      if(config.w.config.labels[config.dataPointIndex].includes(intl.formatMessage({id: 'AREA.MANAGEMENT.JOB.STATUS.DONE'}))){
                        this.props.history.push('/areas?filter_jobs=done')
                      }
                  }}
                }
              }
              } 
                series={[this.state.kpi.jobs.open, this.state.kpi.jobs.in_progress, this.state.kpi.jobs.done_or_skip]}
                type="pie"
                width={"100%"} 
                height={"120px"}
            />
          </div>
        </div>
        <div className='col-md-3 '>
        <div className='card card-xl-stretch mb-xl-8' style={{height: "15em"}}>
          <div className='card-header'>
              <div className='card-title'>
                {intl.formatMessage({id: 'AREA.MENU.TODOS'})}
              </div>
            </div>
            <ReactApexChart 
              options={
                {labels: [
                  this.state.kpi.todos.open.toLocaleString('de')+" "+intl.formatMessage({id: 'AREA.MANAGEMENT.JOB.STATUS.OPENED'}),
                  this.state.kpi.todos.in_progress.toLocaleString('de')+" "+intl.formatMessage({id: 'AREA.MANAGEMENT.JOB.STATUS.IN_PROGRESS'}),
                  this.state.kpi.todos.done_or_skip.toLocaleString('de')+" "+intl.formatMessage({id: 'AREA.MANAGEMENT.JOB.STATUS.DONE'})
                ],
                colors:['#7e8299', '#ffc700', '#4EBC97'],
                chart: {
                  events: {
                    dataPointSelection: (event: any, chartContext: any, config: any) => {
                      if(config.w.config.labels[config.dataPointIndex].includes(intl.formatMessage({id: 'AREA.MANAGEMENT.JOB.STATUS.OPENED'}))){
                        this.props.history.push('/areas?filter_todos=open')
                      }
                      if(config.w.config.labels[config.dataPointIndex].includes(intl.formatMessage({id: 'AREA.MANAGEMENT.JOB.STATUS.IN_PROGRESS'}))){
                        this.props.history.push('/areas?filter_todos=in_progress')
                      }
                      if(config.w.config.labels[config.dataPointIndex].includes(intl.formatMessage({id: 'AREA.MANAGEMENT.JOB.STATUS.DONE'}))){
                        this.props.history.push('/areas?filter_todos=done')
                      }
                  }}
                }
              }
              } 
                series={[this.state.kpi.todos.open, this.state.kpi.todos.in_progress, this.state.kpi.todos.done_or_skip]}
                type="pie"
                width={"100%"} 
                height={"120px"}
            />
          </div>
        </div>
        <div className='col-md-3 '>
        <div className='card card-xl-stretch mb-xl-8' style={{height: "15em"}}>
          <div className='card-header'>
              <div className='card-title'>
                {intl.formatMessage({id: 'AREA.MENU.PLOTS'})}
              </div>
            </div>
            <ReactApexChart 
              options={
                {labels: [
                  this.state.kpi.plots.open.toLocaleString('de')+" "+intl.formatMessage({id: 'AREA.MANAGEMENT.JOB.STATUS.OPENED'}),
                  this.state.kpi.plots.in_progress.toLocaleString('de')+" "+intl.formatMessage({id: 'AREA.MANAGEMENT.JOB.STATUS.IN_PROGRESS'}),
                  this.state.kpi.plots.done_or_skip.toLocaleString('de')+" "+intl.formatMessage({id: 'AREA.MANAGEMENT.JOB.STATUS.DONE'})
                ],
                colors:['#7e8299', '#ffc700', '#4EBC97'],
                chart: {
                  events: {
                    dataPointSelection: (event: any, chartContext: any, config: any) => {
                      if(config.w.config.labels[config.dataPointIndex].includes(intl.formatMessage({id: 'AREA.MANAGEMENT.JOB.STATUS.OPENED'}))){
                        this.props.history.push('/areas?filter_plots=open')
                      }
                      if(config.w.config.labels[config.dataPointIndex].includes(intl.formatMessage({id: 'AREA.MANAGEMENT.JOB.STATUS.IN_PROGRESS'}))){
                        this.props.history.push('/areas?filter_plots=in_progress')
                      }
                      if(config.w.config.labels[config.dataPointIndex].includes(intl.formatMessage({id: 'AREA.MANAGEMENT.JOB.STATUS.DONE'}))){
                        this.props.history.push('/areas?filter_plots=done')
                      }
                  }}
                }
              }
              } 
                series={[this.state.kpi.plots.open, this.state.kpi.plots.in_progress, this.state.kpi.plots.done_or_skip]}
                type="pie"
                width={"100%"} 
                height={"120px"}
            />
          </div>
        </div>
      </div>
      {/* <div className='row'>
        {Object.keys(this.state.kpi.areas_workflow_group).map((workflow:string) => (
          <div className='col-md-4 '>
          <div className='card card-xl-stretch mb-xl-8' style={{height: "20em"}}>
          <div className='card-header'>
              <div className='card-title'>
                {workflow}
              </div>
            </div>
            <ReactApexChart 
              options={
                {labels: Object.keys(this.state.kpi?.areas_workflow_group[workflow]||[]),
              }
              } 
                series={getWorkflowGroupValues(workflow)}
                type="pie"
                width={"100%"} 
                height={"200px"}
                minHeight={"200px"}
            />
          </div>
        </div>
        ))}
      </div> */}
      </>
      )}
      <div className='row'>
        <div className='col-md-7'>
          <TodosList className='mb-5 mb-xl-8' todos={this.state.todos} todosLoading={this.state.loading} showManager/>
          <JobList 
            className='mb-5 mb-xl-8'
            jobs={this.state.jobs}
            loading={this.state.loading}
            showManager
            />
        </div>
        <div className='col-md-5'>
          <AreaChart className='mb-5' areas={this.props.areas} />
          <AreaCommentLog className='' comments={this.state.comments} />
        </div>
      </div>
      </>
    )
  }
  
}

const mapStateToProps = (state: RootState) => {
  return {
    areas: state.areas.areas,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    fetchAreas: (
      offset:number=0, limit:number=25,
      filter?: boolean, search?:string,
      status?:string, mapProps:any=AREA_MAP_PROPS,
      ids?:string[], ordering?:boolean
      ) => dispatch(fetchAreas(offset, limit, filter, search, status, mapProps, ids, ordering)),

  };
};

export default withRouter(connect<ConnectedProps1, DispatchProps>(mapStateToProps, mapDispatchToProps)(injectIntl(AdminPage)));
