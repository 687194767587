/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { connect } from 'react-redux'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { RootState } from '../../../setup/redux/Store'
import { AppDispatch } from '../../../setup/redux/Store'
import { Partner } from '../areas/models/AreaModel'
import { fetchPartnerList, setSelectedPartner } from '../areas/redux/AreaRedux'
import { PartnerCard } from './PartnerCard'
// import HasPerm from '../../../auth/redux/HasPerm'
// import { AreaJob } from '../../models/AreaModel'
// import { fetchAreaJobs } from '../../redux/AreaRedux'
// import { JobCard } from './JobCard'
// import { getTotalCost } from './JobFinance'

interface WorkflowProps {
  // area: string
}

interface WorkflowState {
  selected: string
}

interface ConnectedProps {
  partners: Partner[]
  loading: boolean
  selectedPartner: string
}

interface DispatchProps {
  // fetchAreaJobs: typeof fetchAreaJobs
  fetchPartners: typeof fetchPartnerList
  setSelectedPartner: typeof setSelectedPartner
}

type Props = ConnectedProps & DispatchProps & WorkflowProps & WrappedComponentProps & RouteComponentProps


class PartnerOverview extends Component<Props, WorkflowState> {

  constructor(props: Props) {
    super(props)
    this.selectPartner = this.selectPartner.bind(this)
  }

  componentDidMount() {
    // this.props.fetchAreaJobs(this.props.area)
    this.props.fetchPartners()
    this.props.setSelectedPartner('')
  }

  selectPartner(id: string) {
    if (id !== this.props.selectedPartner) {
      this.props.setSelectedPartner(id)
    }
    this.props.history.push('/partners/dashboard')
  }

  render() {
    return (
      <>
        <div className='d-flex flex-wrap flex-stack mb-6'>
          <h3 className='fw-bolder my-2'>

            {/* <span className='fs-6 text-gray-400 fw-bold ms-1'>Aktive</span> */}
          </h3>

          <div className='d-flex flex-wrap my-2'>
            {/* <div className='me-4'>
              <select
                name='status'
                data-control='select2'
                data-hide-search='true'
                className='form-select form-select-sm form-select-white w-125px'
                defaultValue='Active'
              >
                <option value='Active'>Active</option>
                <option value='Approved'>In Progress</option>
                <option value='Declined'>To Do</option>
                <option value='In Progress'>Completed</option>
              </select>
            </div> */}
            {/* <HasPerm permission='areas.areajob.add'> */}
            <Link
              to={`/partners/registration`}
              className='btn btn-primary btn-sm'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_create_project'
            >
              Partner erstellen
            </Link>
            {/* </HasPerm> */}
          </div>
        </div>

        <div className='row g-6 g-xl-9 mt-6'>
          {this.props.partners.map((partner: Partner, index: number) => (
            <div key={index} className='col-md-6 col-xl-4'>
              <PartnerCard
                name={partner.name}
                logo={partner.logo}
                id={partner.id}
                active={partner.active}
                setPartner={this.selectPartner}
                is_manager={partner.is_manager}
              />
            </div>
          ))}

        </div>
      </>
    )
  }
}


const mapStateToProps = (state: RootState) => {
  return {
    partners: state.areas.partnersList,
    loading: state.areas.partnersListLoading,
    selectedPartner: state.areas.selectedPartner
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {

    // fetchAreaJobs: (area: string) => dispatch(fetchAreaJobs(area)),
    fetchPartners: () => dispatch(fetchPartnerList()),
    setSelectedPartner: (partner: string) => dispatch(setSelectedPartner(partner))

  };
};

export default withRouter(connect<ConnectedProps, DispatchProps>(mapStateToProps, mapDispatchToProps)(injectIntl(PartnerOverview)));
