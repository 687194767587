import {FC, useEffect, useState} from 'react'
import { shallowEqual, useDispatch, useSelector} from 'react-redux'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {fetchProfile, logout} from './AuthCRUD'
import store, { RootState } from '../../../../setup/redux/Store'
import { NewReleaseNoteModal } from '../../release-notes/NewReleaseNoteModal'
import { getModal, hideModal } from '../../modals/redux/ModalRedux'
import { ModalModel } from '../../modals/models/ModalModel'
import axios from 'axios'
import { API_URL } from '../../../../_metronic/helpers'
import { ReleaseNote } from '../../areas/models/AreaModel'


const AuthInit: FC = (props) => {
  const dispatch = useDispatch()
  const all_modals:any = useSelector<RootState>(({modals}):ModalModel[] => modals.modals, shallowEqual)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const [releaseNotes, setReleaseNotes] = useState<ReleaseNote[]>()

  // We should request user by authToken before rendering the application
  useEffect(() => {
    if (store.getState().auth.auth.accessToken) {
      dispatch(fetchProfile());
      axios.get(API_URL+'/release-notes').then(res=>setReleaseNotes(res.data.results))
      if (store.getState().auth.profile.permissions) {
        setShowSplashScreen(false);
      }
    } else {
      dispatch(logout())
      setShowSplashScreen(false)
    }
    //eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>
  {props.children}
    <NewReleaseNoteModal
      key={releaseNotes?releaseNotes[0].id:'init-release-note'}
      show={getModal('new-release-note', all_modals)} 
      handleClose={()=>dispatch({ type: hideModal.type, payload: {modal: 'new-release-note'} })}
    />
  </>
}

export default AuthInit
