/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import { getCSS, getCSSVariableValue } from '../../../../../_metronic/assets/ts/_utils'
import { useIntl } from 'react-intl'
import { AreaModel } from '../../models/AreaModel'

type Props = {
  className: string
  areas: AreaModel[]
}

const AreaChart: React.FC<Props> = ({className, areas}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const intl = useIntl();
  const [_areas, setAreas] = useState(areas);
  const [chart, setChart] = useState<any>();
  
  useEffect(() => {
    
    if (!chartRef.current) {
      return
    }
    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, groups(_areas)))
    if (chart) {
      chart.render()
      setChart(chart)
    }
    
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, _areas])
  if(_areas !== areas){
    setAreas(areas)
    chart.destroy()
    const _chart = new ApexCharts(chartRef.current, getChartOptions(350, groups(areas)))
    _chart.render()
    setChart(_chart)
  }
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>{intl.formatMessage({id: "AREAS.CHART.TITLE"})}</span>

          <span className='text-muted fw-bold fs-7'>{areas.length} {intl.formatMessage({id: "AREAS.CHART.TITLE"})} - IN 6 MONATEN</span>
        </h3>


      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body'>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_3_chart' style={{height: '150px'}}></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {AreaChart}

function getChartOptions(height: number, points: any[]): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-info')
  const lightColor = getCSSVariableValue('--bs-light-info')

  return {
    series: [
      {
        name: 'Areas',
        data: itemsInArray(points),
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: 150,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: keysInObject(points),
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val + ' '
        },
      },
    },
    colors: [lightColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
  }
}


const groups = (data:AreaModel[]) => data.reduce((groups:any, area:AreaModel) => {
  if(!area.created_at) {return []}
  const date = area.created_at.split('T')[0].slice(0, -3);
  if (!groups[date]) {
    groups[date] = [];
  }
  groups[date].push(area);
  return groups;
}, {});

const itemsInArray = (areas:any[]) => {
  let items:number[] = []
  Object.keys(areas).map((value:any)=>{
    items.push(areas[value].length)
    return value
  })
  return items
}

const keysInObject = (areas:any[]) => {
  let items:string[] = []
  Object.keys(areas).map((value:string)=>{
    items.push(value)
    return value
  })
  return items
}