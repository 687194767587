/* eslint-disable jsx-a11y/anchor-is-valid */
import { faArrowRight, faUserHelmetSafety } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { PartnerComment } from '../../models/AreaModel'

type Props = {
  className: string
  comments: PartnerComment[]
}

const AreaCommentLog: React.FC<Props> = ({className, comments}) => {
  const intl = useIntl()

  return (
    <div className={`card ${className}`}>
      <div className='card-header align-items-center border-0 mt-4'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bolder mb-2 text-dark'>Neueste Kommentare</span>
        </h3>
        <div className='card-toolbar'>
        </div>
      </div>
      <div className='card-body pt-5'>
        <div className='timeline-label'>
          {comments.map((comment:any)=>(
              <div className='timeline-item'>
              <div className='timeline-label fw-bolder text-gray-800 fs-6'>{new Date(comment.updated_at).toLocaleDateString(intl.locale)}</div>
              <div className='timeline-badge'>
                <i className='fa fa-genderless text-warning fs-1'></i>
              </div>
              <div className='fw-mormal timeline-content text-muted ps-3'>
                <div className='d-flex flex-row'>
                  <Link className='fw-bold me-2' to={'/area/'+comment.ref_details.area}>{comment.ref_details.area_name}</Link>
                  {!comment.area && (
                    <>
                    <FontAwesomeIcon icon={faArrowRight} className="fa-fw me-2 mt-1"/>
                    <Link className='fw-bold me-2' to={comment.ref_details.target}> {comment.ref_details.title}</Link> 
                    </>

                  )}
                  {/* <FontAwesomeIcon icon={faPipe} className="me-2 mt-1" /> */}
                  
                </div>
                <div className='d-flex flex-row'>
                  <FontAwesomeIcon icon={faUserHelmetSafety} className="me-2 mt-1" />
                  <Link to={'/profiles/'+comment.created_by.id}>
                    {comment.created_by.name}
                  </Link>
                  </div>
                <div className='d-flex flex-row'>
                  {comment.text.slice(0, 35)}{comment.text.length > 34 ?'...':''}
                </div>
              </div>
            </div>
          ))}
          
        </div>
      </div>
    </div>
  )
}

export {AreaCommentLog}
