/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { RootState } from '../../../../setup'
import { Languages } from './Languages'
import { useDispatch } from 'react-redux'
import { toAbsoluteUrl } from '../../../helpers'
import { logout } from '../../../../app/modules/auth/redux/AuthCRUD'
import { useIntl } from 'react-intl'

const HeaderUserMenu: FC = () => {
  const profile: any = useSelector<RootState>(({ auth }) => auth.profile)

  const dispatch = useDispatch();
  const intl = useIntl()

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='Profilepicture' style={{ "objectFit": "cover" }} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {profile.first_name} {profile.last_name}
            </div>
            <p className='fw-bold text-muted text-hover-primary fs-7 text-truncate w-150px'>
              {profile.email}
            </p>
            {profile.role && (
              <p className='fw-bold text-muted text-hover-primary fs-7 text-truncate w-150px'>
                {profile.role}
              </p>
            )}
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={'/profiles/'+profile.id} className='menu-link px-5'>
          {intl.formatMessage({ id: 'MENU.PROFILE' })}
        </Link>
      </div>

      {/* <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          <span className='menu-text'>My Projects</span>
          <span className='menu-badge'>
            <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span>
          </span>
        </a>
      </div> */}


      <div className='separator my-2'></div>

      <Languages />

      <div className='menu-item px-5'>
        <a onClick={() => dispatch(logout())} className='menu-link px-5'>
          {intl.formatMessage({ id: 'MENU.SIGNOUT' })}
        </a>
      </div>
    </div>
  )
}

export { HeaderUserMenu }
