/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { connect } from 'react-redux'
import { AppDispatch, RootState } from '../../../../setup/redux/Store'
import { API_URL, KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { PageTitle } from '../../../../_metronic/layout/core'
import { AreaLocationSet } from '../models/AreaModel'
import { RouteComponentProps } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faSave, faTrash } from '@fortawesome/pro-duotone-svg-icons'
import axios from 'axios'

interface WorkflowState {
  name: string
  locations: AreaLocationSet[]
  owner_name: string
  owner_phone: string
  owner_address: string
  users: any[]
  owner_id: number
  owner_email: string
  size: string
  description: string
}

interface WorkflowProps {

}

interface ConnectedProps1 {
}

interface DispatchProps {
}



type Props = ConnectedProps1 & DispatchProps & WorkflowProps & WrappedComponentProps & RouteComponentProps

class ReportAreaPage extends React.Component<Props, WorkflowState> {

  constructor(props: Props) {
    super(props);
    this.state = {
      locations: [{location_mark: '', location_number: '', id: ''}],
      name: '',
      owner_name: '',
      owner_address: '',
      owner_phone: '',
      users: [],
      owner_id: 0,
      owner_email: '',
      size: '',
      description: 'Keine Daten erhalten'
    }
  }
  

  componentDidMount() {
    axios.get(API_URL+"/admin-api/users").then(res=>this.setState({users:res.data}))
  }

  handleMarkChange(index:number, value:string){

    let location = {...this.state.locations[index]}
    location.location_mark = value
    let locations = [...this.state.locations]
    locations[index] = location
    this.setState({locations})
  }
  handleNumberChange(index:number, value:string){
    let location = {...this.state.locations[index]}
    location.location_number = value
    let locations = [...this.state.locations]
    locations[index]=location
    this.setState({locations})
  }
  handleDeleteChange(index:number){
    let location = {...this.state.locations[index]}
    location.location_mark = "DELETED"
    let locations = [...this.state.locations]
    locations[index]=location
    this.setState({locations})
  }

  saveNewArea(data:WorkflowState){
    let locations: string[][] = []
    data.locations.map(location=>locations.push([location.location_mark, location.location_number]))
    axios.post(API_URL+'/report-area', {...data, locations}).then(res=>
      this.props.history.push('/area/'+res.data.id)
    )
  }


  render(): React.ReactNode {
    const intl = this.props.intl;
    return (
      <>
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({ "id": "AREA.MANAGEMENT.WORKFLOW" })}</PageTitle>

        <div className='card mb-10'>
          <div className='card-body d-flex align-items-center py-8'>
            {/* begin::Icon */}
            <div className='d-flex h-80px w-80px flex-shrink-0 flex-center position-relative'>
              <KTSVG
                path='/media/icons/duotune/abstract/abs051.svg'
                className='svg-icon-primary position-absolute opacity-15'
                svgClassName='h-80px w-80px'
              />
              <FontAwesomeIcon icon={faPlus} />
            </div>
            {/* end::Icon */}

            {/* begin::Description */}
            <div className='ms-6'>
              <p className='list-unstyled text-gray-600 fw-bold fs-6 p-0 m-0'>
                Neue Fläche anlegen
              </p>
            </div>
            {/* end::Description */}
          </div>
        </div>
        <div className='card card-custom'>
          {/* begin::Form */}
          <form className='form'>
            {/* begin::Body */}
            <div className='card-body'>
              <div className='tab-content pt-3 row'>
              {this.state.locations.map((location: AreaLocationSet, index: number) => (
                location.location_mark === "DELETED" ? (''):(
                <div key={index} className='row mb-2'>
                  <div className='col-lg-5 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder={this.props.intl.formatMessage({id: "AREA.MANAGEMENT.SETTINGS.LOCATION_MARK"})}
                      value={location.location_mark}
                      name='location_mark'
                      onChange={(e)=>this.handleMarkChange(index, e.target.value)}
                    />
                  </div>

                  <div className='col-lg-5 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder={this.props.intl.formatMessage({id: "AREA.MANAGEMENT.SETTINGS.LOCATION_NUMBER"})}
                      value={location.location_number}
                      name='location_number'
                      onChange={(e)=>this.handleNumberChange(index, e.target.value)}
                    />
                  </div>
                  <div className='col-lg-2 fv-row'>
                    {this.state.locations.filter(location=>location.location_mark!=="DELETED").length > 1 &&(
                      <button className='btn btn-sm btn-danger' onClick={()=>this.handleDeleteChange(index)}>
                        <FontAwesomeIcon icon={faTrash} className="me-2"/>
                    </button>
                    )}
                  </div>
              </div>
              )))}
              <div className='row mt-1'>
                <div className='col-md-6'>
                <button className='btn btn-sm btn-secondary'
                  type='button'
                    onClick={()=>this.setState({locations: [...this.state.locations, {id: "", location_mark: "", location_number: ""}]})}>
                    <FontAwesomeIcon icon={faPlus} className="me-2"/>
                  </button>
                  </div>
              </div>

            </div>
            <div className='row'>
              <div className='col-md-6'>
              <input
              type='text'
              className='form-control form-control-lg form-control-solid mt-2'
              placeholder={this.props.intl.formatMessage({id: "AREA.MANAGEMENT.SETTINGS.NAME"})+" (Optional)"}
              value={this.state.name}
              name='name'
              onChange={(e)=>this.setState({name:e.target.value})}
            />
              </div>
              <div className='col-md-6'>
              <input
              type='text'
              className='form-control form-control-lg form-control-solid mt-2'
              placeholder={this.props.intl.formatMessage({id: "AREA.MANAGEMENT.SETTINGS.SIZE"})}
              value={this.state.size}
              name='size'
              onChange={(e)=>this.setState({size:e.target.value})}
            />
              </div>
            </div>

            <div className='row mt-2'>
              <div className='col-md-6'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder={"Besitzer Name"}
                  value={this.state.owner_name}
                  name='owner_name'
                  onChange={(e)=>this.setState({owner_name:e.target.value})}
                />
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mt-2'
                  placeholder={"Besitzer E-Mail"}
                  value={this.state.owner_email}
                  name='owner_email'
                  onChange={(e)=>this.setState({owner_email:e.target.value})}
                />
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mt-2'
                  placeholder={"Besitzer Telefon"}
                  value={this.state.owner_phone}
                  name='owner_phone'
                  onChange={(e)=>this.setState({owner_phone:e.target.value})}
                />
                 <textarea
                      className='form-control form-control-lg form-control-solid mt-2'
                      placeholder={"Besitzer Adresse"}
                      value={this.state.owner_address}
                      name='owner_address'
                      onChange={(e)=>this.setState({owner_address:e.target.value})}
                    />
              </div>
              <div className='col-md-6'>
                {this.state.owner_name && (<div>
                {this.state.users.map((user:any)=>(user.name.toLowerCase().search(this.state.owner_name.toLowerCase()) > -1) ? (
                  <div className='d-flex align-items-sm-center mb-7 border cursor-pointer' onClick={()=>{
                    if(this.state.owner_id){
                      this.setState({owner_id: 0, owner_phone: '', owner_address: '', owner_name: '', owner_email: ''})
                    } else {
                      this.setState({owner_id: user.id, owner_phone: user.phone, owner_address: user.address, owner_name: user.name, owner_email: user.email})
                    }
                  }}>
                   <div className='symbol symbol-60px symbol-2by3 me-4'>
                   <div
                     className='symbol-label'
                     style={{backgroundImage: `url(${toAbsoluteUrl(user.image, true)})`}}
                   ></div>
                 </div>
                   <div className='d-flex flex-row-fluid flex-wrap align-items-center'>
                     <div className='flex-grow-1 me-2'>
                         {user.name}
                     </div>
                     <span className='text-muted fw-bold d-block pt-1 me-10'>{user.id === this.state.owner_id && (
                      <p>Daten entfernen</p>
                     ) }</span>
                   </div>
                 </div>
                ):'')}
              </div>)}
              </div>
            </div>
            <button className='btn btn-primary mt-2'
              type='button'
                onClick={()=>this.saveNewArea(this.state)}>
                <FontAwesomeIcon icon={faSave} className="me-2"/> {this.props.intl.formatMessage({id: "GENERAL.SAVE"})}
              </button>
            
            </div>

            
            {/* end::Body */}


          </form>
          {/* end::Form */}
        </div>
      </>
    )
  }

}

const mapStateToProps = (state: RootState) => {
  return {
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
  };
};

export default connect<ConnectedProps1, DispatchProps>(mapStateToProps, mapDispatchToProps)(injectIntl(ReportAreaPage));
