/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { faTimes } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import { Component } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { connect } from 'react-redux'
import { AppDispatch, RootState } from '../../../../../setup/redux/Store'
import { API_URL } from '../../../../../_metronic/helpers'
import { createJob } from '../../redux/AreaRedux'


interface CreateJobModalProps {
  area: string
  show: boolean
  hideModal: ()=>void
}

interface CreateJobModalState {
  templates: any[]

}

interface ConnectedProps {

}

interface DispatchProps {
  createJob: typeof createJob

}

type Props = ConnectedProps & DispatchProps & CreateJobModalProps & WrappedComponentProps


class PartnerMemberModal extends Component<Props, CreateJobModalState> {

  constructor(props:Props){
    super(props);
    this.state = {
      templates: []
    }
  }
  
  componentDidMount(){
    this.fetchJobTemplates()
  }

  fetchJobTemplates(){
    axios.get(API_URL+`/jobs/templates`).then(
      res => this.setState({templates: res.data.results})
    )
  }


  render() {
    return (
      <Modal
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog-centered mw-1000px h-auto'
        show={this.props.show}
        onHide={this.props.hideModal}
      >
        <div className='container-xxl px-10 py-10'>
          <div className='modal-header py-2 d-flex justify-content-end border-0'>
            {/* begin::Close */}
            <div className='btn btn-icon btn-sm btn-light-primary' onClick={this.props.hideModal}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </div>
            {/* end::Close */}
        </div>
  
          <div className='modal-body'>
            {/*begin::Stepper */}
            <div
              className='d-flex flex-column flex-xl-row flex-row-fluid'
            >
              {/*begin::Aside */}
              <div className='d-flex justify-content-center justify-content-xl-start flex-row-auto '>
                
              </div>
              {/*begin::Aside */}
  
              {/*begin::Content */}
              <div className='d-flex flex-row-fluid justify-content-center'>
                {/*begin::Form */}
                <form
                >
                  {/*begin::Step 1 */}
                  <div className='pb-5 current' data-kt-stepper-element='content'>
                    <div className='w-100'>
                      {/*begin::Heading */}
                      <div className='pb-5 pb-lg-10'>
                        <h3 className='fw-bolder text-dark display-6'>{this.props.intl.formatMessage({id: 'AREA.MENU.JOBS.NEW'})}</h3>
                      </div>
                      {/*begin::Heading */}
  
                      {/*begin::Form Group */}
                      <div className='fv-row'>
                        <label className='fs-6 fw-bolder text-dark form-label'>{this.props.intl.formatMessage({id: 'AREA.MANAGEMENT.WORKFLOW.TEMPLATE_TABLE'})}</label>
                        <div className='d-flex flex-wrap'>
                          {this.state.templates.map((template:any)=>(
                            <label
                            className={`btn btn-outline btn-outline-dashed btn-outline-default p-2 d-flex align-items-center mb-10 me-2`}
                            onClick={()=>this.props.createJob(this.props.area, template.id)}
                          >
     
                            <span className='d-block fw-bold text-start'>
                            <span className='text-dark fw-bolder d-block fs-4 mb-2'>{template.name}</span>
                          </span>
                          </label>
                          ))}
                        </div>
                      </div>
                      
                      {/*end::Form Group */}
  
                    </div>
                  </div>
                  {/*end::Step 1 */}
  
                </form>
                {/*end::Form */}
              </div>
              {/*end::Content */}
            </div>
            {/* end::Stepper */}
          </div>
          <div>
  
          </div>
      </Modal>
    )
  }
}


const mapStateToProps = (state: RootState) => {
  return {
      partners: state.areas.partnersList,
      loading: state.areas.partnersListLoading,
      selectedPartner: state.areas.selectedPartner,
      profile: state.auth.profile,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    createJob: (area:string, template:string) => dispatch(createJob(area,template)),

  };
};

export default connect<ConnectedProps, DispatchProps>(mapStateToProps, mapDispatchToProps)(injectIntl(PartnerMemberModal));
