/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { faTimes } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { useIntl } from 'react-intl'
import { ReleaseNote } from '../areas/models/AreaModel'
import axios from 'axios'
import { API_URL } from '../../../_metronic/helpers'
import { useHistory } from 'react-router-dom'

type Props = {
  show: boolean
  handleClose: () => void
}

const NewReleaseNoteModal: React.FC<Props> = ({ show, handleClose }) => {

  const [releaseNotes, setReleaseNotes] = useState<ReleaseNote[]>()

  useEffect(()=>{
    axios.get(API_URL+'/release-notes').then(res=>setReleaseNotes(res.data.results))
  },[])

  const intl = useIntl()
  const history = useHistory()

  const saveReleaseNote = (id?:string) => {
    localStorage.setItem('release-note', id||'')
  }
  if(!releaseNotes?.length){
    return (<></>)
  }
  console.log(releaseNotes)

  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-1000px h-auto'
      show={show}
      onHide={handleClose}
    >
      <div className='container-xxl px-10 py-10'>
        <div className='modal-header py-2 d-flex justify-content-end border-0'>
          <div className='btn btn-icon btn-sm btn-light-primary' onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
        </div>

        <div className='modal-body'>
          <div
            className='d-flex flex-column flex-xl-row flex-row-fluid'
          >
            <div className='d-flex justify-content-center justify-content-xl-start flex-row-auto '>

            </div>
            {releaseNotes && (

            <div className='d-flex flex-row-fluid justify-content-center'>
              <form
                className='pb-5 w-100 w-md-400px w-xl-500px'
              >
                <div className='pb-5 current' data-kt-stepper-element='content'>
                  <div className='w-100'>
                    <div className='pb-5 pb-lg-10'>
                      <h3 className='fw-bolder text-dark display-6'>{intl.formatMessage({ id: 'AREA.RELEASE_NOTES' })}</h3>
                    </div>
                      <div>
                        <p className='fw-bolder fs-4 mb-5'>{releaseNotes[0].version_name} - {releaseNotes[0].subject}</p>
                        {/* 
                        // eslint-disable-next-line @typescript-eslint/no-useless-escape 
                        */}
                        <div dangerouslySetInnerHTML={{ __html: replaceAPI(releaseNotes[0].note) }} />
                      </div>

                  </div>
                </div>
                <div className='d-flex justify-content-between pt-10'>
                  <div className='mr-2'>
                  </div>
                  <div>
                  <button
                      type='button'
                      className='btn btn-lg btn-light-primary fw-bolder py-4 ps-8 me-3'
                      onClick={()=>{
                        saveReleaseNote(releaseNotes[0].id)
                        handleClose()
                        history.push('/release-notes')
                      }}

                    >
                      {intl.formatMessage({ id: 'GENERAL.ALL' })}
                    </button>
                    <button
                      type='button'
                      className='btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3'
                      onClick={()=>{
                        saveReleaseNote(releaseNotes[0].id)
                        handleClose()
                      }}

                    >
                      {intl.formatMessage({ id: 'AREA.RELEASE_NOTES.OK' })}
                    </button>
                  </div>
                </div>
              </form>
            </div>
            )}
          </div>
        </div>
        <div>

        </div>
      </div>
    </Modal>
  )
}

export { NewReleaseNoteModal }

export const replaceAPI = (html:string) => {
  // eslint-disable-next-line  no-useless-escape
  return html.replaceAll('src=\"', `src=\"${API_URL}`)
}