/* eslint-disable jsx-a11y/anchor-is-valid */
import { faCalendar, faNewspaper, faPeople, faQuestion, faTrash, faTrees } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import clsx from 'clsx'
import {FC, useEffect, useState} from 'react'
import { useIntl } from 'react-intl'
import Skeleton from 'react-loading-skeleton'
import { useDispatch } from 'react-redux'
import {useHistory} from 'react-router-dom'
import { updateNotifications } from '../../../../app/modules/auth'
import {API_URL} from '../../../helpers'

const NOTIFICATION_TYPES = [
  {
    icon: faNewspaper
  },
  {
    icon: faPeople
  },
  {
    icon: faTrees
  },
  {
    icon: faCalendar
  },
  {
    icon: faQuestion
  }
]
const getText = (text:string): string[] => {
  let fistText = text.split('.')[0].split('!')[0];
  return [fistText, text.replace(fistText, '').replace('!', '').replace('.', '')];
}
const HeaderNotificationsMenu: FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const markSeen = (id:string) => {
    setLoading(true);
    axios.post(API_URL+"/notifications", {id}).then(r=>axios.get(API_URL+"/notifications").then(res=>{
      setNotifications(res.data)
      setLoading(false);
      dispatch({type:updateNotifications.type, payload:res.data.length})
    }));
    return id
  }
  useEffect(()=>{
    setLoading(true);
    axios.get(API_URL+"/notifications").then(res=>{
      setNotifications(res.data)
      setLoading(false);
      dispatch({type:updateNotifications.type, payload:res.data.length})
    });
  }, [dispatch])
  const openTarget = (id: string, target:string, type:number) =>{
    if(type===3){
      history.push(target);
    }
    else {
      window.open('https://app.treeplantingprojects.com'+target, '_blank')?.focus();
    }
    markSeen(id);
  }
return (
  <div
    className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
    data-kt-menu='true'
  >
    <div
      className='d-flex flex-column bgi-no-repeat rounded-top bg-primary'
    >
      <h3 className='text-white fw-bold px-9 mt-10 mb-6'>
      {notifications.length} {intl.formatMessage({id: "GENERAL.NOTIFICATIONS"})}
      </h3>

    </div>

    <div className='tab-content'>
      <div className='tab-pane fade show active' role='tabpanel'>
        <div className='scroll-y mh-325px my-5 px-8'>
          {loading && (
            <div key={`alert0}`}>
              <Skeleton count={5} height={40} className='mb-4'/>
            </div>
          )}
          {!loading && notifications.map((alert, index) => (
            <div key={`alert${alert.id}`} className='d-flex flex-stack py-4'>
              <div className='d-flex align-items-center'>
                <div className='symbol symbol-35px me-4'>
                  <span className={clsx('symbol-label', `bg-light-${alert.state}`)}>
                    {' '}
                    <FontAwesomeIcon
                      icon={NOTIFICATION_TYPES[alert.notification_type-1].icon}
                      className={`svg-icon-2 svg-icon-${alert.state}`}
                    />
                  </span>
                </div>

                <div className='mb-0 me-2'>
                  <span className='fs-7 text-gray-800 text-hover-primary cursor-pointer' onClick={()=>openTarget(alert.id, alert.link, alert.notification_type)}>
                    {getText(alert.title)[0]}
                  </span>
                  <div className='text-gray-400 fs-7'>{getText(alert.title)[1]}</div>
                </div>
              </div>

              <span className='badge badge-light fs-8 me-2'>{new Date(alert.date).toLocaleDateString('de')}</span>
              <span className='cursor-pointer' 
              onClick={(e)=>{
                e.preventDefault()
                markSeen(alert.id)
              }}
              ><FontAwesomeIcon icon={faTrash}/></span>
            </div>
          ))}
        </div>
        <div className='py-3 text-center border-top'>
          <span
            className='btn btn-color-gray-600 btn-active-color-primary'
            onClick={()=>{
              notifications.forEach(alert=>{
                markSeen(alert.id)
              })
            }}
          >
            {intl.formatMessage({id: "GENERAL.ALL"})} <FontAwesomeIcon icon={faTrash} className='svg-icon-5' />
          </span>
        </div>
      </div>

    </div>
  </div>
)}

export {HeaderNotificationsMenu}
