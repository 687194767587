/* eslint-disable jsx-a11y/anchor-is-valid */
import { faWindowClose } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import React from 'react'
import { useIntl } from 'react-intl'
import Skeleton from 'react-loading-skeleton'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { getWorkflowStatus } from '../../../../pages/dashboard/DashboardWrapper'
import { AreaModel } from '../../models/AreaModel'

type Props = {
  className?: string
  color?: string
  onClose?: ()=>void
  area: AreaModel
  areaDetails: AreaModel
}

const MapInnerCard: React.FC<Props> = ({className, color, onClose, area, areaDetails}) => {
  const intl = useIntl();
  return (
    <div className={`card ${className}`}>
      <div className='card-body'>
        <div className='d-flex flex-stack'>
          <div className='d-flex align-items-center'>
            <div className='symbol symbol-60px me-5'>
              <span className={clsx(`symbol-label`, `bg-${color}-light`)}>
                {areaDetails && (
                  <img src={toAbsoluteUrl(areaDetails.image, true)} className='h-50 align-self-center img-fluid' alt='' />
                )}
              </span>
            </div>
            <div className='d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3'>
              {areaDetails ? (
              <Link to={`/area/${area.id}`} className='text-dark fw-bolder text-hover-primary fs-5'>
                {area.name}
              </Link>
              ):<Skeleton />}
              {areaDetails ? (
              <span className='text-muted fw-bold'>{getWorkflowStatus(areaDetails.workflow_status)}</span>
              ):(
                <Skeleton />
              )}
            </div>
          </div>
          {onClose && (
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              className='btn btn-icon btn-color-primary btn-active-light-primary fs-1'
              onClick={()=>onClose()}
            >
              <FontAwesomeIcon icon={faWindowClose} />
            </button>
          </div>
          )}
        </div>
        <div className='row mt-6'>
          <div className='col-6'>
            <span className='text-dark me-2 fw-bolder pb-3'>{intl.formatMessage({id: "AREA.MANAGEMENT.TABLE.SIZE"})}</span>
            {areaDetails ? (
              <>
              {areaDetails.size} {intl.formatMessage({id: "AREA.MANAGEMENT.UNIT"})}
              </>
            ):(
              <Skeleton />
            )}
            
          </div>
          <div className='col-6'>
            <span className='text-dark me-2 fw-bolder pb-3'>{intl.formatMessage({id: "AREA.MANAGEMENT.TABLE.TREES"})}</span>
            {areaDetails ? (
              <>
              {areaDetails.trees_planted}/{areaDetails.trees_to_plant}
              </>
            ):(
              <Skeleton />
            )}
          </div>
        </div>
        <div className='row mt-6'>
          <div className='col-12'>
            <span className='text-dark me-2 fw-bolder pb-3'>{intl.formatMessage({id: "AREA.MANAGEMENT.SETTINGS.LOCATION"})}</span>
            {areaDetails ? (
              <>
              {areaDetails.locations[0] ? (
              areaDetails.locations[0].location_mark + " " + areaDetails.locations[0].location_number
            ):intl.formatMessage({id: "AREA.MANAGEMENT.MESSAGES.NO_LOCATIONS"})}
              </>
            ):(<Skeleton />)}
          </div>
        </div>
      </div>
    </div>
  )
}

export {MapInnerCard}
