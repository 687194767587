import axios from 'axios';

const api =
    ({ dispatch }: any) =>
        (next: (arg0: any) => any) =>
            async (action: any) => {

                if (action.type && action.type !== 'apiCall') return next(action);

                if (!action.payload) return next(action)

                const { url, method, data, headers, onSuccess, onError, onLoading, target, onSuccessAction, onErrorShowMessage, onSuccessListFetch } = action.payload;

                let httpHeaders = headers;

                if (!headers) {
                    httpHeaders = { 'Content-Type': 'application/json' };
                }

                if (onLoading) dispatch({ type: onLoading });

                try {
                    const response = await axios.request({
                        baseURL: process.env.REACT_APP_TPP_API,
                        headers: httpHeaders,
                        url,
                        data,
                        method,
                    });
                    if (target) {
                        dispatch({ type: onSuccess, payload: { ...response.data, target } });
                    } else {
                        dispatch({ type: onSuccess, payload: response.data });
                    }
                    if (onSuccessAction) {
                        if (Array.isArray(onSuccessAction)) {
                            onSuccessAction.forEach((action, i) => {
                                dispatch(action())
                            })
                        }
                        else dispatch(onSuccessAction());
                    }
                    if (onSuccessListFetch) {
                        response.data.results.map((item: any) => dispatch(onSuccessListFetch.fn(item[onSuccessListFetch.key])))
                    }

                } catch (error: any) {
                    if (onError) {
                        dispatch({ type: onError, payload: error });
                        if (onErrorShowMessage) {
                            if (onErrorShowMessage.message) {
                                onErrorShowMessage.fn(onErrorShowMessage.message)
                            } else {
                                if (error.response.data.detail) {
                                    onErrorShowMessage.fn(error.response.data.detail)
                                }

                            }
                        }
                    }
                }
            };

export default api;
