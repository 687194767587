/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Field, ErrorMessage } from 'formik'
import { useIntl } from 'react-intl';

const Step2PartnerInfo: FC = () => {
  const intl = useIntl();

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>
          {intl.formatMessage({ id: "PARTNER.REGISTRATION.STEP_INFO.TITLE" })}
        </h2>
      </div>

      <div className='mb-10 fv-row'>
        <label className='form-label mb-3'>{intl.formatMessage({ id: "PARTNER.REGISTRATION.STEP_INFO.NAME_LABEL" })}</label>

        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='name'
          placeholder=''
        />
        <div className='fv-plugins-message-container invalid-feedback'>
          <ErrorMessage name='name' />
        </div>
      </div>

      <div className='mb-10 fv-row'>
        <label className='form-label mb-3'>{intl.formatMessage({ id: "PARTNER.REGISTRATION.STEP_INFO.HOMEPAGE_LABEL" })}</label>

        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='homepage'
          placeholder=''
        />
        <div className='fv-plugins-message-container invalid-feedback'>
          <ErrorMessage name='homepage' />
        </div>
      </div>
    </div>
  )
}

export { Step2PartnerInfo }
