/* eslint-disable jsx-a11y/anchor-is-valid */


interface DropdownProps {
  show?: boolean
  onConfirm: Function
  loading: boolean
  text?: string
  onBack?: () => void
  style? : any
}

export function ConfirmDropdown(props:DropdownProps) {

  return (
    <div className={`menu menu-sub menu-sub-dropdown ${props.show ? 'show':''}`} style={{zIndex: "105", position: "fixed", ...props.style}} >
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>{props.text || "Löschen?"}</div>
      </div>

      <div className='separator border-gray-200'></div>
      <div className='d-flex justify-content-center'>

                <button className='btn btn-sm btn-primary m-2' disabled={props.loading} 
                  onClick={()=>props.onConfirm()} type="button"
                >
                  {!props.loading ? "Bestätigen":(
                    <span className='indicator-progress' style={{display: 'block'}}>
                    Bitte warten...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
                {props.onBack && (
                <button type='button' className='btn btn-sm btn-info m-2' disabled={props.loading} 
                  onClick={props.onBack}
                >
                Abbrechen
                </button>
                )}
          </div>

    </div>
  )
}
