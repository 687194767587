
import { Action, configureStore, Middleware, ThunkAction } from '@reduxjs/toolkit';


import {reduxBatch} from '@manaflair/redux-batch'
import { persistStore, persistReducer } from 'redux-persist';
import {rootReducer} from './RootReducer'
import api from './middleware';
import storage from 'redux-persist/lib/storage' 
import { getDefaultMiddleware } from '@reduxjs/toolkit';
import { version } from '../../../package.json';


const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false
}).prepend(
  api as Middleware<(action: Action<'specialAction'>) => number, RootState>,
);

const persistConfig = {
  key: `tpp_app_${version}`,
  storage,
}

const store:any = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  middleware: customizedMiddleware,
  devTools: process.env.NODE_ENV !== 'production',
  enhancers: [reduxBatch],
})


export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
/**
 * @see https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
 * @see https://github.com/rt2zz/redux-persist#persistor-object
 */
export const persistor = persistStore(store)

export default store

