import { FC, useEffect, useRef, useState } from 'react'
import { Step1PartnerType } from './steps/Step1PartnerType'
import { Step2PartnerInfo } from './steps/Step2PartnerInfo'
import { Step4Completed } from './steps/Step4Completed'
import { KTSVG } from '../../../../_metronic/helpers'
import { StepperComponent } from '../../../../_metronic/assets/ts/components'
import { Formik, Form, FormikValues } from 'formik'
import { useIntl } from 'react-intl'
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom'
import { PartnerTypes } from '../PartnerMemberModal'

const PartnerRegistrationCard: FC<{ partnerServices: any[], onSubmit: any }> = (props: any) => {
  const intl = useIntl();
  const history = useHistory();

  const initialValues = {
    type: '',
    name: '',
    homepage: '',
    services: ''
  };

  const validationSchemas = [
    Yup.object({
      type: Yup.string().required().oneOf([PartnerTypes[0].name, PartnerTypes[1].name, PartnerTypes[2].name]),
    }),
    Yup.object({
      name: Yup.string().required(),
      homepage: Yup.string().url().required()
    }),
    Yup.object({}),
    Yup.object({}),
  ];

  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const [currentSchema, setCurrentSchema] = useState(validationSchemas[0]);

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement);
  };

  const prevStep = (values: any) => {
    if (!stepper.current) {
      return;
    }

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goPrev();
    } else {
      if (values.type !== 'provider') {
        stepper.current.goto(2);
      } else {
        stepper.current.goPrev();
      }
    }

    setCurrentSchema(validationSchemas[stepper.current.currentStepIndex - 1]);
  };

  const onSubmitForm = (values: any, actions: FormikValues) => {
    if (!stepper.current) {
      return;
    }

    setCurrentSchema(validationSchemas[stepper.current.currentStepIndex]);
    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      if (stepper.current.currentStepIndex === 2) {
        if (values.type !== 'provider') {
          props.onSubmit(values);
          stepper.current.goto(3);
        } else {
          stepper.current.goNext();
        }
      } else if (stepper.current.currentStepIndex === 3) {
        props.onSubmit(values);
        stepper.current.goNext();
      } else {
        stepper.current.goNext();
      }
    } else {
      stepper.current.goto(1);
      history.push('/partners');
    }
  };

  useEffect(() => {
    if (!stepperRef.current) {
      return;
    }

    loadStepper();
  }, [stepperRef]);
  return (
    <div className='card'>
      <div className='card-body'>
        <div
          ref={stepperRef}
          className='stepper stepper-links d-flex flex-column pt-15'
          id='kt_partner_registration_stepper'
        >
          <div className='stepper-nav mb-5'>
            <div className='stepper-item current' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>{intl.formatMessage({ id: "PARTNER.REGISTRATION.STEP_TYPE.TITLE" })}</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>{intl.formatMessage({ id: "PARTNER.REGISTRATION.STEP_INFO.TITLE" })}</h3>
            </div>

            {/* <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>{intl.formatMessage({ id: "PARTNER.REGISTRATION.STEP_SERVICES.TITLE" })}</h3>
            </div> */}

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>{intl.formatMessage({ id: "PARTNER.REGISTRATION.STEP_COMPLETED.TITLE" })}</h3>
            </div>
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={currentSchema}
            onSubmit={onSubmitForm}
          >
            {({ isValid, values }) => (
              <Form
                className='mx-auto mw-800px w-100 pt-15 pb-10'
                noValidate
                id='kt_partner_registration_form'
              >
                <div className='current' data-kt-stepper-element='content'>
                  <Step1PartnerType />
                </div>

                <div data-kt-stepper-element='content'>
                  <Step2PartnerInfo />
                </div>

                {/* <div data-kt-stepper-element='content'>
                  <Step3PartnerProvider partnerServices={props.partnerServices} />
                </div> */}

                <div data-kt-stepper-element='content'>
                  <Step4Completed />
                </div>
                <div className='d-flex flex-stack pt-15'>
                  <div className='mr-2'>
                    <button
                      onClick={() => prevStep(values)}
                      type='button'
                      className='btn btn-lg btn-light-primary me-3'
                      data-kt-stepper-action='previous'
                    >
                      {stepper.current?.currentStepIndex === 4 && 'Submit'}
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr063.svg'
                        className='svg-icon-4 me-1'
                      />
                      {intl.formatMessage({ id: "PARTNER.REGISTRATION.BACK" })}
                    </button>
                  </div>
                  <div>
                    <button type='submit' className='btn btn-lg btn-primary me-3' disabled={!isValid}>
                      <span className='indicator-label'>
                        {stepper.current?.currentStepIndex !==
                          stepper.current?.totatStepsNumber! - 1 && 'Continue'}
                        {stepper.current?.currentStepIndex ===
                          stepper.current?.totatStepsNumber! - 1 && 'Submit'}
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-3 ms-2 me-0'
                        />
                      </span>
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export { PartnerRegistrationCard }
