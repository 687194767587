import axios from 'axios'
import { fetchProfileFail, fetchProfileLoading, fetchProfileSuccess, loginFail, loginLoading, loginSuccess, logoutSuccess } from '..'
import { AppDispatch } from '../../../../setup/redux/Store'
import {AuthModel} from '../models/AuthModel'
import {ProfileModel} from '../models/ProfileModel'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}profiles`
export const LOGIN_URL = `${API_URL}token/`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export function login1(email: string, password: string) {
  return axios.post(LOGIN_URL, {
      username: email,
      password: password,
    },
  )
}

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string) {
  return axios.post<AuthModel>(REGISTER_URL, {
    email,
    firstname,
    lastname,
    password,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.get<{result: boolean}>(REQUEST_PASSWORD_URL, {
    params: {
      email: email,
    },
  })
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get<ProfileModel>(GET_USER_BY_ACCESSTOKEN_URL)
}

export const login = (username:string, password:string, setStatus: Function) => {
	return (dispatch: AppDispatch) => {
	  dispatch({
	    type: 'apiCall',
	    payload: {
	      url: '/api-token-auth/',
	      method: 'POST',
        data:{username, password},
	      onSuccess: loginSuccess.type,
	      onError: loginFail.type,
        onErrorShowMessage: {fn: setStatus},
	      onLoading: loginLoading.type,
		  onSuccessAction: fetchProfile
	    },
	  });
	};
};

export const logout = () => {
	return (dispatch: AppDispatch) => {
	  dispatch({
	    type: logoutSuccess.type
    });
	};
};

export const fetchProfile = () => {
	return (dispatch: AppDispatch) => {
	  dispatch({
	    type: 'apiCall',
	    payload: {
	      url: '/profiles',
	      method: 'GET',
	      onSuccess: fetchProfileSuccess.type,
	      onError: fetchProfileFail.type,
	      onLoading: fetchProfileLoading.type,
	    },
	  });
	};
};
      